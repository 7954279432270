import { orderProductModel } from '@/models';
import type {
  OrderModel,
  OrderStatus,
  OrderProductModel,
  ReductionData,
  ReductionType,
  OrderItemData,
} from '@/types/orders';
import type {
  BffApiAccountOrderOrderIdGet,
  BffApiAccountOrdersGet,
  BffApiGetCheckoutOrder,
  BffCustomer,
} from '@/types/bff';
import type { ApiResponse } from '@/types/apiInit';

const statusSequence: OrderStatus[] = [
  'created',
  'pending',
  'confirmed',
  'exported',
  'waiting',
  'inProgress',
  'completed',
  'canceled',
  'shipped',
  'outForDelivery',
  'delayed',
  'delivered',
  'returned',
];

export function orderModel(responseData: ApiResponse<BffApiAccountOrderOrderIdGet | BffApiGetCheckoutOrder> | null) {
  const response = responseData ?? {};
  const orderData = response.data?.order;

  const localCache: any = {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    isPlacedSuccessfully(): boolean {
      return orderData?.orderStatus !== 'canceled';
    },

    getId(): string {
      return orderData?.orderId ?? '';
    },

    getExtId(): string {
      return orderData?.extOrderNo ?? '';
    },

    getVisibleId(): string {
      return this.isGlobalEOrder() ? this.getExtId() : this.getId();
    },

    isGlobalEOrder(): boolean {
      return orderData?.orderType === 'globalE';
    },

    isOffline(): boolean {
      return orderData?.orderType === 'offline';
    },

    isDelivered(): boolean {
      if (!orderData?.orderStatus) {
        return false;
      }
      return statusSequence.indexOf(orderData.orderStatus) >= statusSequence.indexOf('delivered');
    },

    isShipped(): boolean {
      if (!orderData?.orderStatus) {
        return false;
      }
      return statusSequence.indexOf(orderData.orderStatus) >= statusSequence.indexOf('shipped');
    },

    isCanceled(): boolean {
      return orderData?.orderStatus === 'canceled';
    },

    isPayed(): boolean {
      return this.getOrderBalance() <= 0;
    },

    getOrderDate(): string {
      return orderData?.orderDate ?? '';
    },

    getCurrency(): string {
      return orderData?.payment.currency ?? '';
    },

    getLocale(): string {
      return (orderData?.locale ?? '').toLowerCase();
    },

    getShippingAddress() {
      return orderData?.addresses?.shipping
        ? ({
            ...orderData?.addresses?.shipping,
            countryCode: orderData?.addresses?.shipping?.countryCode.toLowerCase(),
          } as typeof orderData.addresses.shipping)
        : null;
    },

    getBillingAddress() {
      return orderData?.addresses?.billing
        ? ({
            ...orderData?.addresses?.billing,
            countryCode: orderData?.addresses?.billing?.countryCode.toLowerCase(),
          } as typeof orderData.addresses.billing)
        : null;
    },

    getPackages() {
      return orderData?.packages ?? [];
    },

    getShippingMethod(): string {
      return orderData?.packages[0]?.carrier?.toLowerCase() ?? '';
    },

    getPayment(): { method: string; provider: string } {
      const paymentItemData =
        orderData?.payment.payments.find((p: any) => p?.method?.toLowerCase() !== 'gift_card') || null;
      if (!paymentItemData) {
        return { method: '', provider: '' };
      }
      const method: string = paymentItemData?.method?.toLowerCase() ?? '';
      const provider: string = method === 'credit_card' ? paymentItemData?.cardType?.toLowerCase() ?? '' : method;
      return { method, provider };
    },

    getCustomerFirstName() {
      return orderData?.customer?.firstName || '';
    },

    getCustomerLastName() {
      return orderData?.customer?.lastName || '';
    },

    getCustomerEmail() {
      return orderData?.customer?.email || '';
    },

    getCustomerGender() {
      return orderData?.customer?.gender?.toLocaleLowerCase() as BffCustomer['gender'];
    },

    getCustomerNumber() {
      return orderData?.customer?.crmContactNo || '';
    },

    getOrderItems(): OrderProductModel[] {
      if (localCache.orderItems !== undefined) {
        return localCache.orderItems;
      }
      const orderItems = orderData?.items ?? [];

      localCache.orderItems = orderItems
        .reduce((productList: OrderItemData[], productData: OrderItemData) => {
          let product: OrderItemData | undefined = productList.find((item) => item.ean === productData.ean);
          const isReturned = productData.orderStatus?.status === 'returned';

          if (product) {
            product.quantity = (product.quantity ??= 0) + 1;
          } else {
            product = productData;
            product.quantity = 1;
            productList.push(product);
          }
          if (isReturned) {
            product.returnedQuantity = (product.returnedQuantity ??= 0) + 1;
          }
          return productList;
        }, [])
        .map(orderProductModel);

      return localCache.orderItems;
    },

    getShipFromStoreOrderItems(): OrderProductModel[] {
      return this.getOrderItems().filter((orderProduct) => orderProduct.isShipFromStoreOrder());
    },

    getOnlineOrderItems(): OrderProductModel[] {
      return this.getOrderItems().filter((orderProduct) => !orderProduct.isShipFromStoreOrder());
    },

    hasMultipleShipments(): boolean {
      return this.getOnlineOrderItems().length > 0 && this.getShipFromStoreOrderItems().length > 0;
    },

    isBackOrder(): boolean {
      return this.getOrderItems().some((orderProduct) => orderProduct.isBackOrder()) ?? false;
    },

    getSubtotalPrice(): number {
      return this.getOrderItems().reduce((total, orderItem) => total + orderItem.getTotalOriginalPrice(), 0);
    },

    getShippingPrice(): number {
      return orderData?.fees?.find((fee) => fee.type === 'shipping')?.totalGrossPrice ?? 0;
    },

    getShippingNetPrice(): number {
      return orderData?.fees?.find((fee) => fee.type === 'shipping')?.totalNetPrice ?? 0;
    },

    getPaymentPrice(): number {
      return orderData?.fees?.find((fee) => fee.type === 'payment')?.totalGrossPrice ?? 0;
    },

    getPaymentNetPrice(): number {
      return orderData?.fees?.find((fee) => fee.type === 'payment')?.totalNetPrice ?? 0;
    },

    getTotalDiscount(): number {
      return this.getTotalItemLevelDiscount() + this.getTotalOrderLevelDiscount();
    },

    getTotalItemLevelDiscount(): number {
      return -(
        this.getSubtotalPrice() -
        this.getOrderItems().reduce((total, orderItem) => total + orderItem.getTotalPrice(), 0)
      );
    },

    getReturnedValue(): number {
      return orderData?.transaction?.returnedValue || 0;
    },

    getOrderBalance(): number {
      let balance: number = Math.abs(orderData?.transaction?.orderBalance || 0);
      if (balance) {
        balance = balance - this.getReturnedValue();
      }
      return balance;
    },

    getTotalOrderLevelDiscount(): number {
      return orderData?.reductions?.reduce((total, reduction) => total + reduction.amount, 0) ?? 0;
    },

    getVoucherValue(): number {
      const voucherValue = this.getReductionByType('voucher')?.amount;
      return voucherValue ? -1 * voucherValue : 0;
    },

    getVoucherCode(): string {
      return this.getReductionByType('voucher')?.couponCode ?? '';
    },

    getReductionByType(reductionType: ReductionType): ReductionData | undefined {
      const orderLevelReduction = orderData?.reductions?.find((reduction) => reduction.category === reductionType);
      if (orderLevelReduction) {
        return orderLevelReduction;
      }
      return orderData?.items.reduce((summedReduction: ReductionData | undefined, item) => {
        const reduction = item?.price?.reductions?.find((reduction) => reduction.category === reductionType);
        if (!reduction) {
          return summedReduction;
        }
        return {
          category: reduction.category,
          amount: (summedReduction?.amount || 0) + reduction.amount,
          couponCode: reduction.couponCode,
        };
      }, undefined);
    },

    getTotalPrice(): number {
      return (orderData?.totals.grossPrice ?? 0) - this.getReturnedValue();
    },

    getTotalNetPrice(): number {
      return orderData?.totals.netPrice ?? 0;
    },

    getTotalTax(): number {
      return orderData?.totals.tax ?? 0;
    },

    getTotalOrderCount(): number {
      return orderData && 'totalOrderCount' in orderData ? orderData.totalOrderCount : 0;
    },

    getDownloadInvoiceUrlList(): string[] {
      return orderData && 'invoices' in orderData ? orderData.invoices : [];
    },

    getDownloadReturnLabelUrlList(): string[] {
      return orderData && 'returnLabels' in orderData ? orderData.returnLabels : [];
    },

    getTrackingLink(): string {
      return orderData && 'trackingPath' in orderData && orderData.trackingPath ? orderData.trackingPath : '';
    },
  };
}

export function orderListModel(responseData: ApiResponse<BffApiAccountOrdersGet> | null) {
  const response = responseData ?? {};
  const ordersData = response.data?.orders;

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    getCount(): number {
      return ordersData?.length ?? 0;
    },

    getOrderModelList(): OrderModel[] {
      if (ordersData === undefined) {
        return [];
      }
      return ordersData
        ?.map((order) =>
          orderModel({
            data: {
              order,
              code: response.data!.code,
              statusCode: response.data!.statusCode,
              isMopApiResponse: response.data!.isMopApiResponse,
            },
          }),
        )
        .sort((orderA, orderB) => {
          const a: number = new Date(orderA.getOrderDate()).getTime();
          const b: number = new Date(orderB.getOrderDate()).getTime();

          if (a < b) {
            return 1;
          }
          if (a > b) {
            return -1;
          }
          return 0;
        });
    },
  };
}
