import type { CartDiscountResponseData, CartDiscountData, CartDiscountLineItemsTargetData } from '@/types/cartDiscount';
import type { CustomerGroup } from '@/types/customer';

export function cartDiscountModel(discountData: CartDiscountData, customerGroups: CustomerGroup[]) {
  const customFields = discountData.custom?.fields;

  return {
    isActive() {
      return discountData.isActive === true;
    },

    getType() {
      return discountData.target?.type || 'giftLineItem';
    },

    getPredicate() {
      const type = this.getType();
      if (['lineItems', 'customLineItems', 'multiBuyCustomLineItems', 'multiBuyLineItems'].includes(type)) {
        return (discountData.target as CartDiscountLineItemsTargetData).predicate;
      }
    },

    getCartPredicate() {
      return discountData.cartPredicate;
    },

    getStackingMode() {
      return discountData.stackingMode;
    },

    getRequiresDiscountCode() {
      return discountData.requiresDiscountCode;
    },

    getValidFrom() {
      return discountData.validFrom;
    },

    getValidUntil() {
      return discountData.validUntil;
    },

    getKey() {
      return discountData.key || '';
    },

    getCalloutFlag() {
      const message = customerGroups.includes('Member')
        ? customFields?.label || ''
        : customFields?.labelGuest || customFields?.label || '';
      const appearance = customerGroups.includes('Member')
        ? customFields?.appearance || ''
        : customFields?.appearanceGuest || customFields?.appearance || '';
      return {
        message,
        appearance,
        showPriceOnlyInCart: true,
      };
    },

    getDebugData() {
      return discountData;
    },
  };
}

export function cartDiscountListModel(responseData: CartDiscountResponseData | null) {
  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return responseData?.statusCode !== undefined && responseData.statusCode !== 200;
    },

    getDiscountModelList(customerGroups: CustomerGroup[]) {
      return responseData?.body?.results.map((discountData) => cartDiscountModel(discountData, customerGroups)) ?? [];
    },

    getDebugData() {
      return responseData;
    },
  };
}
