import type { Price, DiscountedLineItemPrice } from '@commercetools/platform-sdk';
import { getVatRateFromCountry } from '@/i18n/localeList';
import type { ProductPrice } from '@/types/product';

export function getMopPriceFromCommercetools(
  price?: Price,
  discountedPrice?: DiscountedLineItemPrice,
  quantity = 1,
): ProductPrice {
  if (!price) {
    return {
      basePrice: 0,
      reductionPrice: 0,
      salePrice: 0,
      reductionPercentage: 0,
      salePercentage: 0,
      currency: '',
    };
  }

  const centBasePrice = price.custom?.fields?.listPrice?.centAmount || price.value.centAmount || 0;
  const priceFromDiscount = discountedPrice?.value.centAmount || price.discounted?.value.centAmount || 0;

  const centReductionPrice = price.value.centAmount || centBasePrice || 0;
  const reductionPercentage =
    (centBasePrice === centReductionPrice ? 0 : Math.round(100 - (centReductionPrice * 100) / centBasePrice)) || 0;

  const centSalePrice = priceFromDiscount || centReductionPrice;
  const salePercentage =
    (centBasePrice === centSalePrice ? 0 : Math.round(100 - (centSalePrice * 100) / centBasePrice)) || 0;

  const currency = price.value.currencyCode ?? '';
  const productDiscountId = price.discounted?.discount.id;
  const cartDiscountIds = discountedPrice?.includedDiscounts.map((discount) => discount.discount.id) || [];

  const centLowestPrice = price.custom?.fields?.lowestPrice?.centAmount || 0;

  const basePrice = (centBasePrice * quantity) / 100;
  const reductionPrice = (centReductionPrice * quantity) / 100;
  const salePrice = (centSalePrice * quantity) / 100;
  const lowestPrice = centLowestPrice / 100;

  const country = price.country?.toLocaleLowerCase() || 'de';
  const vatRate = getVatRateFromCountry(country);
  const basePriceNet = Number((basePrice / (1 + vatRate)).toFixed(2));
  const reductionPriceNet = Number((reductionPrice / (1 + vatRate)).toFixed(2));
  const salePriceNet = Number((salePrice / (1 + vatRate)).toFixed(2));

  return {
    basePrice,
    reductionPrice,
    salePrice,

    basePriceNet,
    reductionPriceNet,
    salePriceNet,

    lowestPrice,
    reductionPercentage,
    salePercentage,
    currency,
    productDiscountId,
    cartDiscountIds,
  };
}

// Example price object
// "price": {
//   "id": "d9dc3ed4-d33e-4ee5-a0bc-371e96acae36",
//   "key": "7325868590542-DE-TEMP",
//   "value": {
//       "type": "centPrecision",
//       "centAmount": 1111,
//       "currencyCode": "EUR",
//       "fractionDigits": 2
//   },
//   "country": "DE",
//   "validFrom": "2024-06-09T22:00:00.000Z",
//   "validUntil": "2024-06-15T22:00:00.000Z",
//   "discounted": {
//       "value": {
//           "type": "centPrecision",
//           "centAmount": 778,
//           "currencyCode": "EUR",
//           "fractionDigits": 2
//       },
//       "discount": {
//           "typeId": "product-discount",
//           "id": "42cdf9e7-cb09-4f2e-9e36-2c986c741374"
//       }
//   },
//   "custom": {
//       "type": {
//           "typeId": "type",
//           "id": "ff53d1b7-5058-456a-84fb-c064db0e1677"
//       },
//       "fields": {
//           "listPrice": {
//               "type": "centPrecision",
//               "currencyCode": "EUR",
//               "centAmount": 3995,
//               "fractionDigits": 2
//           },
//          "lowestPrice": {
//            "type": "centPrecision",
//            "currencyCode": "EUR",
//            "centAmount": 6969,
//            "fractionDigits": 2
//          }
//       }
//   }
// },
