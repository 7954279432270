import { securedWrap } from '@mop/shared/utils/securedWrap';
import { categoryModel } from '@/models';
import type { CategoryModel, MopCategoryDataCustomFields } from '@/types/category';
import type { SeoBreadcrumb } from '@/types/cms';

export default function useMopCategoryTree() {
  const { $rootCategories, $mopConfig } = useNuxtApp();
  const router = useRouter();

  function getCategoryByPath(path: string, categoryList?: CategoryModel[], maxDepth?: number): CategoryModel {
    if (categoryList === undefined) {
      categoryList = $rootCategories?.getCategoryModelList(true);
    }
    if (path === undefined || !categoryList?.length) {
      return categoryModel(null);
    }
    path = path[0] === '/' ? path : `/${path}`;
    const pathList: string[] = path.split('/');
    const slug: string = pathList.splice(1, 1)[0];
    if (slug === undefined) {
      return categoryModel(null);
    }
    const category = categoryList.find((category: CategoryModel) => category.getSlug() === slug);
    if (category === undefined) {
      return categoryModel(null);
    }
    if (pathList.length > 1 && (maxDepth === undefined || maxDepth > category.getDepth())) {
      return getCategoryByPath(pathList.join('/'), category.getChildren(true), maxDepth);
    }
    return category;
  }

  function getCategoriesInPath(path: string): CategoryModel[] {
    const pathList: string[] = path.split('/');
    const result = [getCategoryByPath(path)];
    while (pathList.length > 0) {
      const pathItem: string | undefined = pathList.pop();
      const newPath: string = pathList.join('/');
      if (newPath.length === 0 || pathItem === '' || pathItem === undefined) {
        break;
      }
      result.unshift(getCategoryByPath(newPath));
    }
    return result;
  }

  function getCategoryBreadcrumbs(category: CategoryModel): SeoBreadcrumb[] {
    if (!category?.getId()) {
      return [];
    }
    const breadcrumbCategories = getCategoriesInPath(category.getPath());
    return breadcrumbCategories.map((breadcrumbCategory) => {
      return {
        name: breadcrumbCategory.getName($mopConfig),
        url: breadcrumbCategory.getUrl(),
        isCategory: true,
      };
    });
  }

  function getCurrentCategoryWithFallbackToParent() {
    const path = router.currentRoute.value.path.substring(6) as string;
    if (!path) {
      return getCategoryByPath(path);
    }
    const categoryList = getCategoriesInPath(path);

    return getVisibleCategoryFromList(categoryList);
  }

  function getVisibleCategoryFromList(categoryList: CategoryModel[]): CategoryModel | undefined {
    const category = categoryList.pop();
    if (category?.isVisible() || !categoryList.length) {
      return category;
    }
    return getVisibleCategoryFromList(categoryList);
  }

  function getCategoryById(id: string, categoryList?: CategoryModel[]): CategoryModel | undefined {
    if (categoryList === undefined) {
      categoryList = $rootCategories?.getCategoryModelList(true);
    }
    if (id === undefined || !categoryList?.length) {
      return;
    }
    let category = categoryList.find((category: CategoryModel) => {
      return category.getId() === id;
    });

    if (category !== undefined) {
      return category;
    }

    for (const categoryListItem of categoryList) {
      category = getCategoryById(id, categoryListItem.getChildren(true));

      if (category !== undefined) {
        return category;
      }
    }
  }

  function getCategoryByPropertyName(
    propertyName: 'mopId' | keyof MopCategoryDataCustomFields,
    propertyValue: string,
    categoryList?: CategoryModel[],
  ): CategoryModel | undefined {
    if (!propertyName || !propertyValue) {
      return;
    }
    if (!categoryList) {
      categoryList = $rootCategories?.getCategoryModelList(true);
    }
    if (!categoryList || categoryList.length === 0) {
      return;
    }
    let category = categoryList.find((category: CategoryModel) => {
      if (propertyName === 'mopId') {
        return category.getMopId() === propertyValue;
      }
      return category.getCustomFieldValue(propertyName) === propertyValue;
    });

    if (category) {
      return category;
    }

    for (const categoryListItem of categoryList) {
      category = getCategoryByPropertyName(propertyName, propertyValue, categoryListItem.getChildren(true));
      if (category) {
        return category;
      }
    }
  }

  return securedWrap({
    getCategoryByPath,
    getCategoriesInPath,
    getCategoryBreadcrumbs,
    getCategoryById,
    getCategoryByPropertyName,
    getCurrentCategoryWithFallbackToParent,
  });
}
