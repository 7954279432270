import { maskito } from '@maskito/vue';
import { storyblokDirective } from '@/directives/storyblokEditable';
import { visibilityDirective } from '@/directives/observeVisibility';
import { imageSrcDirective } from '@/directives/imageSrc';
import { scrollDirective } from '@/directives/observeScroll';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('observe-scroll', scrollDirective);
  nuxtApp.vueApp.directive('observe-visibility', visibilityDirective);
  nuxtApp.vueApp.directive('storyblok-editable', storyblokDirective);
  nuxtApp.vueApp.directive('image-src', imageSrcDirective);
  nuxtApp.vueApp.directive('maskito', maskito);
});
