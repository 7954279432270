import { searchProducts, productProjectionSearch } from './apiProduct';
import {
  queryCategoryTree,
  queryCategories,
  queryCategoryById,
  queryCategoryByIds,
  queryCategoryByMopId,
  queryCategoryByPath,
} from './apiCategory';
import { queryDiscounts, queryProductDiscounts } from './apiDiscount';
import { queryProductTypes } from './apiProductTypes';
import type { QueryCategoryParams } from '@/types/category';
import type { ApiCommercetoolsConfig } from '@/types/apiInit';
import type { ProductSearchParams, ProductProjectionSearchParams } from '@/types/product';
import type { QueryCartDiscountParams } from '@/types/cartDiscount';
import type { QueryProductDiscountParams } from '@/types/productDiscount';

export function initApiCommercetools(apiConfig: ApiCommercetoolsConfig) {
  return {
    searchProducts: (params: ProductSearchParams, categoryKeysList = {}) =>
      searchProducts(apiConfig, params, categoryKeysList),
    productProjectionSearch: (params: ProductProjectionSearchParams, categoryKeyslist = {}) =>
      productProjectionSearch(apiConfig, params, categoryKeyslist),
    queryCategoryTree: () => queryCategoryTree(apiConfig),
    queryCategories: (params?: QueryCategoryParams) => queryCategories(apiConfig, params),
    queryCategoryById: (id: string, params?: QueryCategoryParams) => queryCategoryById(apiConfig, id, params),
    queryCategoryByIds: (ids: string[], params?: QueryCategoryParams) => queryCategoryByIds(apiConfig, ids, params),
    queryCategoryByMopId: (mopId: string, params?: QueryCategoryParams) =>
      queryCategoryByMopId(apiConfig, mopId, params),
    queryCategoryByPath: (path: string, params?: QueryCategoryParams) => queryCategoryByPath(apiConfig, path, params),
    queryDiscounts: (params?: QueryCartDiscountParams) => queryDiscounts(apiConfig, params),
    queryProductDiscounts: (params?: QueryProductDiscountParams) => queryProductDiscounts(apiConfig, params),
    queryProductTypes: () => queryProductTypes(apiConfig),
  };
}

export type ApiCommercetools = ReturnType<typeof initApiCommercetools>;
