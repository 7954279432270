<script setup lang="ts">
import type { SlideEventData } from '@mop/types';
import { getHashFromString, isClient } from '@mop/shared/utils/util';
import type { ConcreteComponent } from 'vue';
import { localStorageGet } from '@mop/shared/utils/localStorage';
import UiCmsAnchor from '@mop/ui/components/cms/UiCmsAnchor.vue';
import UiCmsBenefit from '@mop/ui/components/cms/UiCmsBenefit.vue';
import UiCmsCardCarousel from '@mop/ui/components/cms/UiCmsCardCarousel.vue';
import UiCmsCountdown from '@mop/ui/components/cms/UiCmsCountdown.vue';
import UiCmsCta from '@mop/ui/components/cms/UiCmsCta.vue';
import UiCmsDoubleCard from '@mop/ui/components/cms/UiCmsDoubleCard.vue';
import UiCmsFaqImageTiles from '@mop/ui/components/cms/UiCmsFaqImageTiles.vue';
import UiCmsFullWidthBanner from '@mop/ui/components/cms/UiCmsFullWidthBanner.vue';
import UiCmsGenderSwitch from '@mop/ui/components/cms/UiCmsGenderSwitch.vue';
import UiCmsHeadline from '@mop/ui/components/cms/UiCmsHeadline.vue';
import UiCmsHtml from '@mop/ui/components/cms/UiCmsHtml.vue';
import UiCmsImageText from '@mop/ui/components/cms/UiCmsImageText.vue';
import UiCmsRichText from '@mop/ui/components/cms/UiCmsRichText.vue';
import UiCmsSeoTeaser from '@mop/ui/components/cms/UiCmsSeoTeaser.vue';
import UiCmsSeparator from '@mop/ui/components/cms/UiCmsSeparator.vue';
import UiCmsSizeTable from '@mop/ui/components/cms/UiCmsSizeTable.vue';
import UiCmsSlider from '@mop/ui/components/cms/UiCmsSlider.vue';
import UiCmsStaticNavigation from '@mop/ui/components/cms/UiCmsStaticNavigation.vue';
import UiCmsTable from '@mop/ui/components/cms/UiCmsTable.vue';
import UiCmsTextCarousel from '@mop/ui/components/cms/UiCmsTextCarousel.vue';
import UiCmsTimeline from '@mop/ui/components/cms/UiCmsTimeline.vue';
import UiCmsTripleCard from '@mop/ui/components/cms/UiCmsTripleCard.vue';
import UiCmsTripleTeaser from '@mop/ui/components/cms/UiCmsTripleTeaser.vue';
import UiCmsVideoVimeo from '@mop/ui/components/cms/UiCmsVideoVimeo.vue';
import UiCmsElementNotFound from '@mop/ui/components/cms/UiCmsElementNotFound.vue';
import UiCmsImprintText from '@mop/ui/components/cms/UiCmsImprintText.vue';
import MopCmsMemberBenefit from '@/views/members/components/MemberBenefit.vue';
import MopCmsAccordionSearchable from '@/components/mop/cms/AccordionSearchable.vue';
import MopCmsAccountOverviewCards from '@/components/mop/cms/AccountOverviewCards.vue';
import MopCmsHeroCard from '@/components/mop/cms/HeroCard.vue';
import MopCmsProductCarousel from '@/components/mop/cms/ProductCarousel.vue';
import MopCmsProductGrid from '@/components/mop/cms/ProductGrid.vue';
import MopCmsProductRow from '@/components/mop/cms/ProductRow.vue';
import MopCmsPromotionItem from '@/components/mop/cms/PromotionItem.vue';
import MopCmsShopTheLook from '@/components/mop/cms/ShopTheLook.vue';
import MopCmsSuperBannerOverlayCard from '@/components/mop/cms/SuperBannerOverlayCard.vue';
import MopCmsSuperBannerOverlayFlexible from '@/components/mop/cms/SuperBannerOverlayFlexible.vue';
import MopCmsTextPromotionItem from '@/components/mop/cms/TextPromotionItem.vue';
import MopCmsTabNavigation from '@/components/mop/cms/TabNavigation.vue';

import type { CustomerGroup } from '@/types/customer';
import type { CmsContentElementModel, CmsContentElementModelSlotItem, CmsVisibility } from '@/types/cms';

defineOptions({
  name: 'MopCmsContentElementsSlot',
});

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  elements: {
    type: Array as PropType<CmsContentElementModel[]>,
    required: true,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
  lazyLoad: {
    type: Boolean,
    default: false,
  },
  campaignName: {
    type: String,
    default: null,
  },
});

const { customerModelRef } = useMopCustomer();
const { $storyblokLivePreview, $gtm2 } = useNuxtApp();
const isStoryblokLivePreview = $storyblokLivePreview.isEnabled;
const forceLazyLoadFromIndex = 3;
const elementObservers: IntersectionObserver[] = [];
const slotElementRef = ref<HTMLElement>();
const DATA_TRACK_ELEMENT = 'data-track-element';
const DATA_TRACK_ELEMENT_ITEM = 'data-track-element-item';
let currentPathName = '';

const customerGroupsRef = ref(customerModelRef.value.getCustomerGroups());
if (isClient && isStoryblokLivePreview && localStorageGet(constants.LOCAL_STORAGE.PREVIEW_CUSTOMER_GROUP)) {
  // Delay to avoid server client hydration issues
  setTimeout(() => {
    customerGroupsRef.value = [localStorageGet(constants.LOCAL_STORAGE.PREVIEW_CUSTOMER_GROUP) as CustomerGroup];
  }, 500);
}

onMounted(() => {
  currentPathName = window?.location.pathname;
});

// According to https://marc-o-polo.atlassian.net/wiki/spaces/DEV/pages/2431287794/Tracking+Content+Modules#Implementation
function getContentModuleTrackingObject(slotElement: HTMLElement, itemElement?: HTMLElement) {
  const [elementType, elementPosition] = (slotElement.getAttribute(DATA_TRACK_ELEMENT) || '').split('|');
  const [itemElementId, itemElementPosition] = (itemElement?.getAttribute(DATA_TRACK_ELEMENT_ITEM) || '').split('|');
  const id = itemElementId;
  const name = props.campaignName || currentPathName;
  const creative = `${props.id}|${elementType}`;
  const position = `${elementPosition}|${itemElementPosition || 1}`;

  return {
    id,
    name,
    creative,
    position,
  };
}

function trackContentModulesImpressions(slotElement: HTMLElement, slotElementItems: HTMLElement[]) {
  const elementObserver = new IntersectionObserver(
    function (entries) {
      entries.forEach(function (entry) {
        const slotItemElement = entry.target as HTMLElement;
        if (!entry.isIntersecting || !slotItemElement) {
          return;
        }
        const trackingParams = getContentModuleTrackingObject(slotElement, slotItemElement);
        if (!trackingParams) {
          return;
        }
        $gtm2.trackPromotion({
          event: 'view_promotion',
          data: {
            creativeName: trackingParams.id,
            creativeSlot: trackingParams.position,
            promotionId: trackingParams.creative,
            promotionName: trackingParams.name,
          },
        });
        elementObserver!.unobserve(slotItemElement);
      });
    },
    { threshold: 0.4 },
  );

  slotElementItems.forEach(function (element) {
    elementObserver!.observe(element);
  });

  return elementObserver;
}

function handleContentElementMounted(element: any) {
  if (!isClient) {
    return;
  }
  setTimeout(() => {
    const slotElement = element.el;
    const trackingContentElementItems = Array.from(
      slotElement.getAttribute(DATA_TRACK_ELEMENT_ITEM)
        ? [slotElement]
        : slotElement.querySelectorAll(`[${DATA_TRACK_ELEMENT_ITEM}]`),
    ) as HTMLElement[];
    if (trackingContentElementItems.length) {
      elementObservers.push(trackContentModulesImpressions(slotElement, trackingContentElementItems));
    }
  }, 200);
}

onUnmounted(() => {
  elementObservers.forEach((elementObserver) => {
    elementObserver.disconnect();
  });
});

function handleSlotClick(event: MouseEvent) {
  if (!event.target) {
    return;
  }
  const target = event.target as HTMLElement;
  const trackingContentElementItem = target.closest(`[${DATA_TRACK_ELEMENT_ITEM}]`) as HTMLElement;
  if (!trackingContentElementItem) {
    return;
  }
  const trackingContentElement = (
    target.getAttribute(DATA_TRACK_ELEMENT_ITEM)
      ? target
      : trackingContentElementItem.closest(`[${DATA_TRACK_ELEMENT}]`)
  ) as HTMLElement;

  if (!trackingContentElement) {
    return;
  }

  const trackingParams = getContentModuleTrackingObject(trackingContentElement, trackingContentElementItem);
  if (!trackingParams) {
    return;
  }
  $gtm2.trackPromotion({
    event: 'select_promotion',
    data: {
      creativeName: trackingParams.id,
      creativeSlot: trackingParams.position,
      promotionId: trackingParams.creative,
      promotionName: trackingParams.name,
    },
  });
}

const componentMapping = {
  // Global ui components
  Anchor: UiCmsAnchor,
  Benefit: UiCmsBenefit,
  MemberBenefit: MopCmsMemberBenefit,
  CardCarousel: UiCmsCardCarousel,
  Countdown: UiCmsCountdown,
  Cta: UiCmsCta,
  DoubleCard: UiCmsDoubleCard,
  FaqImageTiles: UiCmsFaqImageTiles,
  FullWidthBanner: UiCmsFullWidthBanner,
  GenderSwitch: UiCmsGenderSwitch,
  Headline: UiCmsHeadline,
  Html: UiCmsHtml,
  ImageText: UiCmsImageText,
  RichText: UiCmsRichText,
  SeoTeaser: UiCmsSeoTeaser,
  Separator: UiCmsSeparator,
  SizeTable: UiCmsSizeTable,
  Slider: UiCmsSlider,
  StaticNavigation: UiCmsStaticNavigation,
  Table: UiCmsTable,
  TextCarousel: UiCmsTextCarousel,
  Timeline: UiCmsTimeline,
  TripleCard: UiCmsTripleCard,
  TripleTeaser: UiCmsTripleTeaser,
  VideoVimeo: UiCmsVideoVimeo,
  ImprintText: UiCmsImprintText,
  // Theme components
  AccordionSearchable: MopCmsAccordionSearchable,
  AccountOverviewCards: MopCmsAccountOverviewCards,
  HeroCard: MopCmsHeroCard,
  ProductCarousel: MopCmsProductCarousel,
  ProductGrid: MopCmsProductGrid,
  ProductRow: MopCmsProductRow,
  PromotionItem: MopCmsPromotionItem,
  TextPromotionItem: MopCmsTextPromotionItem,
  ShopTheLook: MopCmsShopTheLook,
  SuperBannerOverlayCard: MopCmsSuperBannerOverlayCard,
  SuperBannerOverlayFlexible: MopCmsSuperBannerOverlayFlexible,
  TabNavigation: MopCmsTabNavigation,
};

function getComponent(element: CmsContentElementModel) {
  // @ts-ignore
  const component = componentMapping[element.getType()] || UiCmsElementNotFound;
  return component as ConcreteComponent | string;
}

function getComponentKey(element: CmsContentElementModel) {
  if (!isStoryblokLivePreview) {
    return element.getUid();
  }
  return getHashFromString(JSON.stringify(element.getData()));
}

function getComponentData(element: CmsContentElementModel) {
  return element.getData();
}

const elementsRef = computed(() => {
  return props.elements.reduce((elementList: CmsContentElementModelSlotItem[], element) => {
    const isVisible: boolean = isVisibleForCustomer(element.getVisibility(), customerGroupsRef.value);
    if (!isStoryblokLivePreview && !isVisible) {
      return elementList;
    }

    const componentData = getComponentData(element);
    if (!componentData) {
      return elementList;
    }

    if (element.getType() === 'Slider') {
      componentData.slides?.forEach((slide: { isVisible: boolean; visibility: CmsVisibility }) => {
        slide.isVisible = isVisibleForCustomer(slide.visibility, customerGroupsRef.value);
      });
    }

    componentData.isProductionBuild = isProductionBuild;

    const returnElement: CmsContentElementModelSlotItem = {
      ...element,
      componentKey: getComponentKey(element),
      componentData,
      componentName: getComponent(element),
      componentIsVisible: isVisible,
    };

    elementList.push(returnElement);
    return elementList;
  }, []);
});

function handleSlideChange(element: HTMLElement, slideData: SlideEventData) {
  // wasAutoScroll is not very reliable - Sliders might be replaced anyway
  if (slideData.wasAutoScroll) {
    return;
  }
  const trackingParams = getContentModuleTrackingObject(element);
  if (!trackingParams) {
    return;
  }
  const componentType = trackingParams.creative.split('|')[1];

  $gtm2.reportLegacyEngagement({
    event: 'promoInteraction',
    category: componentType,
    label: 'slide',
  });
}
</script>

<template>
  <div
    :id="`cms-content-slot-${id}`"
    ref="slotElementRef"
    :class="['cms-content-slot', { 'cms-content-slot--no-padding': noPadding }]"
    @click="handleSlotClick"
  >
    <div
      v-for="(element, index) in elementsRef"
      :key="element.componentKey"
      :element-id="element.getUid()"
      :class="[
        'cms-content-slot__row',
        `cms-content-slot__row--device-${element.getDeviceVisibility()}`,
        {
          'cms-content-slot__row--hidden': element.getVisibility() === constants.STORYBLOK.VISIBILITY_HIDDEN,
        },
      ]"
    >
      <span v-if="element.getCustomId()" :id="element.getCustomId()" class="cms-content-slot__anchor" />
      <component
        :is="element.componentName"
        v-if="element.componentIsVisible !== false"
        :class="`element-count-${elements.length}`"
        :data="element.componentData"
        :component-count="elements.length"
        :lazy-load="lazyLoad || index >= forceLazyLoadFromIndex"
        :data-track-element="`${element.getType()}|${index + 1}`"
        @vue:mounted="handleContentElementMounted"
        v-on="
          ['Slider', 'CardCarousel', 'ProductCarousel'].includes(element.getType())
            ? { 'slide-change': handleSlideChange }
            : {}
        "
      />
      <div
        v-if="
          isStoryblokLivePreview &&
          element.getVisibility() &&
          element.getVisibility() !== constants.STORYBLOK.VISIBILITY_ALL
        "
        v-storyblok-editable="element.componentData"
        class="cms-content-slot__visibility"
      >
        <span class="cms-content-slot__visibility-icon"> ! </span>
        <span class="cms-content-slot__visibility-detail">
          {{ element.getVisibility() }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.cms-content-slot--no-padding {
  &:deep(.ui-cms-padding) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cms-content-slot__row {
  position: relative;
  width: 100%;

  .wrapper__iframe-live-preview & {
    min-height: 20px;
  }
}

.cms-content-slot__anchor {
  display: inline-block;
  position: absolute;
  top: -$sticky-big-header-height;
}

.cms-content-slot__visibility-detail {
  display: inline-block;
}

.cms-content-slot__row--hidden > div:not(.cms-content-slot__visibility) {
  display: none;
}

.cms-content-slot__visibility {
  position: absolute;
  top: 0;
  left: 0;
  background: $signal-alert;
  color: $white;
  font-size: 9px;
  padding: 0 5px;

  @include hover {
    .cms-content-slot__visibility-icon {
      display: none;
    }
  }
}

.cms-content-slot__row--device-desktop {
  @include apply-upto(small) {
    display: none;
  }
}

.cms-content-slot__row--device-mobile {
  @include apply-from(small) {
    display: none;
  }
}
</style>
