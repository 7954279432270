import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ssrCache } from '@/api/utils/ssrCache';
import type {
  ProductDiscountListModel,
  ProductDiscountResponseData,
  QueryProductDiscountParams,
  ProductDiscountModel,
} from '@/types/productDiscount';
import type { ProductPrice } from '@/types/product';
import { productDiscountListModel } from '@/models';

type DiscountComposableStorage = {
  productDiscountListRef: Ref<ProductDiscountListModel>;
  productDiscountMapRef: Ref<Record<string, ProductDiscountModel>>;
};

export default function useMopProductDiscounts() {
  const nuxtApp = useNuxtApp();
  const storage = initStorage<DiscountComposableStorage>('useMopProductDiscounts');
  const cacheId = `product-discounts-${nuxtApp.$mopI18n.locale}`;
  const redisCache = ssrCache();
  const responseRef = useMopSSR<ProductDiscountResponseData | null>(cacheId, null);
  const productDiscountListRef =
    storage.get('productDiscountListRef') ??
    storage.saveAndGet('productDiscountListRef', ref(productDiscountListModel(responseRef.value)));
  const productDiscountMapRef =
    storage.get('productDiscountMapRef') ?? storage.saveAndGet('productDiscountMapRef', ref({}));

  /**
   * Called on app init.
   * Do not init elsewhere.
   */
  async function initAvailableProductDiscounts() {
    responseRef.value ??= await redisCache.get<ProductDiscountResponseData>(cacheId, getEnrichedProductDiscounts);
    productDiscountListRef.value = productDiscountListModel(responseRef.value);
    productDiscountMapRef.value = getProductDiscountMap();
  }

  async function getEnrichedProductDiscounts() {
    const params: QueryProductDiscountParams = {
      queryArgs: {
        sort: 'sortOrder desc',
        where: 'isActive = true',
        limit: 100,
      },
    };
    return await nuxtApp.$apiCommercetools.queryProductDiscounts(params);
  }

  function getProductDiscountMap() {
    const productDiscountMap: {
      [string: string]: ProductDiscountModel;
    } = {};
    productDiscountListRef.value.getDiscountModelList().forEach((productDiscount: ProductDiscountModel) => {
      productDiscountMap[productDiscount.getId()] = productDiscount;
    });
    return productDiscountMap;
  }

  function getActiveProductDiscount(price: ProductPrice) {
    if (productDiscountMapRef.value) {
      return productDiscountMapRef.value[price.productDiscountId ?? ''];
    }
  }

  return securedWrap({
    initAvailableProductDiscounts,
    getActiveProductDiscount,
  });
}
