import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { StoreModel, StoreSearchParams } from '@/types/store';
import { storeListModel, storeModel } from '@/models';

type LoadingState = {
  searchStores: boolean;
  loading: boolean;
};

export default function useMopStoreFinder() {
  const loadingRef: Ref<LoadingState> = ref({
    searchStores: false,
    loading: computed(() => isLoading(loadingRef)),
  });

  const storeListModelRef = ref(storeListModel(null));
  const storeModelRef = ref<StoreModel>();

  async function searchStores(params: StoreSearchParams) {
    loadingRef.value.searchStores = true;
    const result = await useNuxtApp().$apiBff.getStores(params);
    storeListModelRef.value = storeListModel(result);
    loadingRef.value.searchStores = false;
    return result.data?.code;
  }

  async function searchStore(id: string, params?: { ean?: string }) {
    loadingRef.value.searchStores = true;
    const result = await useNuxtApp().$apiBff.getStore(id, params);
    if (result.data?.store) {
      storeModelRef.value = storeModel(result.data.store);
    }
    loadingRef.value.searchStores = false;
    return result.data?.code;
  }

  async function searchStoreByGln(gln: string) {
    loadingRef.value.searchStores = true;
    const result = await useNuxtApp().$apiBff.getStoreByGln(gln);
    if (result.data?.store) {
      storeModelRef.value = storeModel(result.data.store);
    }
    loadingRef.value.searchStores = false;
    return result.data?.code;
  }

  return securedWrap({
    storeListModelRef,
    storeModelRef,
    searchStores,
    searchStore,
    searchStoreByGln,
    loadingRef,
  });
}
