import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type {
  BffApiWishlistGet,
  BffApiWishlistLineItemsPost,
  BffApiWishlistLineItemsProductKeyDelete,
} from '@/types/bff';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
const API_NAME = 'AWS - BFF SHOPPING LISTS';

export function getWishlist(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiWishlistGet>> {
  const url = getBffUrlWithParams('/wishlist', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiWishlistGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getWishlist',
      url,
    }),
  }));
}

export function addWishlistItem(
  apiConfig: ApiBffConfig,
  params: { productKey: string; quantity?: number },
): Promise<ApiResponse<BffApiWishlistLineItemsPost>> {
  const url = getBffUrlWithParams('/wishlist/line-items', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiWishlistLineItemsPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'addWishlist',
      url,
    }),
  }));
}

export function removeWishlistItem(
  apiConfig: ApiBffConfig,
  params: { productKey: string },
): Promise<ApiResponse<BffApiWishlistLineItemsProductKeyDelete>> {
  const url = getBffUrlWithParams(`/wishlist/line-items/${params.productKey}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiWishlistLineItemsProductKeyDelete>>(async () => ({
    data: (
      await apiConfig.apiClient.delete(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'removeWishlistItem',
      url,
    }),
  }));
}
