import type { Filter, FilterValueModel, FilterModel, FilterListResponseData } from '@/types/filters';
import { filterValuesModel, filterValueModel } from '@/models';

export function filterModel(response: Filter, searchableAttributes?: any) {
  return {
    getSlug(): string {
      return convertToSlug(response.name);
    },
    getRawName(): string {
      return response.name;
    },
    getName(): string {
      return searchableAttributes?.[response.name]?.label ?? response.name;
    },
    getValues(): FilterValueModel[] {
      const labels = searchableAttributes?.[response.name]?.labelValues ?? {};
      const fieldType = searchableAttributes?.[response.name]?.fieldType;
      // filter out comma seperated values
      if (this.isColor()) {
        return filterValuesModel(response?.buckets.filter((item) => !item.key.includes(',')) ?? [], labels, fieldType);
      } else if (this.isPriceRange()) {
        let min = 0;
        let max = 0;
        response?.buckets.forEach((item) => {
          const val = parseInt(item.key);
          if (!min || val < min) {
            min = val;
          }
          if (!max || val > max) {
            max = val;
          }
        });

        return [
          filterValueModel(
            {
              key: 'prices',
              min,
              max,
              count: 0,
            },
            labels,
          ),
        ];
      }
      return filterValuesModel(response?.buckets ?? [], labels, fieldType);
    },
    getKey(): string {
      if (this.isPriceRange()) {
        const priceVals = this.getValues()[0];
        return `price-${priceVals.getMin()}-${priceVals.getMax()}`;
      }
      return `${this.getSlug()}`;
    },
    isPriceRange(): boolean {
      return response.name === 'prices';
    },
    hasPriceRange(): boolean {
      return this.isPriceRange() && response?.buckets && response.buckets.length >= 3;
    },
    isColor(): boolean {
      return response.name === 'refinementColor';
    },
    getDebugData(): any {
      return response;
    },
  };
}

export function filterListModel(responseData: FilterListResponseData, searchableAttributes?: any) {
  const response: FilterListResponseData = responseData || null;
  return {
    hasError(): boolean {
      return response?.statusCode !== undefined && response.statusCode !== 200;
    },

    isEmpty(): boolean {
      return (
        !response?.body.facets.length || response.body.facets.every((facet) => !(facet as Filter)?.buckets?.length)
      );
    },

    getFilterModelList(): FilterModel[] {
      if (!response?.body) {
        return [];
      }

      return response.body.facets.map((filterData) => filterModel(filterData as Filter, searchableAttributes));
    },
  };
}
