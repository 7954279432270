import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { BffApiBackinstockSubscriptionPostRequest, BffApiBackinstockSubscriptionPostResponse } from '@/types/bff';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
const API_NAME = 'AWS - BFF BACK IN STOCK';

export function registerBackInStockNotification(
  apiConfig: ApiBffConfig,
  params: BffApiBackinstockSubscriptionPostRequest,
): Promise<ApiResponse<BffApiBackinstockSubscriptionPostResponse>> {
  const url = getBffUrlWithParams('/back-in-stock/subscription', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiBackinstockSubscriptionPostResponse>>(async () => ({
    data: (await apiConfig.apiClient.post(url, params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'registerBackInStockNotification',
      url,
    }),
  }));
}
