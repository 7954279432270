import type { ClientResponse, ProductPagedSearchResponse } from '@commercetools/platform-sdk';
import type { Pagination } from '@/types/product';

export function paginationModel(productPagedSearchResponse: ClientResponse<ProductPagedSearchResponse> | null) {
  const pagination: Pagination = {
    total: productPagedSearchResponse?.body?.total || 0,
    perPage: productPagedSearchResponse?.body?.limit || 48,
  };

  return {
    getLastPage(): number {
      return Math.ceil(pagination.total / pagination.perPage);
    },
    getTotal(): number {
      return pagination.total;
    },
  };
}
