import type { ApiResponse } from '@/types/apiInit';
import type { BffApiCartsPaymentMethodsPost } from '@/types/bff';

type PaymentMethod = BffApiCartsPaymentMethodsPost['paymentMethods'][0] & {
  position: number;
};

export function paymentModel(responseData: ApiResponse<BffApiCartsPaymentMethodsPost> | null) {
  const response = responseData ?? {};

  const arvatoPayments: PaymentMethod[] = [];
  const adyenPayments: PaymentMethod[] = [];

  response.data?.paymentMethods?.forEach((item, index) => {
    // id is used for stored methods, push to new array when time comes and do sorting
    // @ts-ignore missing type
    if (item.type === PAYMENT_METHOD.GIFTCARD || item.id) {
      return;
    }
    const paymentMethod: PaymentMethod = {
      position: index,
      ...item,
    };
    if (item.provider === 'Arvato') {
      arvatoPayments.push(paymentMethod);
    } else {
      adyenPayments.push(paymentMethod);
    }
  });

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getAdyenPaymentMethods() {
      return adyenPayments ?? [];
    },

    isArvatoPayment(paymentMethod: string) {
      return arvatoPayments.some((item) => item.type === paymentMethod);
    },

    getArvatoPaymentMethods() {
      return arvatoPayments ?? [];
    },

    getPaymentMethodByType(type: string) {
      return response.data?.paymentMethods?.find((item) => item.type === type);
    },

    getPaymentIconList(paymentMethodType: string) {
      const result: string[] = [];
      const payment = this.getPaymentMethodByType(paymentMethodType);

      if (payment && 'brands' in payment) {
        payment.brands?.forEach((brand) => {
          result.push(`${paymentMethodType}${brand}`);
        });
      } else {
        result.push(paymentMethodType);
      }

      return result;
    },

    getDebugData(): any {
      return response;
    },
  };
}
