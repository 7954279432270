import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { ProductModel, VariantModel } from '@/types/product';
import type {
  GtmProductTracking,
  GtmPageViewEvent,
  GtmPageViewParams,
  GtmProductTrackingParams,
  GtmCartContentEvent,
  GtmCheckoutEvent,
  GtmCheckoutEventParams,
} from '@/types/gtm';

function getProductTrackingObject(productTrackingParams: GtmProductTrackingParams): GtmProductTracking {
  const product: ProductModel = productTrackingParams.product;
  const variant: VariantModel | undefined = productTrackingParams.variant;
  const priceInformation = variant ? variant.getPrice() : product.getPrice();
  return {
    id: product.getMopMasterId(),
    brand: product.getSimpleBrandName(),
    gender: product.getGender(),
    product_variation_id: variant?.getSku() || '',
    product_id: product.getMopId(),
    category: getCategoryPath(productTrackingParams),
    product_category: product.getPrimaryCategoryMopId(),
    list: productTrackingParams.list || 'na',
    quantity: productTrackingParams.quantity,
    sale: priceInformation.salePrice !== priceInformation.basePrice,
    position: productTrackingParams.position || 1,
    name: product.getName(),
    price:
      productTrackingParams.price || (variant ? variant.getPrice().salePriceNet : priceInformation.salePriceNet) || 0,
    salepercent: priceInformation.salePercentage ?? 0,
    saletotal: priceInformation.basePrice * ((priceInformation.salePercentage ?? 0) / 100),
    stock: variant?.getAvailability()?.quantity,
    size: variant?.getSize() || '',
    season: product.getSeason(),
    variant: product.getColorId(),
    shipFromStoreProduct: variant?.getAvailability().isShipFromStore || false,
    pdpVideo: Boolean(productTrackingParams.product.getVimeoId()),
    sustainablematerial: product.getSustainabilityId(),
    correctionGlasses: product.isCorrectionGlasses(),
    virtualTryOnPotential: product.isVirtualTryOnEnabled(),
    virtualTryOnDone: product.isProductTriedWithVirtualTryOn(),
  };
}

function getPageViewEvent(pageViewParams: GtmPageViewParams): GtmPageViewEvent {
  return {
    Landingpage: pageViewParams.landingPage || false,
    UserId: pageViewParams.userId || '',
    PageCategoryID: pageViewParams.pageCategoryId || '',
    PageCountryCode: pageViewParams.pageCountryCode || '',
    PageDivision: pageViewParams.pageCategoryId ? getDivision(pageViewParams.pageCategoryId) : '',
    PageLanguageCode: pageViewParams.pageLanguageCode || '',
    PageType: pageViewParams.pageType,
    PageTitle: pageViewParams.pageTitle || '',

    Privacy_OptOut_Performance: pageViewParams.privacyOptOutPerformance || 0,
    errorCode: pageViewParams.errorCode || '',
    paymentMethode: pageViewParams.paymentMethod || '',
    paymentProvider: pageViewParams.paymentProvider || '',
    shippingProvider: pageViewParams.shippingProvider || '',
    GlobalE: pageViewParams.globalE || false,

    MopTestName: pageViewParams.abTestName || 'na',
    MopTestVariant: pageViewParams.abTestVariant || 'na',
  };
}

function getDivision(categoryMopId: string) {
  if (constants.VIRTUAL_TOP_CATEGORIES.includes(categoryMopId)) {
    return categoryMopId.split('-')[1];
  }
  return categoryMopId.split('-')[0] || '';
}

function getCartContentEvent(
  productTrackingParams: GtmProductTrackingParams[],
  currencyCode?: string,
): GtmCartContentEvent {
  const cartContent = productTrackingParams.map((productTrackingParam: GtmProductTrackingParams) => {
    return getProductTrackingObject(productTrackingParam);
  });

  return {
    event: 'cartContent',
    currencyCode,
    cartContent,
  };
}

function getCheckoutEvent(checkoutEventParams: GtmCheckoutEventParams): GtmCheckoutEvent {
  const actionField: { step: number; option?: string } = {
    step: checkoutEventParams.step,
  };
  if (checkoutEventParams.option) {
    actionField.option = checkoutEventParams.option;
  }
  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField,
        products: checkoutEventParams.products.map((orderProduct: GtmProductTrackingParams) => {
          return getProductTrackingObject(orderProduct);
        }),
      },
    },
  };
}

function getCategoryPath(productTrackingParams: GtmProductTrackingParams): string {
  let categoryPath = '';
  const product: ProductModel = productTrackingParams.product;

  if (!productTrackingParams.category) {
    categoryPath = [product.getBreadcrumbPath().split('-').join('/'), product.getMopId()].join('/');
  } else {
    const category: string = productTrackingParams.category;
    categoryPath = category.includes(product.getMopId()) ? category : [category, product.getMopId()].join('/');
  }
  return categoryPath.replace(/^\//, '');
}

export const GtmUtils = securedWrap({
  getCartContentEvent,
  getPageViewEvent,
  getCheckoutEvent,
});
