import { isClient } from '@mop/shared/utils/util';
import type { AbTestServerSideData } from '@/types';

type AbTestCookie = Ref<string>;
type AbTestComposableStorage = {
  [AB_TEST.QUERY_NAME]: AbTestCookie;
};

const NOT_AB_TEST_TRAFFIC_COOKIE_VALUE = 'none';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  if (!app.$mopI18n) {
    return;
  }

  const route = useRoute();

  const serverSideAbTestValue = String(route.query[AB_TEST.QUERY_NAME] || '');
  const storage = initStorage<AbTestComposableStorage>('useMopRouter');
  const activeServerSideAbTest: AbTestCookie =
    storage.get(AB_TEST.QUERY_NAME) ??
    storage.saveAndGet(AB_TEST.QUERY_NAME, useMopSSR(AB_TEST.QUERY_NAME, serverSideAbTestValue));

  const { $mopConfig, $cookie } = app;
  const cookieValue = getAbTestCookieValue();

  function getSortingType(): string {
    let sortingType = 'sorting-control';
    if (cookieValue && ['sorting-control', 'sorting-variantA', 'sorting-variantB'].includes(cookieValue)) {
      sortingType = cookieValue;
    }
    return sortingType;
  }

  function getAbTestCookieValue() {
    if (!isClient || !$mopConfig.isAbTestCookieEnabled()) {
      return;
    }
    const abTestData = $mopConfig.getAbTestCookieData();
    if (!abTestData) {
      return;
    }
    const cookieName: string = constants.COOKIE.AB_TEST;
    const possibleCookieValues: string[] = [
      NOT_AB_TEST_TRAFFIC_COOKIE_VALUE,
      ...abTestData.groups.map((group) => group.value),
    ];

    if (possibleCookieValues.includes($cookie.get(cookieName))) {
      return $cookie.get(cookieName);
    }

    const randomNumberTrafficShare = Math.floor(Math.random() * 100) + 1;
    let cookieValue = '';
    if (randomNumberTrafficShare >= abTestData.shareOfTrafficPercentage) {
      // Not part of AB test
      cookieValue = NOT_AB_TEST_TRAFFIC_COOKIE_VALUE;
    } else {
      const randomNumberGroupPercentage = Math.floor(Math.random() * 100) + 1;
      let percentageSum = 0;
      const abTestGroup = abTestData.groups.find((group) => {
        percentageSum += group.percentage;
        return randomNumberGroupPercentage <= percentageSum;
      });
      if (abTestGroup?.value) {
        cookieValue = abTestGroup.value;
      }
    }

    if (!cookieValue) {
      return;
    }

    $cookie.store(cookieName, cookieValue);
    return cookieValue;
  }

  function getAbTestServerSideData(): AbTestServerSideData {
    const [abTestName = ''] = activeServerSideAbTest.value.split('|');
    return {
      abTestName,
      abTestVariant: activeServerSideAbTest.value,
    };
  }
  nuxtApp.provide('abTestSortingType', getSortingType());
  nuxtApp.provide('abTestServerSide', getAbTestServerSideData());
});
