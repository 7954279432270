<script setup lang="ts">
import SVGRight from '@mop/shared/images/arrow/right.svg?component';
import { getHtmlFromRichText } from '@mop/cms/utils/utils';
import { globalEShopLocale } from '@/i18n/localeList';

defineOptions({
  name: 'MopCountrySelector',
});

const emit = defineEmits(['overlay-override']);

const { $mopI18n, $storyblokLivePreview } = useNuxtApp();
const { getCmsStory, cmsStoryModelRef } = useMopCms(constants.STORYBLOK.STORY_PATHS.COUNTRY_SELECTOR);
const { storeSelectedCountry, getLocalisationCountry } = useMopCountrySelector();

const locale = $mopI18n.locale;

const countryRef = ref(getLocalisationCountry() || $mopI18n.country);
const localeRef = ref($mopI18n.getBestMatchingBrowserLocale(countryRef.value));

const loadingRef = ref(false);

const countrySelectItems = $mopI18n.availableCountries
  .map((country) => ({
    text: $mopI18n.t(`locale.country.${country}`),
    value: country,
  }))
  .sort((a, b) => a.text.toString().localeCompare(b.text.toString()));

const localeSelectItemsRef = ref<{ text: string; value: string }[]>([]);

onMounted(async () => {
  emit('overlay-override', { loading: true });
  await getCmsStory(constants.STORYBLOK.STORY_PATHS.COUNTRY_SELECTOR);
  nextTick(() => {
    emit('overlay-override', { loading: false });
  });
});

$storyblokLivePreview.initStoryListener('CountrySelector', cmsStoryModelRef);

watch(
  countryRef,
  (country) => {
    const availableLocales = $mopI18n.getLocalesFromCountry(country);
    localeSelectItemsRef.value = availableLocales
      .map((code) => {
        const language = code.split('-')[0];
        return {
          text: $mopI18n.t(`locale.lang.${language}`),
          value: code,
        };
      })
      .sort((a, b) => a.text.toString().localeCompare(b.text.toString()));
    localeRef.value =
      localeSelectItemsRef.value.length === 1
        ? localeSelectItemsRef.value[0].value
        : $mopI18n.getBestMatchingBrowserLocale(country);
  },
  { immediate: true },
);

const submitCountryHandler = async () => {
  const overlay = useMopOverlay();
  const { performLocaleRedirect } = useMopRouter();
  storeSelectedCountry(countryRef.value);
  const oldLocale = locale;
  if (oldLocale === localeRef.value) {
    await overlay.closeAll();
    return;
  }
  loadingRef.value = true;

  performLocaleRedirect(oldLocale, localeRef.value!, globalEShopLocale);
};

const cmsContentState = computed(() => {
  if (!cmsStoryModelRef.value || !cmsStoryModelRef.value.isInitialized() || cmsStoryModelRef.value.hasError()) {
    return {};
  }
  const richText = getHtmlFromRichText(cmsStoryModelRef.value.getAttribute('richText'));
  const country = cmsStoryModelRef.value.getAttribute('country');
  const language = cmsStoryModelRef.value.getAttribute('language');
  const ctaText = cmsStoryModelRef.value.getAttribute('ctaText');
  const editableData = cmsStoryModelRef.value.getContentEditableObject();
  return {
    richText,
    country,
    language,
    ctaText,
    editableData,
  };
});
</script>

<template>
  <div>
    <UiForm
      v-if="cmsContentState.editableData"
      v-storyblok-editable="cmsContentState.editableData"
      class="country-selector"
      @submit="submitCountryHandler"
    >
      <div class="country-selector__text" v-html="cmsContentState.richText" />

      <div class="country-selector__form">
        <UiFormGroup :label="cmsContentState.country" class="country-selector__from-group">
          <UiFormSelect id="countrySelect" v-model="countryRef" name="country" :items="countrySelectItems" />
        </UiFormGroup>

        <UiFormGroup :label="cmsContentState.language" class="country-selector__from-group">
          <UiFormSelect
            id="languageSelect"
            :key="localeRef"
            v-model="localeRef"
            name="language"
            :items="localeSelectItemsRef"
            :disabled="localeSelectItemsRef.length < 2"
          />
        </UiFormGroup>
      </div>
      <button type="submit" class="country-selector__button">
        {{ cmsContentState.ctaText }}

        <SVGRight class="country-selector__arrow" width="15" height="15" />
      </button>
    </UiForm>
  </div>
</template>

<style lang="scss" scoped>
.country-selector {
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.country-selector__text {
  @include text-style(common);

  padding-bottom: $space20;

  @include apply-upto(small) {
    order: 1;
    padding: $space10 0;
  }

  // from storyblok rich text
  :deep(p) {
    @include text-style(common);

    margin: 0;
  }
}

.country-selector__form {
  width: 100%;
  max-width: 300px;

  @include apply-upto(small) {
    max-width: 100%;
  }
}

.country-selector__from-group {
  :deep(.form-group__row) {
    margin-bottom: $space10;
  }

  :deep(.form-group__content) {
    margin-right: 0;
  }

  :deep(.form-group__label) {
    @include text-style(common);

    flex: 1 0 0;
    padding-right: columns(1);
    width: columns(36);

    @include apply-upto(small) {
      padding: 0 0 $space5;
    }
  }
}

.country-selector__button {
  @include link-neutral($space10);
  @include text-style(strong);

  text-transform: uppercase;
  margin-top: $space10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 21px;

  @include apply-upto(small) {
    order: 2;
    margin-top: 0;
  }
}
</style>
