import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { FinishGloablEResponseData } from '@/types/orders';
import type { BffApiAccountOrderOrderIdGet, BffApiAccountOrdersGet, BffApiGetCheckoutOrder } from '@/types/bff';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';

const API_NAME = 'AWS - BFF ORDERS';

export function getOrders(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAccountOrdersGet>> {
  const url = getBffUrlWithParams('/account/orders', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountOrdersGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getOrders',
      url,
    }),
  }));
}

export function getOrder(
  apiConfig: ApiBffConfig,
  orderId: string,
  customerNumber: string,
): Promise<ApiResponse<BffApiAccountOrderOrderIdGet>> {
  const url = getBffUrlWithParams(`/account/order/${orderId}?customer_number=${customerNumber}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountOrderOrderIdGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getOrder',
      url,
    }),
  }));
}

export function getCheckoutOrder(
  apiConfig: ApiBffConfig,
  params: { orderId: string; externalOrderId: string },
): Promise<ApiResponse<BffApiGetCheckoutOrder>> {
  const url = getBffUrlWithParams(
    `/checkout-order?externalOrderId=${params.externalOrderId}&orderId=${params.orderId}`,
    apiConfig,
  );
  return bffErrorHandler<ApiResponse<BffApiGetCheckoutOrder>>(async () => ({
    data: (await apiConfig.apiClient.get(url)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCheckoutOrder',
      url,
    }),
  }));
}
export function finishGlobalEOrder(
  apiConfig: ApiBffConfig,
  params: { externalOrderId: string },
): Promise<FinishGloablEResponseData> {
  const url = getBffUrlWithParams(`/finish-globale-order`, apiConfig);

  return bffErrorHandler<FinishGloablEResponseData>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          externalOrderId: params.externalOrderId,
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'finishGlobalEOrder',
      url,
    }),
  }));
}
