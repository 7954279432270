import type { AxiosError } from 'axios';
import { handleAxiosError, getBffUrlWithParams, bffErrorHandler } from '@/api/utils';
import type { CreateReservationData, ReservationStatus } from '@/types/reservation';
import type {
  BffApiReservationGet,
  BffApiReservationsGet,
  BffApiReservationPost,
  BffApiReservationDelete,
} from '@/types/bff';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
const API_NAME = 'AWS - BFF RESERVATION';

export function getReservations(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiReservationsGet>> {
  const url = getBffUrlWithParams('/reservations', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiReservationsGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getReservations',
      url,
    }),
  }));
}

export function getReservationById(
  apiConfig: ApiBffConfig,
  reservationId: string,
): Promise<ApiResponse<BffApiReservationGet>> {
  const url = getBffUrlWithParams(`/reservations/${reservationId}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiReservationGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getReservationById',
      url,
    }),
  }));
}

export function createReservation(
  apiConfig: ApiBffConfig,
  reservationData: CreateReservationData,
): Promise<ApiResponse<BffApiReservationPost>> {
  const url = getBffUrlWithParams('/reservations', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiReservationPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, reservationData, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createReservation',
      url,
    }),
  }));
}

export function requestReservation(
  apiConfig: ApiBffConfig,
  reservationStatus: ReservationStatus,
  reservationId: string,
): Promise<ApiResponse<BffApiReservationGet>> {
  const url = getBffUrlWithParams(`/reservations/${reservationId}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiReservationGet>>(async () => ({
    data: (
      await apiConfig.apiClient.patch(
        url,
        {
          reservationStatus,
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'requestReservation',
      url,
    }),
  }));
}

export function deleteReservation(
  apiConfig: ApiBffConfig,
  reservationId: string,
): Promise<ApiResponse<BffApiReservationDelete>> {
  const url = getBffUrlWithParams(`/reservations/${reservationId}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiReservationDelete>>(async () => ({
    data: (
      await apiConfig.apiClient.delete(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'deleteReservation',
      url,
    }),
  }));
}
