import type { ProductVariant, ProductSearchProjectionParams } from '@commercetools/platform-sdk';
import { isClient } from '@mop/shared/utils/util';
import { getAvailableLanguages } from '@/i18n/localeList';
import type { MopI18n } from '@/types/locale';

const availableLanguages = getAvailableLanguages();

export function unwrapLocalizedStringVariantAttributes(variant?: ProductVariant): Record<string, any> {
  return (
    variant?.attributes?.reduce((attributesObj: Record<string, any>, attribute) => {
      if (Array.isArray(attribute.value) && attribute.value.length > 0 && typeof attribute.value[0] === 'object') {
        // Translateble enums
        const items: any[] = [];
        attribute.value.forEach((attributeItem) => {
          if (attributeItem.key && attributeItem.label) {
            const keys = Object.keys(attributeItem.label);
            if (keys.length === 1 && availableLanguages.includes(keys[0])) {
              items.push(Object.values(attributeItem.label)[0]);
              attributesObj[attribute.name] = items;
            } else {
              attributesObj[attribute.name] = attributeItem.label;
            }
          }
        });
      } else if (typeof attribute.value === 'object') {
        // Translatable strings
        const keys = Object.keys(attribute.value);
        if (keys.length === 1 && availableLanguages.includes(keys[0])) {
          attributesObj[attribute.name] = Object.values(attribute.value)[0];
        }
      }

      if (!attributesObj[attribute.name]) {
        attributesObj[attribute.name] = attribute.value;
      }

      return attributesObj;
    }, {}) ?? {}
  );
}

export function getProductProjectionParameters(
  $mopI18n: MopI18n,
  customerGroupId: string,
): ProductSearchProjectionParams {
  return {
    localeProjection: [$mopI18n.lang],
    storeProjection: $mopI18n.commercetoolsCountry.toUpperCase(),
    priceCountry: $mopI18n.commercetoolsCountry.toUpperCase(),
    priceCurrency: $mopI18n.currency,
    priceCustomerGroup: isClient && customerGroupId ? customerGroupId : undefined,
  };
}
