import { getHashFromString } from '@mop/shared/utils/util';
import type { PaymentReference, CentPrecisionMoney } from '@commercetools/platform-sdk';
import type { CartLineItemModel, CartDiscountCode, BffResponseWithCart } from '@/types/cart';
import type { GiftCard } from '@/types/product';
import { cartLineItemModel } from '@/models';
import { getMappedAddress, isValidAddress } from '@/models/utils/addressUtils';
import type { ApiResponse } from '@/types/apiInit';
import type { BffCart } from '@/types/bff';

export function cartModel(responseData: ApiResponse<BffResponseWithCart> | null) {
  const response = responseData ?? {};
  const cartResponse = response.data && 'cart' in response.data ? response.data.cart : ({} as BffCart);

  let giftCardPaymentMethod: PaymentReference | undefined | null = null;
  let lineItems: CartLineItemModel[] = [];
  const soldOutlineItems: CartLineItemModel[] = [];
  let subTotal = 0;
  let checkoutSubTotal = 0;
  let isBackOrder = false;
  let isSoldOut = true;
  let totalItemCount = 0;
  let totalItemCountExcludingGiveaway = 0;
  let giftCardProductCount = 0;

  if (cartResponse?.lineItems?.length) {
    cartResponse.lineItems.forEach((item) => {
      const lineItem = cartLineItemModel(item);
      const { basePrice, salePrice } = lineItem.getTotalPrice();
      subTotal += basePrice;
      checkoutSubTotal += salePrice;
      const variant = lineItem.getVariant();
      const availability = variant.getAvailability();

      totalItemCount += lineItem.getQuantity();
      if (!variant.isGiveaway()) {
        totalItemCountExcludingGiveaway += lineItem.getQuantity();
      }
      if (availability.isInStock) {
        isSoldOut = false;
        lineItems.push(lineItem);
      } else {
        soldOutlineItems.push(lineItem);
      }
      if (!isBackOrder) {
        isBackOrder = availability.isBackOrder;
      }
      if (lineItem.isGiftCard()) {
        giftCardProductCount += 1;
      }
    });

    // ensure sold out items are always in the bottom
    lineItems = lineItems.concat(soldOutlineItems);
  }

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    isInitialized(): boolean {
      return response !== null && response.data !== undefined && 'cart' in response.data;
    },

    getCount(includeGiveAway = true): number {
      return includeGiveAway ? totalItemCount : totalItemCountExcludingGiveaway;
    },

    getCurrency(): string {
      return cartResponse?.totalPrice?.currencyCode ?? '';
    },

    getId(): string {
      return cartResponse?.id || cartResponse?.anonymousId || '';
    },

    getLineItems(): CartLineItemModel[] {
      return lineItems;
    },

    getSubTotal(returnCentAmount = false): number {
      if (returnCentAmount) {
        return parseInt((subTotal * 100).toFixed(0));
      }
      return Number(subTotal.toFixed(2));
    },

    getCheckoutSubTotal(returnCentAmount = false): number {
      if (returnCentAmount) {
        return parseInt((checkoutSubTotal * 100).toFixed(0));
      }
      return Number(checkoutSubTotal.toFixed(2));
    },

    getTotalDiscount(): number {
      // prevents rounding issues with floating numbers
      return (this.getTotal(true) - this.getSubTotal(true) - this.getShippingCosts(true)) / 100;
    },

    getShippingCosts(returnCentAmount = false): number {
      const total =
        cartResponse.shippingInfo?.discountedPrice?.value.centAmount ??
        cartResponse.shippingInfo?.price?.centAmount ??
        0;
      return returnCentAmount ? total : total / 100;
    },

    getPaymentCosts(returnCentAmount = false): number {
      const paymentCost =
        cartResponse.customLineItems?.find((item) => item.key === 'cash-on-delivery-fee')?.money.centAmount || 0;
      return returnCentAmount ? paymentCost : paymentCost / 100;
    },

    getShippingMethodId(): string {
      return cartResponse.shippingInfo?.shippingMethod?.id ?? '';
    },

    getTotal(returnCentAmount = false): number {
      const total = cartResponse.totalPrice?.centAmount ?? 0;
      return returnCentAmount ? total : total / 100;
    },

    getTotalPayable(returnCentAmount = false): number {
      const total = cartResponse.openPaymentAmount?.centAmount ?? 0;
      return returnCentAmount ? total : total / 100;
    },

    getOpenPaymentAmount() {
      return cartResponse.openPaymentAmount as CentPrecisionMoney;
    },

    isBackOrder(): boolean {
      return isBackOrder;
    },

    getAppliedGiftCardPayment() {
      if (giftCardPaymentMethod !== null) {
        return giftCardPaymentMethod;
      }

      giftCardPaymentMethod = cartResponse.paymentInfo?.payments.find(
        (payment) =>
          payment.obj?.paymentMethodInfo.method === 'giftcard' &&
          payment.obj?.paymentStatus.interfaceCode === 'Initial',
      );
      return giftCardPaymentMethod;
    },

    getAppliedGiftCardPaymentTotals(): { applied: number; remaining: number } {
      const paymentMethod = this.getAppliedGiftCardPayment();
      if (!paymentMethod) {
        return {
          applied: 0,
          remaining: 0,
        };
      }

      const applied = paymentMethod?.obj?.amountPlanned.centAmount ?? 0;
      const remaining = (paymentMethod?.obj?.custom?.fields?.giftcardBalance ?? 0) - applied;

      return {
        applied: applied / 100,
        remaining: remaining / 100,
      };
    },

    isPaidInFullWithGiftCard(): boolean {
      return this.getAppliedGiftCardPaymentTotals().applied > 0 && this.getTotalPayable() === 0;
    },

    isGiftCardOnlyOrder(): boolean {
      return this.hasGiftCard() && this.getCount(false) === giftCardProductCount;
    },

    isPrintableGiftCardOnlyOrder(): boolean {
      return this.getCount(false) === 1 && this.hasPrintGiftCard();
    },

    getGiftCard(): CartLineItemModel | undefined {
      if (!this.hasGiftCard()) {
        return;
      }
      return this.getLineItems().find((item) => item.isGiftCard());
    },

    getGiftCardMessage(): GiftCard | undefined {
      return this.getGiftCard()?.getGiftMessage();
    },

    hasPrintGiftCard(): boolean {
      return this.hasGiftCard() && this.getGiftCardMessage()?.deliveryMethod === 'print';
    },

    hasGiftCard(): boolean {
      return giftCardProductCount > 0;
    },

    hasMixOfPrintGiftCardAndProducts(): boolean {
      return this.hasPrintGiftCard() && !this.isGiftCardOnlyOrder();
    },

    isSoldOut(): boolean {
      return isSoldOut;
    },

    hasSomeSoldOut(): boolean {
      return soldOutlineItems.length > 0;
    },

    getLineItemBySku(sku: string): CartLineItemModel | undefined {
      return this.getLineItems().find((lineItem: CartLineItemModel) => {
        return lineItem.getKey() === sku;
      });
    },

    getPaymentMethod(): string {
      return cartResponse?.custom?.fields?.selectedPaymentMethod ?? '';
    },

    getShippingAddress() {
      const address = cartResponse?.shippingAddress;
      return isValidAddress(address) ? getMappedAddress(address) : undefined;
    },

    getBillingAddress() {
      const address = cartResponse?.billingAddress;
      return isValidAddress(address) ? getMappedAddress(address) : undefined;
    },

    isReadyForPayment(country: string) {
      const shippingAddress = this.getShippingAddress();
      return (
        shippingAddress &&
        shippingAddress.country.toLocaleLowerCase() === country &&
        this.getBillingAddress() &&
        (shippingAddress!.addressType !== 'realAddress' || this.getShippingMethodId())
      );
    },

    // We want to track only items price, without shipping and giftcard
    getHash() {
      return getHashFromString(`${this.getTotal(true) - this.getShippingCosts(true) - this.getPaymentCosts(true)}`);
    },

    isSubscribedToNewsletter() {
      return cartResponse?.custom?.fields?.subscribeNewsletter === true;
    },

    getGuestEmail() {
      const billingAddress = this.getBillingAddress();
      return billingAddress && 'email' in billingAddress ? (billingAddress.email as string) : '';
    },

    getCartDiscountCode() {
      if (!cartResponse?.voucher) {
        return null;
      }
      const { discountCode, cartDiscount, customFieldNamesOfViolatedCriteria } = cartResponse.voucher;
      if (!cartDiscount) {
        return null;
      }
      let absoluteDiscountValue = 0;
      if (cartDiscount.id) {
        const foundIncludedDiscount = cartResponse?.discountOnTotalPrice?.includedDiscounts.find(
          (includedDiscount) => includedDiscount.discount.id === cartDiscount.id,
        );
        if (foundIncludedDiscount) {
          absoluteDiscountValue = foundIncludedDiscount.discountedAmount.centAmount / 100;
        }
      }
      const customFields = cartDiscount.custom?.fields;
      return {
        id: discountCode.id,
        isValid: discountCode.state === 'MatchesCart',
        isDateValid: discountCode.state !== 'NotValid',
        cartDiscountId: cartDiscount.id,
        validFrom: cartDiscount.validFrom,
        validUntil: cartDiscount.validUntil,
        predicate: cartDiscount.cartPredicate,
        code: customFields?.voucherCode || '',
        voucherCriteriaMaximumOrderValue: (customFields?.voucherCriteriaMaximumOrderValue?.centAmount || 0) / 100,
        voucherCriteriaMinimumOrderValue: (customFields?.voucherCriteriaMinimumOrderValue?.centAmount || 0) / 100,
        voucherValueRelative: customFields?.voucherValueRelative,
        voucherValueAbsolute: (customFields?.voucherValueAbsolute?.centAmount || 0) / 100,
        voucherCriteriaCategory: customFields?.voucherCriteriaCategory,
        voucherCriteriaIsCombinableWithSale: customFields?.voucherCriteriaIsCombinableWithSale,
        absoluteDiscountValue,
        customFieldNamesOfViolatedCriteria,
      } satisfies CartDiscountCode;
    },

    getDebugData() {
      return response;
    },
  };
}
