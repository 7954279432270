import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { CmsStoryModel } from '@/types/cms';
import { cmsStoryModel } from '@/models';

type SuperBannerStorage = {
  isOpen: Ref<boolean>;
  story: Ref<CmsStoryModel | null>;
};

export default function useMopSuperBannerClient() {
  const overlay = useMopOverlay();
  const storage = initStorage<SuperBannerStorage>('useSuperBanner');
  const isOpenRef = storage.get('isOpen') ?? storage.saveAndGet('isOpen', ref(false));
  const cmsSuperBannerStoryModelRef = storage.get('story') ?? storage.saveAndGet('story', ref(null));

  async function openSuperBannerOverlay(callbackFn?: Function) {
    isOpenRef.value = true;
    await overlay.open({
      type: 'top',
      componentName: 'MopSuperBannerOverlay',
      urlHash: OVERLAYS.SUPER_BANNER.URL_HASH,
      overrideParams: {
        lockScroll: false,
        showClose: false,
      },
      onClose: () => {
        isOpenRef.value = false;
        callbackFn && callbackFn();
      },
    });
  }

  async function initSuperBannerStory() {
    const { getCmsStory, cmsStoryModelRef } = useMopCms('superbanner');
    if (useNuxtApp().$storyblokLivePreview.isEnabled) {
      const path = useRoute().path;
      if (path.includes('super-banner')) {
        const slug = path.match(/([^/]+$)/)?.[0];
        if (slug) {
          await getCmsStory(`shop/super-banner/${slug}`);

          if (cmsStoryModelRef.value) {
            const data = cmsStoryModelRef.value?.getDebugData();
            if (data) {
              cmsSuperBannerStoryModelRef.value = cmsStoryModel({
                // @ts-ignore match with api response tweak in apiCms.ts
                data: {
                  content: data?.content,
                  id: data?.id,
                  uuid: data?.uuid,
                },
              });

              return;
            }
          }
        }
      }
    }
    cmsSuperBannerStoryModelRef.value =
      useMopCmsGlobalComponents().cmsGlobalStoryListModelRef.value.getStoryModelByName(
        constants.STORYBLOK.GLOBAL_STORY_NAMES.SUPER_BANNER,
      ) ?? null;
  }

  async function closeSuperBannerOverlay() {
    await overlay.closeAll();
  }

  return securedWrap({
    initSuperBannerStory,
    openSuperBannerOverlay,
    closeSuperBannerOverlay,
    cmsSuperBannerStoryModelRef,
    isOpenRef,
  });
}
