import revive_payload_client_YTGlmQWWT5 from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/apps/b2c/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cookie_fY1uPxNcjB from "/home/vsts/work/1/s/apps/b2c/plugins/cookie.ts";
import debug_ZiGB8yHUre from "/home/vsts/work/1/s/apps/b2c/plugins/debug.ts";
import appInsights_client_PTg6gTfz7l from "/home/vsts/work/1/s/apps/b2c/plugins/appInsights.client.ts";
import datadog_client_cfAAU11B0P from "/home/vsts/work/1/s/apps/b2c/plugins/datadog.client.ts";
import errorHandler_x1Y4n5NP4w from "/home/vsts/work/1/s/apps/b2c/plugins/errorHandler.ts";
import commercetools_client_cckGgZcpLb from "/home/vsts/work/1/s/apps/b2c/plugins/commercetools.client.ts";
import composableStorage_yMAjS8MWOd from "/home/vsts/work/1/s/apps/b2c/plugins/composableStorage.ts";
import urls_hQgrCsbIDt from "/home/vsts/work/1/s/apps/b2c/plugins/urls.ts";
import resizeHandler_WBjVe1R1OX from "/home/vsts/work/1/s/apps/b2c/plugins/resizeHandler.ts";
import scrollHandler_vsgC7U9b62 from "/home/vsts/work/1/s/apps/b2c/plugins/scrollHandler.ts";
import breakpoint_JVnKhWJKDQ from "/home/vsts/work/1/s/apps/b2c/plugins/breakpoint.ts";
import storyblokLivePreview_whcxKqA7YO from "/home/vsts/work/1/s/apps/b2c/plugins/storyblokLivePreview.ts";
import mopI18n_aIiIC8irec from "/home/vsts/work/1/s/apps/b2c/plugins/mopI18n.ts";
import appInit_RZ6eE2UnES from "/home/vsts/work/1/s/apps/b2c/plugins/appInit.ts";
import abTest_Ti5YtZ4GiB from "/home/vsts/work/1/s/apps/b2c/plugins/abTest.ts";
import gtm2_xEHBgxCCMW from "/home/vsts/work/1/s/apps/b2c/plugins/gtm2.ts";
import directives_8CcCirWtnE from "/home/vsts/work/1/s/apps/b2c/plugins/directives.ts";
import beforeRouteChange_client_rvSLt9pl1t from "/home/vsts/work/1/s/apps/b2c/plugins/beforeRouteChange.client.ts";
import ui2_gTgeYASmAv from "/home/vsts/work/1/s/apps/b2c/plugins/ui2.ts";
import globalComponents_L23wfncRyp from "/home/vsts/work/1/s/apps/b2c/plugins/globalComponents.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  cookie_fY1uPxNcjB,
  debug_ZiGB8yHUre,
  appInsights_client_PTg6gTfz7l,
  datadog_client_cfAAU11B0P,
  errorHandler_x1Y4n5NP4w,
  commercetools_client_cckGgZcpLb,
  composableStorage_yMAjS8MWOd,
  urls_hQgrCsbIDt,
  resizeHandler_WBjVe1R1OX,
  scrollHandler_vsgC7U9b62,
  breakpoint_JVnKhWJKDQ,
  storyblokLivePreview_whcxKqA7YO,
  mopI18n_aIiIC8irec,
  appInit_RZ6eE2UnES,
  abTest_Ti5YtZ4GiB,
  gtm2_xEHBgxCCMW,
  directives_8CcCirWtnE,
  beforeRouteChange_client_rvSLt9pl1t,
  ui2_gTgeYASmAv,
  globalComponents_L23wfncRyp
]