import type { LocaleObject, Country } from '@/types/locale';

export const cmsLivePreviewLanguageMappings: { [key: string]: string } = {
  de: 'de-de',
  fr: 'fr-fr',
  nl: 'nl-nl',
  'ge-ge': 'en-dk', // Global-e: Denmark is default
};

const vatRateGlobalE = 0.21;

const globalECountry = 'lv';
export const globalEShopLocale = 'en-lv';

export const localeList: LocaleObject[] = [
  {
    code: 'de-de',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'DEU',
    isGlobalE: false,
    commercetoolsCountry: 'de',
    vatRate: 0.19,
  },
  {
    code: 'fr-fr',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'FRA',
    isGlobalE: false,
    commercetoolsCountry: 'fr',
    vatRate: 0.2,
  },
  {
    code: 'de-at',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'AUT',
    isGlobalE: false,
    commercetoolsCountry: 'at',
    vatRate: 0.2,
  },
  {
    code: 'nl-nl',
    cmsFallbackLanguage: 'nl',
    lang: 'nl',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'NLD',
    isGlobalE: false,
    commercetoolsCountry: 'nl',
    vatRate: 0.21,
  },
  {
    code: 'en-se',
    lang: 'en',
    geCurrency: 'SEK',
    currency: 'SEK',
    alpha3: 'SWE',
    isGlobalE: false,
    commercetoolsCountry: 'se',
    vatRate: 0.25,
  },
  {
    code: 'nl-be',
    cmsFallbackLanguage: 'nl',
    lang: 'nl',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'BEL',
    isGlobalE: false,
    commercetoolsCountry: 'be',
    vatRate: 0.21,
  },
  {
    code: 'fr-be',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'BEL',
    isGlobalE: false,
    commercetoolsCountry: 'be',
    vatRate: 0.21,
  },
  {
    code: 'de-ch',
    cmsFallbackLanguage: 'de',
    lang: 'de',
    geCurrency: 'CHF',
    currency: 'CHF',
    alpha3: 'CHE',
    isGlobalE: false,
    commercetoolsCountry: 'ch',
    vatRate: 0.081,
  },
  {
    code: 'fr-ch',
    cmsFallbackLanguage: 'fr',
    lang: 'fr',
    geCurrency: 'CHF',
    currency: 'CHF',
    alpha3: 'CHE',
    isGlobalE: false,
    commercetoolsCountry: 'ch',
    vatRate: 0.081,
  },
  {
    code: 'en-gb',
    lang: 'en',
    geCurrency: 'GBP',
    currency: 'GBP',
    alpha3: 'GBR',
    isGlobalE: false,
    commercetoolsCountry: 'gb',
    vatRate: 0.2,
  },
  {
    code: 'en-fi',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'FIN',
    isGlobalE: false,
    commercetoolsCountry: 'fi',
    vatRate: 0.255,
  },
  {
    code: 'es-es',
    lang: 'es',
    cmsFallbackLanguage: 'es',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'ESP',
    isGlobalE: false,
    commercetoolsCountry: 'es',
    vatRate: 0.21,
  },
  {
    code: 'it-it',
    lang: 'it',
    cmsFallbackLanguage: 'it',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'ITA',
    isGlobalE: false,
    commercetoolsCountry: 'it',
    vatRate: 0.22,
  },
  {
    code: 'en-lu',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LUX',
    isGlobalE: false,
    commercetoolsCountry: 'lu',
    vatRate: 0.17,
  },
  {
    code: 'en-lv',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LVA',
    isGlobalE: false,
    commercetoolsCountry: 'lv',
    vatRate: 0.21,
  },
  {
    code: 'en-lt',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'LTU',
    isGlobalE: false,
    commercetoolsCountry: 'lt',
    vatRate: 0.21,
  },
  {
    code: 'en-sk',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'SVK',
    isGlobalE: false,
    commercetoolsCountry: 'sk',
    vatRate: 0.2,
  },
  {
    code: 'en-si',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'SVN',
    isGlobalE: false,
    commercetoolsCountry: 'si',
    vatRate: 0.22,
  },
  {
    code: 'cs-cz',
    lang: 'cs',
    geCurrency: 'CZK',
    currency: 'CZK',
    alpha3: 'CZE',
    isGlobalE: false,
    commercetoolsCountry: 'cz',
    vatRate: 0.21,
  },
  {
    code: 'pl-pl',
    lang: 'pl',
    geCurrency: 'PLN',
    currency: 'PLN',
    alpha3: 'POL',
    isGlobalE: false,
    commercetoolsCountry: 'pl',
    vatRate: 0.23,
  },
  {
    code: 'en-ro',
    lang: 'en',
    geCurrency: 'RON',
    currency: 'RON',
    alpha3: 'ROU',
    isGlobalE: false,
    commercetoolsCountry: 'ro',
    vatRate: 0.19,
  },
  {
    code: 'en-hu',
    lang: 'en',
    geCurrency: 'HUF',
    currency: 'HUF',
    alpha3: 'HUN',
    isGlobalE: false,
    commercetoolsCountry: 'hu',
    vatRate: 0.27,
  },
  {
    code: 'en-ee',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'EST',
    isGlobalE: false,
    commercetoolsCountry: 'ee',
    vatRate: 0.22,
  },
  {
    code: 'en-ie',
    lang: 'en',
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'IRL',
    isGlobalE: false,
    commercetoolsCountry: 'ie',
    vatRate: 0.23,
  },
  {
    code: 'en-dk',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'DKK',
    currency: 'EUR',
    alpha3: 'DNK',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-gr',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'EUR',
    currency: 'EUR',
    alpha3: 'GRC',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-pt',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'PTE',
    currency: 'EUR',
    alpha3: 'PRT',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-no',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'NOK',
    currency: 'EUR',
    alpha3: 'NOR',
    isDisabled: true,
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-is',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'ISK',
    currency: 'EUR',
    alpha3: 'ISL',
    isDisabled: true,
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-hr',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'HRK',
    currency: 'EUR',
    alpha3: 'HRV',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-us',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'USD',
    currency: 'EUR',
    alpha3: 'USA',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-ca',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'CAD',
    currency: 'EUR',
    alpha3: 'CAN',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-rs',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'RSD',
    currency: 'EUR',
    alpha3: 'SRB',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-ae',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'AED',
    currency: 'EUR',
    alpha3: 'ARE',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-il',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'ILS',
    currency: 'EUR',
    alpha3: 'ISR',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-qa',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'QAR',
    currency: 'EUR',
    alpha3: 'QAT',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-nz',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'NZD',
    currency: 'EUR',
    alpha3: 'NZL',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-in',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'INR',
    currency: 'EUR',
    alpha3: 'IND',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-sg',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'SGD',
    currency: 'EUR',
    alpha3: 'SGP',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-jp',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'JPY',
    currency: 'EUR',
    alpha3: 'JPN',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
  {
    code: 'en-hk',
    cmsFallbackLanguage: 'ge_ge',
    lang: 'en',
    commercetoolsCountry: globalECountry,
    geCurrency: 'HKD',
    currency: 'EUR',
    alpha3: 'HKG',
    isGlobalE: true,
    vatRate: vatRateGlobalE,
  },
];

export function getLocalesFromCountry(localeList: LocaleObject[], country: Country): string[] {
  const list: string[] = [].slice.call(localeList);
  country = country.toLowerCase();
  return list.reduce((locales: string[], locale: any) => {
    if (locale !== undefined && locale.code.split('-')[1] === country) {
      locales.push(locale.code);
    }
    return locales;
  }, []);
}

export function extractCountriesFromLocaleList(filteredLocaleList: LocaleObject[]): string[] {
  const countryList: string[] = [];
  filteredLocaleList.forEach((locale: LocaleObject) => {
    const country: string = getCountryFromLocale(locale);
    if (countryList.includes(country)) {
      return;
    }
    countryList.push(country);
  });
  return countryList;
}

export function getAvailableLanguages(): string[] {
  const languages: string[] = [];
  localeList.forEach((locale: LocaleObject) => {
    if (languages.includes(locale.lang)) {
      return;
    }
    languages.push(locale.lang);
  });
  return languages;
}

export function getCountryFromLocale(locale: LocaleObject | undefined): string {
  return locale ? locale.code.split('-')[1] : '';
}

export function getCountryFromAlpha3(alpha3: string) {
  return getCountryFromLocale(localeList.find((locale) => locale.alpha3 === alpha3));
}

export function getAlpha3FromCountry(country: string) {
  return localeList.find((locale) => getCountryFromLocale(locale) === country)?.alpha3 ?? '';
}

export function getVatRateFromCountry(country: string) {
  return localeList.find((locale) => getCountryFromLocale(locale) === country)?.vatRate ?? 0;
}
