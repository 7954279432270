import type { CartLineItemCustomFields, CartDiscountCode } from '@/types/cart';
import type { GiftCard, ProductPrice, GiftCardDeliveryMethod } from '@/types/product';
import { productModel, variantModel } from '@/models';
import { getMopPriceFromCommercetools } from '@/models/utils/priceUtils';
import type { BffCart } from '@/types/bff';

export function cartLineItemModel(response: BffCart['lineItems'][0]) {
  const customFields: CartLineItemCustomFields | undefined = response.custom?.fields;

  return {
    getProduct() {
      return productModel({
        statusCode: 200,
        body: response.custom?.fields.productProjection,
      });
    },

    getVariant() {
      return variantModel(response.variant);
    },

    getQuantity(): number {
      return response.quantity ?? 0;
    },

    getKey(): string {
      return response.variant.sku ?? response.variant.key ?? '';
    },

    getUnitPrice(): ProductPrice {
      // @ts-ignore missing type discountedPrice in commercetools
      return getMopPriceFromCommercetools(response.price, response.discountedPrice);
    },

    getTotalPrice(): ProductPrice {
      // @ts-ignore missing type discountedPrice in commercetools
      return getMopPriceFromCommercetools(response.price, response.discountedPrice, response.quantity);
    },

    getAvailableQuantity(): number {
      return this.getVariant().getAvailability()?.quantity ?? 0;
    },

    getIsSizeAdviceAvailable() {
      return customFields?.isSizeAdviceAvailable === true;
    },

    isGiveaway(): boolean {
      return customFields?.isGiveaway === true;
    },

    isGiftCard(): boolean {
      return customFields?.isGiftCard === true;
    },

    getLastAddedTimestamp(): number {
      return new Date(response?.addedAt || Date.now()).getTime();
    },

    getLastModifiedTimestamp(): number {
      return new Date(response?.lastModifiedAt || Date.now()).getTime();
    },

    getGiftMessage(): GiftCard {
      return {
        from: decodeURIComponent(customFields?.giftCardFrom ?? ''),
        to: decodeURIComponent(customFields?.giftCardTo ?? ''),
        message: decodeURIComponent(customFields?.giftCardMessage ?? ''),
        deliveryMethod: decodeURIComponent(customFields?.giftCardDeliveryMethod ?? 'postal') as GiftCardDeliveryMethod,
      };
    },

    isCartDiscountCodeApplied(appliedCartDiscountCode: CartDiscountCode | null) {
      return (
        appliedCartDiscountCode?.id &&
        this.getUnitPrice().cartDiscountIds?.includes(appliedCartDiscountCode.cartDiscountId)
      );
    },

    getSupplyChannelId() {
      return response.supplyChannel?.id;
    },

    getDebugData(): any {
      return response;
    },
  };
}
