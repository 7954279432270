import type { BffCartAddress } from '@/types/bff';

export function getHash(address: BffCartAddress) {
  return `${'salutation' in address && address.salutation ? address.salutation : ''}-${
    'academicTitle' in address && address.academicTitle ? address.academicTitle : 'None'
  }-${'firstName' in address && address.firstName ? address.firstName : ''}-${
    'lastName' in address && address.lastName ? address.lastName : ''
  }-${address.streetName}-${address.streetNumber}-${address.additionalAddressInfo || ''}-${address.postalCode}-${
    address.city
  }-${address.country.toLocaleLowerCase()}`
    .trim()
    .replace(/ /g, '');
}

export function isValidAddress(address?: BffCartAddress) {
  return (
    address?.addressType &&
    (
      [
        constants.CHECKOUT.ADDRESS_TYPE.REAL_ADDRESS,
        constants.CHECKOUT.ADDRESS_TYPE.CLICK_AND_COLLECT,
        constants.CHECKOUT.ADDRESS_TYPE.COLLECTION_POINT,
      ] as string[]
    ).includes(address.addressType)
  );
}

export function getMappedAddress(address?: BffCartAddress): BffCartAddress | undefined {
  if (!address) {
    return;
  }
  const mappedAddress = {
    ...address,
    country: address.country.toLocaleLowerCase(),
  };
  if ('academicTitle' in mappedAddress) {
    mappedAddress.academicTitle = mappedAddress.academicTitle !== 'None' ? mappedAddress.academicTitle : undefined;
  }
  return mappedAddress;
}

export function isSameAddress(address1?: BffCartAddress, address2?: BffCartAddress) {
  if (!address1 || !address2) {
    return false;
  }
  const hash1 = getHash(address1);
  const hash2 = getHash(address2);
  return hash1 === hash2;
}
