import type { CmsLink, CmsPromoTileLayout, CmsHeadlineWithUppercaseOption, CmsMediaImage } from '@mop/cms/types';
import { getElementUiObject, getHeadlineWithUppercaseOption, getLink, getMedia, getCta } from '@mop/cms/utils/utils';
import type {
  CmsVisibility,
  CmsStoryModel,
  CmsListItem,
  CmsList,
  CmsUiSuperBanner,
  CmsPromoTile,
  CmsUiProductGridTextTile,
} from '@/types/cms';

export function getCmsListItem(object: any): CmsListItem {
  return getElementUiObject(object, {
    label: object.label,
    link: getLink(object.link),
  } as CmsListItem);
}

export function getCmsList(object: any): CmsList {
  return getElementUiObject(object, {
    title: object.headline,
    link: getLink(object.link),
    items: object.items.map((listItem: any) => getCmsListItem(listItem)),
  } as CmsList);
}

export function getCmsPromoTile(data: any): CmsPromoTile | undefined {
  if (!data) {
    return;
  }

  const productId: string = data.productId || '';
  const image = data.productImageIndex?.split('.') || [];
  const productImageDivision = parseInt(image[0] || 1);
  const productImageIndex = parseInt(image[1] || PRODUCT_IMAGE_INDEX.STANDARD);
  const hideText: boolean = data.hideText;
  const layout: CmsPromoTileLayout = data.layout;
  const position: number = parseInt(data.position || 0);
  const media = getMedia(data.media?.[0]);
  const headline: CmsHeadlineWithUppercaseOption = getHeadlineWithUppercaseOption(hideText ? '' : data);
  const subline = hideText ? '' : data.subline;
  const isSublineUppercase: boolean = data.isSublineUppercase;
  const cta = getCta(data.cta?.[0]);
  const visibility: CmsVisibility = data.visibility;
  const customId = data.customId;
  const link: CmsLink = getLink(data.link);
  const imageType = layout === 'single' ? 'product-tile' : 'product-tile-grid-hero';
  if (media) {
    media.imageType = imageType;
  }
  const isProductTile = Boolean(productId && !headline?.text && !subline && !cta);
  const hasMobileMedia: boolean = media?.type === 'vimeo' || (media as CmsMediaImage)?.imageMobile !== undefined;

  return getElementUiObject(data, {
    productId,
    productImageIndex,
    productImageDivision,
    hideText,
    layout,
    position,
    media,
    headline,
    subline,
    isSublineUppercase,
    cta,
    visibility,
    customId,
    link,
    isProductTile,
    hasMobileMedia,
    imageType,
  } as CmsPromoTile);
}

export function getCmsUiPromoTextTile(object: any): CmsUiProductGridTextTile | undefined {
  const data: any = object;
  if (!data) {
    return;
  }

  return getElementUiObject(data, {
    alignment: data.alignment || 'bottom',
    headline: getHeadlineWithUppercaseOption(data),
    headlineFontStyle: data.headlineFontStyle,
    subline: data.subline,
    isSublineUppercase: data.isSublineUppercase,
    cta: getCta(data.cta?.[0]),
    link: getLink(data.link),
    isProductTile: false,
    isTextTile: true,
    layout: data.layout,
  } as CmsUiProductGridTextTile);
}

export function getCmsSuperBanner(storyModel: CmsStoryModel | null): CmsUiSuperBanner | undefined {
  const data: any = storyModel?.getContent();
  if (!data) {
    return;
  }

  const bannerData: any = data.banner?.[0];
  if (!bannerData) {
    return;
  }

  const overlayRowsData: any = data.overlay;

  const {
    textLeft,
    textCenter,
    textRight,
    textLeftMobile,
    textCenterMobile,
    textRightMobile,
    showBottomStripe,
    textBottomLeft,
    textBottomCenter,
    textBottomRight,
    textBottomMobile,
    isUppercase,
    autoOpen,
  } = bannerData;

  const link: CmsLink = getLink(bannerData.link);
  const hasOverlayContent: boolean = overlayRowsData.some((row: any) => row.columns.length);
  const hasToggle: boolean = link.componentName === 'div' && hasOverlayContent;
  const textColor: string = bannerData.textColor?.color;
  const backgroundColor: string = bannerData.backgroundColor?.color;
  const borderColor: string = bannerData.borderColor?.color;

  return getElementUiObject(bannerData, {
    textLeft,
    textCenter,
    textRight,
    textLeftMobile,
    textCenterMobile,
    textRightMobile,
    showBottomStripe,
    textBottomLeft,
    textBottomCenter,
    textBottomRight,
    textBottomMobile,
    isUppercase,
    link,
    textColor,
    backgroundColor,
    borderColor,
    autoOpen,
    hasOverlayContent,
    hasToggle,
  }) as CmsUiSuperBanner;
}
