<script setup lang="ts">
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsPromoTileLayout, CmsMediaImage } from '@mop/cms/types';
import type { CmsPromoTile, CmsUiProductGridTextTile, CmsUiProductRow, CmsUiProductRowTile } from '@/types/cms';

defineOptions({
  name: 'MopCmsProductRow',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;

const PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE =
  'productsingle_productsingle_productsingle_productsingle';
const PRODUCTSINGLE_PRODUCTSINGLE_TEXTDOUBLE = 'productsingle_productsingle_textdouble';
const PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE_TEXTSINGLE = 'productsingle_productsingle_productsingle_textsingle';
const PRODUCTSINGLE_TEXTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE = 'productsingle_textsingle_productsingle_productsingle';
const PRODUCTDOUBLE_PRODUCTDOUBLE = 'productdouble_productdouble';
const PRODUCTHERO_PRODUCTHERO = 'producthero_producthero';

let maxTilesCount = 4;
let type: string = data.type;
if (!type) {
  type = data.items
    .map((item: any) => {
      const tileType: string = item.component === 'ProductGridTextItem' ? 'text' : 'product';
      const layout: CmsPromoTileLayout = item.layout || 'single';
      return `${tileType}${layout}`;
    })
    .join('_');
}
const legacyTypeMapping: Record<string, string> = {
  productsSingle: PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE,
  doubleTextRight: PRODUCTSINGLE_PRODUCTSINGLE_TEXTDOUBLE,
  singleTextRight: PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE_TEXTSINGLE,
  singleTextLeft: PRODUCTSINGLE_TEXTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE,
  productsDouble: PRODUCTDOUBLE_PRODUCTDOUBLE,
};
type = legacyTypeMapping[type] || type;

const tiles: CmsUiProductRowTile[] = data.items?.map((item: any, i: number) => {
  let tile: CmsUiProductRowTile;
  if (item.component === 'ProductGridItem') {
    tile = getCmsPromoTile(item) as CmsPromoTile;

    // START: Overwrite tile with the correct imageType
    let imageType = 'product-tile';
    if (tile.layout === 'double') {
      imageType = 'product-tile-double';
    } else if (tile.layout === 'hero') {
      imageType = 'product-tile-hero';
    }
    if (
      i === 0 &&
      [
        PRODUCTSINGLE_TEXTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE,
        PRODUCTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE_TEXTSINGLE,
      ].includes(type)
    ) {
      imageType = 'product-tile-mobile-hero';
    }
    if ([PRODUCTHERO_PRODUCTHERO].includes(type)) {
      imageType = 'product-tile-desktop-hero';
    }
    if ([PRODUCTDOUBLE_PRODUCTDOUBLE].includes(type)) {
      imageType = 'product-tile-desktop-double';
    }
    (tile as CmsPromoTile).imageType = imageType;
    if ((tile as CmsPromoTile).media) {
      ((tile as CmsPromoTile).media as CmsMediaImage).imageType = imageType;
    }
    // END: Overwrite tile with the correct imageType
  } else {
    tile = getCmsUiPromoTextTile(item) as CmsUiProductGridTextTile;
  }
  if (tile.layout === 'double' || tile.layout === 'hero') {
    maxTilesCount--;
  }
  tile.component = item.component;
  return tile;
});

// START: Legacy productRow support (textTile has been removed and is part of the tiles list)
const textTile: CmsUiProductGridTextTile | undefined = getCmsUiPromoTextTile(data.textItem?.[0]);
if (textTile) {
  textTile.layout = type === PRODUCTSINGLE_PRODUCTSINGLE_TEXTDOUBLE ? 'double' : 'single';
  if (textTile.layout === 'double') {
    maxTilesCount--;
  }
  if (type === PRODUCTSINGLE_TEXTSINGLE_PRODUCTSINGLE_PRODUCTSINGLE) {
    tiles.splice(1, 0, textTile);
  } else {
    tiles.push(textTile);
  }
}
// END: Legacy productRow support (textTile has been removed and is part of the tiles list)

// START: Check if row variation (type) is valid
const invalidTypes: string[] = [
  'textsingle_producthero',
  'textdouble_producthero',
  'productdouble_textsingle',
  'producthero_textdouble',
];
let isInvalid: boolean = invalidTypes.includes(type);
if (type.includes('hero') && tiles.length > 2) {
  isInvalid = true;
} else if (tiles.length > maxTilesCount) {
  isInvalid = true;
}
// END: Check if row variation (type) is valid

const element: CmsUiProductRow = getElementUiObject(data, {
  type,
  tiles,
  position: parseInt(data.position || 0),
  isInvalid,
});
</script>

<template>
  <div v-storyblok-editable="element" :class="['cms-product-row', `cms-product-row--${element.type}`]">
    <ClientOnly>
      <template v-if="$storyblokLivePreview.isEnabledInIframe && isInvalid">
        <div class="cms-product-row__failed">
          {{ 'Live Preview only - Error' }}<br />
          {{ `Product row: Combination of text / products not allowed: ${type}` }}
        </div>
      </template>
    </ClientOnly>
    <UiGrid v-if="!isInvalid">
      <UiGridItem
        v-for="(tile, index) in tiles"
        :key="`${index}-${tile._uid}`"
        :layout="tile.layout"
        :type="tile.isTextTile ? 'text' : 'product'"
      >
        <MopTile
          :data="tile as any"
          :image-type="tile.imageType"
          :disable-image-hover-change="tile.layout !== 'hero'"
          :track-position="(index + 1).toString()"
          :enable-popularity-flag="enablePopularityFlag"
        />
      </UiGridItem>
    </UiGrid>
  </div>
</template>

<style lang="scss" scoped>
.cms-product-row {
  padding-right: $global-padding;
  padding-left: $global-padding;
  margin-bottom: $space20;

  @include apply-upto(small) {
    padding: 0;
  }
}

.cms-product-row__failed {
  @include text-style(small);

  background: $signal-alert;
  color: $white;
  padding: 0 $global-padding;
}

.cms-product-row--producthero_texthero,
.cms-product-row--texthero_producthero,
.cms-product-row--producthero_textsingle {
  @include apply-upto(small) {
    padding: $space20 0;

    .tile--text {
      --small-grid-row-start: 1;
      --small-grid-column-start: 1;

      grid-column-end: span 2;
    }
  }
}

.cms-product-row--producthero,
.cms-product-row--producthero_textsingle {
  .tile--product {
    --large-grid-column-start: 2;

    @include apply-from(medium) {
      --small-grid-column-start: 2;
    }
  }

  .tile--text {
    --large-grid-column-start: 4;

    @include apply-from(medium) {
      --small-grid-column-start: 4;
    }
  }

  :deep(tile-text) {
    bottom: auto;
    top: 0;
  }
}

.cms-product-row--productsingle_textsingle_productsingle_productsingle,
.cms-product-row--productsingle_productsingle_productsingle_textsingle {
  @include apply-upto(small) {
    .tile--text {
      --small-grid-row-start: 1;
      --small-grid-column-start: 1;

      grid-column-end: span 2;
    }

    .tile:first-child {
      grid-column-end: span 2;
    }
  }
}

.cms-product-row--productdouble_productdouble {
  @include apply-upto(small) {
    .tile--double {
      grid-column-end: span 1;
    }
  }
}

.cms-product-row--productsingle_productdouble_productsingle {
  @include apply-upto(small) {
    .tile--double {
      --small-grid-row-start: 1;
      --small-grid-column-start: 1;
    }
  }
}

.cms-product-row--producthero_producthero {
  @include apply-upto(small) {
    .tile--hero {
      grid-column-end: span 1;
    }
  }
}
</style>
