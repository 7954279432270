import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { CollectionPointSearchParams } from '@/types/collectionPoint';
import type { ApiResponse, ApiBffConfig } from '@/types/apiInit';
import type { BffApiCollectionPointsGet } from '@/types/bff';
const API_NAME = 'AWS - BFF CHECKOUT';

export function getCollectionPoints(
  apiConfig: ApiBffConfig,
  params: CollectionPointSearchParams,
): Promise<ApiResponse<BffApiCollectionPointsGet>> {
  const url = getBffUrlWithParams('/collection-points', apiConfig);

  return bffErrorHandler<any>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        params,
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCollectionPoints',
      url,
    }),
  }));
}
