import type { App, Component } from 'vue';
import type { UiPriceParams } from '@mop/ui2/types';

const svgIcons: Record<string, () => Promise<Component>> = import.meta.glob('../../icons/*.svg', {
  query: '?component',
  import: 'default',
  eager: false,
});

const componentTranslations = {
  ui: {
    fileUpload: {
      click_to_upload: 'Click to upload',
      drag_document_here: 'or drag the document here',
      drag_content: 'Drag document here',
      unsupported_file: 'Unsupported file type was not uploaded',
    },
    datepicker: {
      clear: 'Clear',
      apply: 'Apply',
      placeholder_single: 'Select date',
      placeholder_range: 'Select date range',
    },
    pagination: {
      previous: 'Previous',
      next: 'Next',
    },
    textFieldAria: {
      optional: '(optional)',
    },
    textFieldInput: {
      optional: '(optional)',
    },
    textFieldBirthday: {
      wrong_birthday: 'Incorrect birthday format',
    },
    textFieldPassword: {
      show: 'Show Password',
      hide: 'Hide Password',
      error_validation: 'Enter valid password',
      error_length: 'At least 8 characters',
      error_uppercase: 'At least 1 uppercase letter',
      error_char: 'At least 1 special character',
    },
    collapse: {
      show_more: 'Show more',
      show_less: 'Show less',
    },
  },
  components: {
    producttile: {
      more: {
        multiple_sizes: 'Many sizes available',
      },
    },
    wishlist: {
      tab_name_short: 'Wishlist',
    },
  },
};

type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends object ? DeepPartial<T[P]> : T[P];
};

type Translations = DeepPartial<typeof componentTranslations>;

type InstallOptions = {
  i18n?: {
    t: Function;
    formatPrice(params: UiPriceParams): string;
  };
  translations?: Translations;
  isGlobalEEnabled?: Function;
  isSaleEnabled?: Function;
  isSavingLabelEnabled?: Function;
};

export default {
  install(app: App, options: InstallOptions) {
    const { i18n, translations, isGlobalEEnabled, isSaleEnabled, isSavingLabelEnabled } = options;
    const ui2Config = {
      svgIcons,
      ui2T(key: string, ...args: any) {
        const translate = i18n?.t !== undefined ? i18n.t(key, args) : key;
        if (!translate || translate === key) {
          return getTFallback(key, translations) ?? key;
        }
        return translate;
      },
      // te: locale.te,
      // tm: locale.tm,
      formatPrice(params: UiPriceParams) {
        return i18n?.formatPrice !== undefined ? i18n.formatPrice(params) : getFallbackFormatPrice(params);
      },
      isGlobalEEnabled() {
        return isGlobalEEnabled !== undefined ? isGlobalEEnabled() : getFallbackIsGlobalEEnabled();
      },
      isSaleEnabled() {
        return isSaleEnabled !== undefined ? isSaleEnabled() : isFallbackSaleEnabled();
      },
      isSavingLabelEnabled() {
        return isSavingLabelEnabled !== undefined ? isSavingLabelEnabled() : isFallbackSavingLabelEnabled();
      },
    };
    app.config.globalProperties.$ui2Config = ui2Config;
    app.provide('ui2Config', ui2Config);
  },
};

function logFallbackUsage(methodName: string) {
  // eslint-disable-next-line no-console
  console.log(`Fallback "${methodName}" has been used!`);
}

function getFallbackIsGlobalEEnabled() {
  logFallbackUsage('getFallbackIsGlobalEEnabled');
  return false;
}

function isFallbackSaleEnabled() {
  logFallbackUsage('isFallbackSaleEnabled');
  return true;
}

function isFallbackSavingLabelEnabled() {
  logFallbackUsage('isFallbackSavingLabelEnabled');
  return true;
}

function getTFallback(key: string, translations?: Translations) {
  logFallbackUsage('getTFallback');
  let translation = '';
  if (!translation || translation === key) {
    translation = getLocalTranslation(key, translations);
  }
  if (!translation || translation === key) {
    translation = getLocalTranslation(key, componentTranslations);
  }
  return translation;
}

function getFallbackFormatPrice(params: UiPriceParams) {
  logFallbackUsage('getFallbackFormatPrice');
  const { price, precision = 2 } = params;
  const priceCountry: string = params.country || 'de';
  const currency: string = params.currency || 'EUR';
  const displayPrice: number = typeof price === 'string' ? parseFloat(price) : price;
  const priceLang: string = priceCountry || 'de';
  const formatConfig: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    minimumFractionDigits: precision,
  };
  const localeCode = `${priceLang}-${priceCountry.toUpperCase()}`;
  if (!window.Intl) {
    // fallback to old browsers
    return displayPrice.toLocaleString(localeCode, formatConfig);
  }
  return new Intl.NumberFormat(localeCode, formatConfig).format(displayPrice);
}

function getLocalTranslation(key: string, translations?: any): string {
  return key.split('.').reduce((o, i) => (o ? o[i] : o), translations);
}
