<script setup lang="ts">
import type { CategoryModel } from '@/types/category';

defineOptions({
  name: 'MopHeaderMainNavigation',
});

const emit = defineEmits(['open']);

const { $gtm2, $mopConfig, $mopI18n, $rootCategories } = useNuxtApp();
const router = useRouter();
const rootCategoriesRef = computed(() => $rootCategories.getCategoryModelList());
const navigation = useMopNavigationClient();
const headerOverlay = useMopOverlay();
const { isActiveRootCategory } = useMopRouter();
const canSetActiveCategoryRef = ref(false);
const currentActiveCategoryId = computed(() => {
  if (!canSetActiveCategoryRef.value) {
    return;
  }
  return rootCategoriesRef.value
    .find((category) => {
      const isActive =
        (isOverlayOpen.value && navigation.isSubNavigationOpen(category.getMopId())) ||
        (!isOverlayOpen.value && isActiveRootCategory(category.getMopId()));
      return isActive;
    })
    ?.getId();
});

const isOverlayOpen = computed(() => {
  return (
    headerOverlay.activeOverlayRef?.value?.isOpen &&
    headerOverlay.activeOverlayRef.value.componentName === 'MopHeaderNavigationOverlay'
  );
});

onMounted(() => {
  canSetActiveCategoryRef.value = true;
});

const handleClick = (category: CategoryModel) => {
  $gtm2.reportLegacyEngagement({
    event: 'navigation',
    category: $gtm2.getCurrentPage(),
    label: category.getPath(),
    parameter3: $gtm2.getPageCategoryId(),
    parameter4: category.getName($mopConfig),
    parameter5: category.getDepth().toString(),
  });
  if (isTouchDevice() && !navigation.isSubNavigationOpen(category.getMopId())) {
    navigation.openSubNavigation(category, () => emit('open'));
    return;
  }
  navigation.closeAll();
  headerOverlay.closeAll();
  router.push($mopI18n.localePath(category.getUrl()));
};
</script>

<template>
  <nav class="main-navigation">
    <ul
      :class="[
        'main-navigation__root',
        {
          'main-navigation__root--black': isOverlayOpen,
        },
      ]"
    >
      <li
        v-for="category in rootCategoriesRef"
        :key="category.getMopId()"
        :data-cy="`header-main-navigation-link-${category.getMopId()}`"
        class="main-navigation__root-item"
        @mouseenter="() => !isTouchDevice() && navigation.openSubNavigation(category, () => emit('open'))"
        @mouseleave="() => !isTouchDevice() && navigation.stopOpening()"
      >
        <a
          :href="$mopI18n.localePath(category.getUrl())"
          :class="[
            'main-navigation__link',
            {
              'main-navigation__link--active': category.getId() === currentActiveCategoryId,
            },
          ]"
          :style="[
            {
              '--color': $mopConfig.getCategoryPreference(category.getMopId()).textColor || undefined,
            },
          ]"
          @click.prevent="handleClick(category)"
        >
          {{ category.getName($mopConfig) }}
        </a>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.main-navigation__root {
  display: flex;
  position: relative; /* For positioning and stretching ("min-width") the submenu. */
  margin-left: -$space10;
}

.main-navigation__root-item {
  padding: 0 $space10;
}

.main-navigation__link {
  @include link-not-underlined(23px, left-right);
  @include text-style(strong);

  text-transform: uppercase;
  display: block;
  line-height: 22px;

  &::after {
    border-bottom-width: 2px;
  }
}

.main-navigation__root--black {
  --color: $black;
}
</style>
