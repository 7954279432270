import { securedWrap } from '@mop/shared/utils/securedWrap';
import { sessionStorageGet, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import type {
  CartModelRef,
  CartModel,
  CartLineItemModel,
  CartLineItemCustomFields,
  BffResponseWithCart,
} from '@/types/cart';
import type { GiftCard } from '@/types/product';
import { cartModel } from '@/models';
import type { ApiResponse } from '@/types/apiInit';

type LoadingState = {
  initCart: boolean;
  addVariantToCart: boolean;
  updateCartItem: boolean;
  removeCartItem: boolean;
  recalculateCart: boolean;
  loading: boolean;
};

type CartComposableStorage = {
  cart: CartModelRef;
  loading: Ref<LoadingState>;
  isAddVariantToCartRef: Ref<boolean>;
  maxQuantityAddedToCartRef: Ref<number>;
};

export default function useMopCartClient() {
  const storage = initStorage<CartComposableStorage>('useCart');
  const { $apiBff, $mopI18n, $urls, $datadog, $gtm2 } = useNuxtApp();
  const { logCheckoutError } = useMopCheckoutErrorClient();
  const { addToast } = useMopToastsClient();
  const cartModelRef = storage.get('cart') ?? storage.saveAndGet('cart', ref(cartModel(null)));
  const isAddVariantToCartRef =
    storage.get('isAddVariantToCartRef') ?? storage.saveAndGet('isAddVariantToCartRef', ref(false));
  const maxQuantityAddedToCartRef =
    storage.get('maxQuantityAddedToCartRef') ?? storage.saveAndGet('maxQuantityAddedToCartRef', ref(0));
  const { createAnonymousSessionIfNotSet, isSessionCreated } = useMopCustomer();

  const loadingRef: Ref<LoadingState> =
    storage.get('loading') ??
    storage.saveAndGet(
      'loading',
      ref({
        initCart: false,
        addVariantToCart: false,
        updateCartItem: false,
        removeCartItem: false,
        recalculateCart: false,
        loading: computed(() => isLoading(loadingRef)),
      }),
    );

  async function initCart({ recalculate }: { recalculate?: boolean } = {}) {
    if (!isSessionCreated()) {
      return;
    }

    loadingRef.value.initCart = true;
    const result = await $apiBff.getCart({ recalculate });
    updateCart(result, { showChangeInfo: false });
    handleGtmCartContent();

    addCartDataToDOM();
    loadingRef.value.initCart = false;
    return result.data?.code;
  }

  async function isCartIdentical() {
    if (!isSessionCreated()) {
      return false;
    }

    const result = await $apiBff.getCart({ recalculate: true });
    return updateCart(result, { showChangeInfo: true }).cartChanged === false;
  }

  async function recalculateCart(showLoading = false) {
    if (!isSessionCreated()) {
      return;
    }

    if (showLoading) {
      loadingRef.value.recalculateCart = true;
    }

    const result = await $apiBff.getCart({ recalculate: true });
    updateCart(result, { showChangeInfo: false });
    if (showLoading) {
      loadingRef.value.recalculateCart = false;
    }
    return result.data?.code;
  }

  // Needed for AB test cart overlay: https://marc-o-polo.atlassian.net/wiki/spaces/CRO/pages/2389672162/Cart+Abandoner+Note+Pop-Up
  function addCartDataToDOM() {
    const cartData: any = cartModelRef.value.getLineItems().map((lineItem) => {
      const product = lineItem.getProduct();
      const imageSrc = product.getImageByIndex(PRODUCT_IMAGE_INDEX.BUST);
      const variant = lineItem.getVariant();
      const unitPrice = lineItem.getUnitPrice();
      const availability = variant.getAvailability();
      const size = variant.getSize();
      const productUrl = product.getUrl(size);
      const salePrice = unitPrice.salePrice;
      const basePrice = unitPrice.basePrice;
      const salePercentage = unitPrice.salePercentage;
      const currency = unitPrice.currency;

      return {
        url: $mopI18n.localePath(productUrl),
        name: product.getName(),
        image: imageSrc,
        quantity: lineItem.getQuantity(),
        size,
        salePrice,
        formattedSalePrice: $mopI18n.formatPrice({
          price: salePrice,
          currency,
        }),
        basePrice,
        formattedBasePrice: $mopI18n.formatPrice({
          price: basePrice,
          currency,
        }),
        isSoldOut: !availability.isInStock,
        salePercentage,
      };
    });

    // @ts-ignore
    if (window.__NUXT__) {
      // @ts-ignore
      window.__NUXT__['cart-init-data'] = cartData;
    }
  }

  async function addVariantToCart(
    sku: string,
    supplyChannelId: string,
    quantity = 1,
    customFields?: CartLineItemCustomFields,
  ) {
    loadingRef.value.addVariantToCart = true;

    await createAnonymousSessionIfNotSet();

    const existingItem = cartModelRef.value.getLineItems().find((item) => item.getVariant().getSku() === sku);
    if (
      (existingItem?.getQuantity() || 0) + quantity > constants.LIMITS.CART.MAX_QUANTITY_PER_ITEM ||
      (existingItem && (existingItem.getQuantity() || 0) + quantity > (existingItem.getAvailableQuantity() || 0))
    ) {
      maxQuantityAddedToCartRef.value = constants.LIMITS.CART.MAX_QUANTITY_PER_ITEM;
      loadingRef.value.addVariantToCart = false;
      return false;
    }
    const result = await $apiBff.addCartItem({ quantity, sku, supplyChannelId, customFields });
    const bffStatus = result.data?.code;
    updateCart(result, { showChangeInfo: false });

    const addedItem = cartModelRef.value.getLineItemBySku(sku);
    const successfulResponse = bffStatus === 'OK';
    if (successfulResponse && addedItem) {
      $gtm2.reportLegacyCartContent({
        items: cartModelRef.value.getLineItems(),
      });
    }
    isAddVariantToCartRef.value = successfulResponse && Boolean(addedItem);
    loadingRef.value.addVariantToCart = false;

    return bffStatus;
  }

  async function addGiftCardToCart(sku: string, supplyChannelId: string, giftCardData: GiftCard) {
    const customFields: CartLineItemCustomFields = {
      giftCardFrom: giftCardData.from,
      giftCardTo: giftCardData.to,
      giftCardMessage: giftCardData.message,
      giftCardDeliveryMethod: giftCardData.deliveryMethod,
      isGiftCard: true,
    };

    await addVariantToCart(sku, supplyChannelId, 1, customFields);
  }

  async function updateCartItem(sku: string, quantity: number) {
    loadingRef.value.updateCartItem = true;

    const result = await $apiBff.updateCartItem({ sku, quantity });
    if (result.data?.code === 'OK') {
      updateCart(result, { showChangeInfo: false });
      handleGtmCartContent();
    }

    loadingRef.value.updateCartItem = false;
    return result.data?.code;
  }

  async function removeCartItem(sku: string) {
    if (!sku) {
      return;
    }
    loadingRef.value.removeCartItem = true;
    resetCustomCartData();

    const result = await $apiBff.removeCartItem({ sku });
    updateCart(result, { showChangeInfo: false });
    handleGtmCartContent();

    loadingRef.value.removeCartItem = false;
    return result.data?.code;
  }

  function handleGtmCartContent() {
    $gtm2.reportLegacyCartContent({
      items: cartModelRef.value.getLineItems(),
    });
  }

  function getCart() {
    let giveaway: CartLineItemModel | undefined;
    const cart: CartModel = cartModelRef.value;

    let lastAddedItem: CartLineItemModel | undefined;
    let lastModifiedItem: CartLineItemModel | undefined;

    const items: CartLineItemModel[] = cart.getLineItems().filter((item: CartLineItemModel) => {
      if (item.getVariant().isGiveaway()) {
        giveaway = item;
        return false;
      }
      if (!lastAddedItem || item.getLastAddedTimestamp() > lastAddedItem.getLastAddedTimestamp()) {
        lastAddedItem = item;
      }
      if (!lastModifiedItem || item.getLastModifiedTimestamp() > lastModifiedItem.getLastModifiedTimestamp()) {
        lastModifiedItem = item;
      }
      return true;
    });

    const shipFromStoreItems: CartLineItemModel[] = [];
    const onlineItems: CartLineItemModel[] = [];
    const soldOutItems: CartLineItemModel[] = [];

    items.forEach((item) => {
      if (!item.getVariant().getAvailability().isInStock) {
        soldOutItems.push(item);
      } else if (item.getVariant().getAvailability().isShipFromStore) {
        shipFromStoreItems.push(item);
      } else {
        onlineItems.push(item);
      }
    });

    const hasMultipleShipments: boolean = shipFromStoreItems.length > 0 && onlineItems.length > 0;

    return {
      count: cart.getCount(),
      items,
      shipFromStoreItems,
      onlineItems,
      soldOutItems,
      giveaway,
      hasMultipleShipments,
      lastAddedItem,
      lastModifiedItem,
    };
  }

  function resetCustomCartData() {
    isAddVariantToCartRef.value = false;
    maxQuantityAddedToCartRef.value = 0;
  }

  function canAddProductToCart() {
    if (cartModelRef.value.getCount(false) > 0 && cartModelRef.value.hasPrintGiftCard()) {
      return false;
    }

    if (cartModelRef.value.hasMixOfPrintGiftCardAndProducts()) {
      return false;
    }

    return true;
  }

  function canAddGiftCardToCart() {
    if (cartModelRef.value.getCount(false) > 0 && !cartModelRef.value.hasPrintGiftCard()) {
      return false;
    }

    if (cartModelRef.value.hasMixOfPrintGiftCardAndProducts()) {
      return false;
    }

    return true;
  }

  function updateCart(responseData: ApiResponse<BffResponseWithCart> | null, params: { showChangeInfo: boolean }) {
    const result = {
      cartChanged: false,
    };
    if (responseData === null || responseData.data?.code === 'OK') {
      const isCartNotEnhanced =
        responseData &&
        responseData.data &&
        'cart' in responseData.data &&
        responseData.data.cart &&
        !responseData.data.cart.isEnhanced;
      if (isCartNotEnhanced) {
        logCheckoutError({
          errorGroup: 'noEnhancedCart',
          errorDetail: responseData.data?.code,
        });
        return result;
      }
      const newCartModel = cartModel(responseData);

      result.cartChanged =
        cartModelRef.value.isInitialized() && newCartModel.getHash() !== cartModelRef.value.getHash();

      if (result.cartChanged && params.showChangeInfo) {
        addToast({
          title: $mopI18n.t('components.cart.change_info.title'),
          content: $mopI18n.t('components.cart.change_info.description'),
          status: 'info',
          showCloseButton: true,
          showIcon: true,
          autoCloseDelay: 10,
          action: `<a class="ui-link-underline" href="${$mopI18n.localePath($urls.CART)}">${$mopI18n.t(
            'cart.view',
          )}</a>`,
        });
      }
      cartModelRef.value = newCartModel;
    }

    $datadog.setGlobalProperty('cartId', cartModelRef.value.getId());
    return result;
  }

  async function setEmployeeId(employeeId: string) {
    const response = await $apiBff.setCartEmployeeId(employeeId);
    return response.data;
  }

  function storeSizeAdviceAvailableMopId(mopId: string) {
    const sizeAdviceAvailableMopIds = JSON.parse(
      sessionStorageGet(constants.SESSION_STORAGE.SIZE_ADVICE_AVAILABLE_MOP_IDS) ?? '[]',
    );
    const mopIdsSet = new Set(sizeAdviceAvailableMopIds);
    mopIdsSet.add(mopId);
    sessionStorageSet(constants.SESSION_STORAGE.SIZE_ADVICE_AVAILABLE_MOP_IDS, JSON.stringify(Array.from(mopIdsSet)));
  }

  function getAndResetSizeAdviceAvailableMopIds(items: CartLineItemModel[]) {
    const sizeAdviceAvailableMopIds = JSON.parse(
      sessionStorageGet(constants.SESSION_STORAGE.SIZE_ADVICE_AVAILABLE_MOP_IDS) ?? '[]',
    );
    const sizeAdviceProductIds = items.reduce((list: string[], item) => {
      const foundMopId = sizeAdviceAvailableMopIds.find((mopId: string) => mopId === item.getProduct().getMopId());
      return foundMopId ? [...list, foundMopId] : list;
    }, []);
    sessionStorageSet(
      constants.SESSION_STORAGE.SIZE_ADVICE_AVAILABLE_MOP_IDS,
      JSON.stringify(sizeAdviceAvailableMopIds),
    );
    return sizeAdviceProductIds;
  }

  return securedWrap({
    cartModelRef,
    initCart,
    getCart,
    addVariantToCart,
    updateCartItem,
    removeCartItem,
    addGiftCardToCart,
    loadingRef,
    isAddVariantToCartRef,
    maxQuantityAddedToCartRef,
    resetCustomCartData,
    canAddProductToCart,
    canAddGiftCardToCart,
    updateCart,
    recalculateCart,
    isCartIdentical,
    setEmployeeId,
    storeSizeAdviceAvailableMopId,
    getAndResetSizeAdviceAvailableMopIds,
  });
}
