import type { QueryCartDiscountParams, CartDiscountResponseData, CartDiscountData } from '@/types/cartDiscount';
import type {
  QueryProductDiscountParams,
  ProductDiscountResponseData,
  ProductDiscountData,
} from '@/types/productDiscount';
import type { ApiCommercetoolsConfig } from '@/types/apiInit';

function getMappedDiscounts(
  responseData: CartDiscountResponseData | ProductDiscountResponseData,
  lang: string,
  country: string,
) {
  const discountResults = responseData.body.results;
  discountResults.forEach((discount) => {
    // @ts-ignore readonly attr
    discount.name = discount.name?.[lang] || discount.name?.en || '';
    // @ts-ignore readonly attr
    discount.description = discount.description?.[lang] || discount.description?.en || '';
    const customFields = (discount as CartDiscountData).custom?.fields;
    if (!customFields) {
      return;
    }
    const translatableFields = ['label', 'labelGuest'];
    translatableFields.forEach((field) => {
      if (customFields[field]) {
        customFields[field] = customFields[field][lang] || customFields[field].en;
      }
    });
  });

  const now = new Date();
  // @ts-ignore readonly attr
  responseData.body.results = responseData.body.results.filter(
    (discountData: CartDiscountData | ProductDiscountData) => {
      if (
        (discountData as CartDiscountData).stores?.length &&
        !(discountData as CartDiscountData).stores.map((store) => store.key.toLocaleLowerCase()).includes(country)
      ) {
        // Filter Cart Discount by country
        return false;
      }
      if ((discountData as ProductDiscountData).predicate) {
        // Filter Product Discount by country
        const match = (discountData as ProductDiscountData).predicate.match(/country in \(([^)]+)\)/);
        if (match && match[1]) {
          const countries = match[1].toLocaleLowerCase().replace(/"/g, '').split(',');
          if (countries.length && !countries.includes(country)) {
            return false;
          }
        }
      }
      const validFrom = discountData.validFrom ? new Date(discountData.validFrom) : now;
      const validUntil = discountData.validUntil ? new Date(discountData.validUntil) : now;
      return now >= validFrom && now <= validUntil && validFrom <= validUntil;
    },
  );
  return responseData;
}

export async function queryDiscounts(apiConfig: ApiCommercetoolsConfig, params?: QueryCartDiscountParams) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.limit = params.queryArgs.limit || 500;
  const data = getMappedDiscounts(
    await apiConfig.apiClient.cartDiscounts().get(params).execute(),
    apiConfig.lang,
    apiConfig.country,
  );
  return data as CartDiscountResponseData;
}

export async function queryProductDiscounts(apiConfig: ApiCommercetoolsConfig, params?: QueryProductDiscountParams) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.limit = params.queryArgs.limit || 500;
  const data = getMappedDiscounts(
    await apiConfig.apiClient.productDiscounts().get(params).execute(),
    apiConfig.lang,
    apiConfig.country,
  );
  return data as ProductDiscountResponseData;
}
