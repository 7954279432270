import { securedWrap } from '@mop/shared/utils/securedWrap';
import type Ui2Toast from '@mop/ui2/components/Ui2Toast/Ui2Toast.vue';

type ToastProps = InstanceType<typeof Ui2Toast>['$props'];

type ToastData = ToastProps & {
  content: string;
  action?: string;
};

type ToastsComposableStorage = {
  toastsListRef: Ref<ToastData[]>;
};

export default function useMopToastsClient() {
  const storage = initStorage<ToastsComposableStorage>('useToasts');
  const toastsListRef: Ref<ToastData[]> = storage.get('toastsListRef') || storage.saveAndGet('toastsListRef', ref([]));

  function addToast(toast: ToastData) {
    toastsListRef.value.push({
      ...toast,
      autoCloseDelay: toast.autoCloseDelay ?? 2,
      modelValue: true,
    });
  }

  return securedWrap({
    toastsListRef,
    addToast,
  });
}
