<script setup lang="ts">
import type { CmsMedia, CmsMediaImage } from '@mop/cms/types';
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsPromoTile, CmsUiProductGrid } from '@/types/cms';

defineOptions({
  name: 'MopCmsProductGrid',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
});

const {
  $breakpoint: { isMobileRef },
} = useNuxtApp();

const data: any = props.data;

const element: CmsUiProductGrid = getElementUiObject(data, {
  tiles: data.items?.map((item: any) => {
    const tile: CmsPromoTile = getCmsPromoTile(item) as CmsPromoTile;
    const imageType: string = tile.layout === 'single' ? 'product-tile-grid' : 'product-tile-grid-hero';
    tile.imageType = imageType;
    if (tile.media) {
      (tile.media as CmsMediaImage).imageType = imageType;
    }
    return tile;
  }),
} as CmsUiProductGrid);

const enhancedTilesRef = computed<CmsPromoTile[]>(() => {
  return element.tiles.map((tile) => {
    // Fallback to product image on mobile for double tiles if not specified
    const breakPointMedia: CmsMedia | undefined =
      !isMobileRef.value || tile.layout === 'single' || tile.hasMobileMedia ? tile.media : undefined;
    if (breakPointMedia) {
      return tile;
    }

    return {
      ...tile,
      media: breakPointMedia,
    };
  });
});
</script>

<template>
  <div v-if="enhancedTilesRef.length > 0" v-storyblok-editable="element" class="cms-product-grid">
    <UiGrid>
      <UiGridItem
        v-for="(promoElement, index) in enhancedTilesRef"
        :key="`${index}-${promoElement._uid}-${isMobileRef}`"
        :layout="promoElement.layout"
        :visibility="promoElement.visibility"
        :custom-id="promoElement.customId"
        :data-position="promoElement.position"
      >
        <MopTile
          :data="promoElement"
          :image-type="promoElement.imageType"
          :enable-popularity-flag="enablePopularityFlag"
          disable-image-hover-change
          :track-position="(index + 1).toString()"
        />
      </UiGridItem>
    </UiGrid>
  </div>
</template>

<style lang="scss" scoped>
.cms-product-grid {
  padding-right: $global-padding;
  padding-left: $global-padding;
  margin-bottom: $space60;

  @include apply-upto(small) {
    margin-bottom: $space40;
  }

  :deep(.grid) {
    @include apply-upto(small) {
      grid-template-columns: repeat(1, 100%);
      grid-template-areas: 'item';
      column-gap: 0;
      padding-bottom: $space30;
    }
  }

  :deep(.product-tile),
  :deep(.tile),
  :deep(.tile--double) {
    @include apply-upto(small) {
      width: columns(36);
    }
  }

  :deep(.tile--single) {
    @include apply-upto(small) {
      grid-column-end: span 2;
    }
  }
}

.cms-product-grid__failed-list {
  display: flex;
}

.cms-product-grid__failed-element {
  @include text-style(small);

  background: $signal-alert;
  color: $white;
  padding: $space5;
  margin: $space5;
}
</style>
