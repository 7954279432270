import type { AxiosError } from 'axios';
import { handleAxiosError, getBffUrlWithParams, bffErrorHandler } from '@/api/utils';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type {
  BffApiNewsletterSubscriptionPostRequest,
  BffApiNewsletterSubscriptionPostResponse,
  BffApiNewsletterUnsubscribeFeedbackPostRequest,
  BffApiNewsletterUnsubscribeFeedbackPostResponse,
} from '@/types/bff';
const API_NAME = 'AWS - BFF Newsletter';

export function setNewsletterSubscription(
  apiConfig: ApiBffConfig,
  params: BffApiNewsletterSubscriptionPostRequest,
): Promise<ApiResponse<BffApiNewsletterSubscriptionPostResponse>> {
  const url = getBffUrlWithParams('/newsletter/subscription', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiNewsletterSubscriptionPostResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setNewsletterSubscription',
      url,
    }),
  }));
}

export function createNewsletterUnsubscriptionFeedback(
  apiConfig: ApiBffConfig,
  params: BffApiNewsletterUnsubscribeFeedbackPostRequest,
): Promise<ApiResponse<BffApiNewsletterUnsubscribeFeedbackPostResponse>> {
  const url = getBffUrlWithParams('/newsletter/unsubscribe-feedback', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiNewsletterUnsubscribeFeedbackPostResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createNewsletterUnsubscriptionFeedback',
      url,
    }),
  }));
}
