import { datadogRum } from '@datadog/browser-rum';
import type { RouteLocationNormalized } from 'vue-router';
import type { CustomerModel } from '@/types/customer';

class CheckoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'CheckoutError';
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const applicationId = String(config.public.DATADOG_APP_KEY ?? '');
  const clientToken = String(config.public.DATADOG_APP_TOKEN ?? '');
  const version = String(config.public.VERSION ?? '');
  const datadogEnv = String(config.public.DATADOG_ENV ?? 'develop');
  const productionHost = String(config.public.BASE_URL ?? '');
  const BFF_HOST = config.public.CT_AWS_BFF_PROXIED_URL || config.public.CT_AWS_BFF_URL;
  const sessionSampleRateRoutes = String(config.public.DATADOG_RUM_ENABLED_ROUTES ?? '').split(',');
  const sessionReplaySampleRateRoutes = String(config.public.DATADOG_RUM_SESSION_REPLAY_ENABLED_ROUTES ?? '').split(
    ',',
  );
  const sessionSampleRate = parseInt((config.public.DATADOG_RUM_SAMPLE_RATE as string) ?? '0');
  const sessionReplaySampleRate = parseInt((config.public.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE as string) ?? '0');
  const checkoutSessionSampleRate = parseInt((config.public.DATADOG_RUM_SAMPLE_RATE_CHECKOUT as string) ?? '0');
  const checkoutSessionReplaySampleRate = parseInt(
    (config.public.DATADOG_RUM_SESSION_REPLAY_SAMPLE_RATE_CHECKOUT as string) ?? '0',
  );
  const isBffURL = (url: string) => url.startsWith(BFF_HOST);
  const isInternalURL = (url: string) => url.includes('_nuxt4/') || isBffURL(url);

  let isInitalised = false;
  // init called only when consent is present from default.vue on each route change, to enable only for defined routes and persist afterwards
  const initDataDogBasedOnRoute = (route: RouteLocationNormalized) => {
    if (isInitalised || !applicationId || !clientToken) {
      return;
    }
    const { fullPath, meta } = route;
    const isSamplingForRouteAllowed = sessionSampleRateRoutes.some(
      (routePartial) => routePartial && fullPath.includes(routePartial),
    );
    if (!isSamplingForRouteAllowed) {
      return;
    }
    const isCheckout = meta?.datadogGroup === DATADOG_GROUP.CHECKOUT;
    const isReplaySamplingForRouteAllowed = sessionReplaySampleRateRoutes.some(
      (routePartial) => routePartial && fullPath.includes(routePartial),
    );

    const rumSessionSampleRate = isCheckout ? checkoutSessionSampleRate : sessionSampleRate;
    let rumSessionReplaySampleRate = 0;
    if (isReplaySamplingForRouteAllowed) {
      rumSessionReplaySampleRate = isCheckout ? checkoutSessionReplaySampleRate : sessionReplaySampleRate;
    }
    isInitalised = true;

    datadogRum.init({
      beforeSend: (event) => {
        // Track only BFF performance
        if (event.type === 'resource') {
          const url = event.resource.url ?? '';

          if (!isBffURL(url)) {
            return false; // Discard the event
          }
        }

        // Track errors from internal js and bff
        if (event.type === 'error' && event.error) {
          // Exclude console error events
          if (event.error.source === 'console') {
            return false;
          }
          const url = event.error.resource?.url ?? '';
          if ((url && !isInternalURL(url)) || event.error.handling === 'unhandled') {
            return false; // Discard the event
          }
        }

        return true;
      },
      applicationId,
      clientToken,
      env: datadogEnv,
      site: 'datadoghq.com',
      service: productionHost,
      version,
      sessionSampleRate: rumSessionSampleRate,
      sessionReplaySampleRate: rumSessionReplaySampleRate,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      trackingConsent: 'granted',
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/^https:\/\/(?!sgtm\.)[^/]+\.marc-o-polo\.com/],
    });

    window.mopLogger ??= {};
    let logErrorOriginal: null | ((err: any) => void) = null;
    if (window.mopLogger.logError) {
      logErrorOriginal = window.mopLogger.logError.bind(null);
    }

    window.mopLogger.logError = (err) => {
      logErrorOriginal && logErrorOriginal(err);

      if (typeof err === 'string') {
        err = new CheckoutError(err);
      }

      if (typeof err === 'object') {
        const superError = new CheckoutError(err.message);
        if (err.name) {
          superError.name = err.name;
        }
        superError.stack = err.stack;
        datadogRum.addError(superError, {
          method: err?.config?.method,
          url: err?.config?.baseURL + err?.config?.url,
        });
      }
    };
  };

  const setUserContext = (customer: CustomerModel) => {
    if (!isInitalised) {
      return;
    }
    if (customer.getCommercetoolsUserId()) {
      datadogRum.setUser({
        id: customer.getCommercetoolsUserId(),
        externalId: customer.getCrmUserlId(),
        customerNumber: customer.getCustomerNumber(),
        bonusAccountNumber: customer.getBonusAccountNumber(),
        isEmployeeAccount: customer.isEmployeeAccount(),
      });
    }
  };

  const setGlobalProperty = (key: any, value: any) => {
    if (!isInitalised || !key) {
      return;
    }
    datadogRum.setGlobalContextProperty(key, value);
  };

  nuxtApp.provide('datadog', {
    initDataDogBasedOnRoute,
    setUserContext,
    setGlobalProperty,
  });
});
