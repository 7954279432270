import type { ISbStoryParams, ISbStoriesParams, ISbStoryData } from 'storyblok-js-client';
import { isClient } from '@mop/shared/utils/util';
import type { CmsStoryListResponseData, CmsStoryResponseData, ApiCmsInstance } from '@/types/cms';
import { handleAxiosError, errorHandler } from '@/api/utils';
import type {
  CountryCategoryConfig,
  CountryCategoryConfigValues,
  CategoryPreference,
  CountryProductConfig,
  CountryProductConfigValues,
  ProductPreference,
  CountryPopularityFlagConfigValues,
  PopularityFlagPreference,
} from '@/types/countryConfig';
const API_NAME = 'CMS - cms';
let ssrCacheVersion: number | undefined;

if (isClient) {
  setInterval(
    () => {
      ssrCacheVersion = undefined;
    },
    6 * 60 * 1000,
  );
}

export function getStory(
  apiInstance: ApiCmsInstance,
  slug: string,
  params: ISbStoryParams = {},
  contentOnly = false,
): Promise<CmsStoryResponseData> {
  const { cmsLanguage, cmsFallbackLanguage, cmsVersion, cmsRelease } = apiInstance.config;
  params = {
    language: cmsLanguage,
    fallback_lang: cmsFallbackLanguage,
    version: cmsVersion,
    from_release: cmsRelease,
    resolve_links: 'url',
    cv: ssrCacheVersion,
    ...params,
  };
  return errorHandler<CmsStoryResponseData>(async () => {
    const response = await apiInstance.client.getStory(slug, params);
    ssrCacheVersion = (response as any).data.cv;
    const story: any = response.data?.story;
    if (contentOnly) {
      return {
        data: {
          name: story.name,
          id: story.id,
          uuid: story.uuid,
          content: story.content,
        },
      };
    }
    return {
      data: story,
    };
  }).catch((error: any) => {
    const disableLog: boolean = !isClient && [0, 404].includes(error?.status);
    return {
      error: handleAxiosError(
        error,
        {
          api: API_NAME,
          method: 'getStory',
          slug,
          params,
          msg: error?.message,
        },
        disableLog,
      ),
    };
  });
}

export function getStories(
  apiInstance: ApiCmsInstance,
  slug?: string,
  params: ISbStoriesParams = {},
  contentOnly = false,
): Promise<CmsStoryListResponseData> {
  const { cmsLanguage, cmsFallbackLanguage, cmsVersion, cmsRelease } = apiInstance.config;

  params = {
    language: cmsLanguage,
    fallback_lang: cmsFallbackLanguage,
    version: cmsVersion,
    from_release: cmsRelease,
    resolve_links: 'url',
    cv: ssrCacheVersion,
    ...params,
  };

  if (!params.starts_with && slug) {
    params.starts_with = slug;
  }

  return errorHandler<CmsStoryListResponseData>(async () => {
    const response: any = await apiInstance.client.getStories(params);
    ssrCacheVersion = response.data.cv;
    if (contentOnly) {
      response.data.stories = response.data.stories.map((story: ISbStoryData) => {
        return {
          name: story.name,
          id: story.id,
          uuid: story.uuid,
          content: story.content,
        };
      });
    }
    return {
      data: response,
    };
  }).catch((error: any) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getStories',
      slug,
      params,
      msg: error?.message,
    }),
  }));
}

// TODO: commercetools migrate to CT categories from storyblok
function getMinifiedShopPreferencesCategories(categoryPreferences: CategoryPreference[]) {
  const categoryConfig: CountryCategoryConfig = {};
  categoryPreferences.forEach((categoryPreference: CategoryPreference) => {
    const categoryConfigValues: CountryCategoryConfigValues = {};
    const {
      mopId,
      customName,
      categoryFlag,
      isLandingPage,
      disableSizeAdvice,
      enableSeoFilterUrls,
      addSpaceAboveMenuItem,
      textColor,
    } = categoryPreference;
    if (customName) {
      categoryConfigValues.customName = customName;
    }
    if (categoryFlag) {
      categoryConfigValues.categoryFlag = categoryFlag;
    }
    if (isLandingPage) {
      categoryConfigValues.isLandingPage = isLandingPage;
    }
    if (disableSizeAdvice) {
      categoryConfigValues.isSizeAdviceDisabled = disableSizeAdvice;
    }
    if (enableSeoFilterUrls) {
      categoryConfigValues.isSeoFilterUrlsEnabled = enableSeoFilterUrls;
    }
    if (addSpaceAboveMenuItem) {
      categoryConfigValues.addSpaceAboveMenuItem = addSpaceAboveMenuItem;
    }
    if (textColor?.color) {
      categoryConfigValues.textColor = textColor.color;
    }

    if (Object.keys(categoryConfigValues).length) {
      categoryConfig[mopId] = categoryConfigValues;
    }
  });
  return categoryConfig;
}

function getMinifiedShopPreferencesProducts(productPreferences: ProductPreference[]) {
  const productConfig: CountryProductConfig = {};
  productPreferences.forEach((productPreference: ProductPreference) => {
    const productConfigValues: CountryProductConfigValues = {};
    const { vimeoId, productId } = productPreference;
    if (vimeoId) {
      productConfigValues.vimeoId = vimeoId;
    }
    if (Object.keys(productConfigValues).length) {
      productConfig[productId] = productConfigValues;
    }
  });
  return productConfig;
}

function getMinifiedShopPreferencesPopularityFlags(popularityFlagPreferences: PopularityFlagPreference[]) {
  const preferences: CountryPopularityFlagConfigValues = {};
  popularityFlagPreferences.forEach((popularityFlagPreference: PopularityFlagPreference) => {
    const { productId, count } = popularityFlagPreference;
    preferences[productId] = parseInt(count);
  });
  return preferences;
}

export function getGlobalStories(
  apiInstance: ApiCmsInstance,
  slug: string,
  params: ISbStoriesParams = {},
): Promise<CmsStoryListResponseData> {
  const { cmsLanguage, cmsFallbackLanguage, cmsVersion, cmsRelease } = apiInstance.config;

  params = {
    starts_with: slug,
    language: cmsLanguage,
    fallback_lang: cmsFallbackLanguage,
    version: cmsVersion,
    from_release: cmsRelease,
    resolve_links: 'url',
    // 1, in order to not cache cv by storyblok package and get fresh version
    cv: 1,
    ...params,
  };

  return errorHandler<CmsStoryListResponseData>(async () => {
    const response: any = await apiInstance.client.getStories(params);
    ssrCacheVersion = response.data.cv;

    const superBannerStoryUuidStory: any = response.data.stories.find((story: any) => story.content?.superBannerStory);
    if (superBannerStoryUuidStory) {
      const superBannerStoryUuid: string = superBannerStoryUuidStory.content.superBannerStory;
      const superBannerResponse: any = await getStory(apiInstance, superBannerStoryUuid, { find_by: 'uuid' });
      if (superBannerResponse?.data) {
        superBannerStoryUuidStory.content = superBannerResponse.data.content;
        superBannerStoryUuidStory.id = superBannerResponse.data.id;
        superBannerStoryUuidStory.uuid = superBannerResponse.data.uuid;
      }
    }

    response.data.stories = response.data.stories.map((story: ISbStoryData) => {
      const content: any = story.content;
      if (content.categories) {
        content.categories = content.categories.reduce((list: any[], categoryUUID: string) => {
          const category: any = response.data.rels.find((item: any) => item.uuid === categoryUUID);
          if (!category) {
            return list;
          }
          list.push({
            mopCategoryId: category.content?.mopCategoryId,
          });
          return list;
        }, []);
      }

      if (content.component === 'ShopPreferencesCategories') {
        content.categoryPreferences = getMinifiedShopPreferencesCategories(content.categoryPreferences);
      }

      if (content.component === 'ShopPreferencesProducts') {
        content.productPreferences = getMinifiedShopPreferencesProducts(content.productPreferences);
      }

      if (content.component === 'ShopPreferencesPopularityFlags') {
        content.itemsOnTrend = getMinifiedShopPreferencesPopularityFlags(content.itemsOnTrend);
        content.itemsBestseller = getMinifiedShopPreferencesPopularityFlags(content.itemsBestseller);
        content.itemsPopular = getMinifiedShopPreferencesPopularityFlags(content.itemsPopular);
      }

      return {
        name: story.name,
        id: story.id,
        uuid: story.uuid,
        content: story.content,
      };
    });

    return {
      data: response,
    };
  }).catch((error: any) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getGlobalStories',
      slug,
      params,
      msg: error?.message,
    }),
  }));
}
