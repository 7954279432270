<script setup lang="ts">
import type { CategoryModel } from '@/types/category';

defineOptions({
  name: 'MopHeaderCategorySubscripts',
});

const props = defineProps({
  category: {
    type: Object as PropType<CategoryModel>,
    required: true,
  },
});

const { $mopConfig } = useNuxtApp();

const categoryFlag = computed(() => {
  return $mopConfig.getCategoryPreference(props.category.getMopId()).categoryFlag;
});
</script>

<template>
  <span v-if="category.getProductCount() !== undefined || categoryFlag">
    <template v-if="category.getProductCount() !== undefined">
      {{ category.getProductCount() }}
    </template>
    <template v-if="categoryFlag">
      {{ categoryFlag }}
    </template>
  </span>
</template>
