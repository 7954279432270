<script setup lang="ts">
import { computed } from 'vue';
import type { UiPrice, UiEcomPriceSize } from '@mop/ui2/types';
import useUi2Config from '@mop/ui2/composables/useUi2Config';

defineOptions({
  name: 'Ui2EcomPrice',
});

const props = defineProps({
  price: {
    type: Object as PropType<UiPrice | undefined>,
    required: true,
  },
  showSaving: {
    type: Boolean,
    default: false,
  },
  showPrice: {
    type: Boolean,
    default: false,
  },
  disableHighlight: {
    type: Boolean,
    default: false,
  },
  showGlobalE: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String as PropType<UiEcomPriceSize>,
    default: 'md',
  },
});

const { isSaleEnabled, isSavingLabelEnabled, isGlobalEEnabled } = useUi2Config();
const priceStateRef = computed(() => {
  const { salePrice, basePrice, salePercentage, currency } = props.price ?? ({} as UiPrice);
  const isSale = salePrice !== basePrice;
  const showSaleHighlighted = isSale && !props.disableHighlight && isSaleEnabled();
  const showSavingLabel = isSale && isSavingLabelEnabled();
  return {
    salePrice,
    basePrice,
    salePercentage,
    currency,
    isSale,
    showSaleHighlighted,
    showSavingLabel,
  };
});

const showGlobalEBasketData = isGlobalEEnabled() && props.showGlobalE;
const showGlobalEPriceData = isGlobalEEnabled() && !props.showGlobalE;
</script>

<template>
  <span
    :class="[
      'ui-price',
      `ui-price--${size}`,
      {
        'ui-price--sale': priceStateRef.isSale,
        'ui-price--highlighted-sale': priceStateRef.showSaleHighlighted,
      },
    ]"
  >
    <span
      v-show="showPrice"
      class="ui-price__price"
      :data-ge-price="showGlobalEPriceData ? '' : undefined"
      :data-ge-basket-productsaleprice="showGlobalEBasketData ? '' : undefined"
    >
      {{
        $ui2Config.formatPrice({
          price: priceStateRef.salePrice,
          currency: priceStateRef.currency,
        })
      }}
    </span>

    <span
      v-show="showPrice && priceStateRef.isSale"
      class="ui-price__original"
      :data-ge-price="showGlobalEPriceData ? '' : undefined"
      :data-ge-basket-productlistprice="showGlobalEBasketData ? '' : undefined"
    >
      {{
        $ui2Config.formatPrice({
          price: priceStateRef.basePrice,
          currency: priceStateRef.currency,
        })
      }}
    </span>
    <span
      v-show="showSaving && priceStateRef.showSavingLabel"
      class="ui-price__saving"
      :data-ge-basket-productdiscountsprice="showGlobalEBasketData ? '' : undefined"
    >
      -{{ priceStateRef.salePercentage }}%
    </span>
  </span>
</template>

<style scoped lang="scss">
.ui-price {
  @include v2-text-style(md, highlight);
  color: $color-text-body-primary;
  display: flex;
  flex-wrap: wrap;
  gap: $space-8;
  align-items: center;
  line-height: 20px;
}

.ui-price__original {
  @include v2-text-style(md);
  color: $color-text-body-secondary;
  text-decoration: line-through;
  display: inline-flex;
}

.ui-price__saving {
  @include v2-text-style(md);
  border-radius: $border-radius-rounded-md;
  border: 1px solid currentColor;
  padding: 0 $space-4;
}

.ui-price--md {
  @include v2-text-style(sm, highlight);

  .ui-price__original {
    @include v2-text-style(sm);
  }

  .ui-price__saving {
    @include v2-text-style(sm);
  }
}

.ui-price--sm {
  @include v2-text-style(xs, highlight);
  gap: $space-4;

  .ui-price__original {
    @include v2-text-style(xs);
  }

  .ui-price__saving {
    @include v2-text-style(xs);
  }
}

.ui-price--sale,
.ui-price--highlighted-sale {
  line-height: $space-24;
  &.ui-price--sm {
    line-height: 20px;
  }
}

.ui-price--highlighted-sale {
  .ui-price__price,
  .ui-price__saving {
    color: $color-text-sale;
  }
}
</style>
