<script setup lang="ts">
import { isClient } from '@mop/shared/utils/util';
import type { CmsHeadlineWithUppercaseOption } from '@mop/cms/types';
import type { ProductModel } from '@/types/product';
import type { CmsPromoTile } from '@/types/cms';

defineOptions({
  name: 'MopTile',
});

const props = defineProps({
  data: {
    type: Object as PropType<CmsPromoTile>,
    required: true,
  },
  imageType: {
    type: String,
    default: 'product-tile',
  },
  showRecoImage: {
    type: Boolean,
    default: false,
  },
  disableImageHoverChange: {
    type: Boolean,
    default: false,
  },
  trackPosition: {
    type: String,
    default: '',
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
});

const {
  layout = 'single',
  alignment = 'bottom',
  productId = '',
  productImageIndex = PRODUCT_IMAGE_INDEX.STANDARD,
  productHoverImageIndex = PRODUCT_IMAGE_INDEX.DETAIL,
  headline = {} as CmsHeadlineWithUppercaseOption,
  headlineFontStyle = 'default',
  subline = '',
  isSublineUppercase,
  hideText = false,
  link = {},
  isProductTile = true,
  isTextTile = false,
  media,
  cta,
} = props.data;

let productRef = ref<ProductModel | null>(null);

if (isClient && productId) {
  productRef = useMopPromotionProducts().getPromotionProductRef(productId);
}
</script>

<template>
  <MopProductTile
    v-if="isProductTile && productRef"
    v-storyblok-editable="data"
    :product="productRef"
    :media="media"
    :image-type="imageType"
    :hide-text="hideText"
    :disable-click="$storyblokLivePreview.isEnabledInIframe"
    :image-index="showRecoImage && productRef ? productRef.getImageIndexForRecos() : productImageIndex"
    :image-hover-index="showRecoImage && productRef ? productRef.getImageIndexForRecos(true) : productHoverImageIndex"
    :disable-image-hover-change="disableImageHoverChange"
    :enable-popularity-flag="enablePopularityFlag"
    data-tile-type="product-tile"
    gtm-list-type="Category"
    :data-track-element-item="`${media?.trackingId || 'product image'}|${trackPosition}`"
  />
  <MopTileText
    v-else-if="isTextTile"
    v-storyblok-editable="data"
    :alignment="alignment"
    :headline="headline"
    :headline-font-style="headlineFontStyle"
    :subline="subline"
    :layout="layout"
    :is-subline-uppercase="isSublineUppercase"
    :link="link"
    :cta="cta"
    data-tile-type="text-tile"
    gtm-list-type="Category"
    :data-track-element-item="`${headline.text || subline}|${trackPosition}`"
  />
  <MopTilePromo
    v-else
    v-storyblok-editable="data"
    :product="productRef"
    :media="media"
    :headline="headline"
    :subline="subline"
    :is-subline-uppercase="isSublineUppercase"
    :link="link"
    :image-type="imageType"
    :image-index="showRecoImage && productRef ? productRef.getImageIndexForRecos() : productImageIndex"
    :cta="cta"
    data-tile-type="promo-tile"
    gtm-list-type="Category"
    :data-track-element-item="`${media?.trackingId || 'product image'}|${trackPosition}`"
  />
</template>
