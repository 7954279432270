// Overwrite default scroll behaviour on route change,
// to provide a nice back-button-experience
// https://router.vuejs.org/guide/advanced/scroll-behavior.html#async-scrolling
// Original code: https://github.com/nuxt/nuxt.js/blob/dev/packages/vue-app/template/router.scrollBehavior.js
import { isClient, scrollToPosition } from '@mop/shared/utils/util';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import type { RouterConfig } from '@nuxt/schema';

/**
 * COPY PASTE FROM
 * https://github.com/nuxt/nuxt.js/blob/dev/packages/vue-app/template/router.scrollBehavior.js
 */
if (isClient) {
  if ('scrollRestoration' in window.history) {
    setScrollRestoration('manual');

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      setScrollRestoration('auto');
    });

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      setScrollRestoration('manual');
    });
  }
}

function setScrollRestoration(newVal: ScrollRestoration) {
  try {
    window.history.scrollRestoration = newVal;
  } catch (error) {}
}

function getMatchedComponents(route: RouteLocationNormalizedLoaded, matches = false, prop = 'components') {
  return Array.prototype.concat.apply(
    [],
    route.matched.map((m: any, index) => {
      return Object.keys(m[prop]).map((key) => {
        matches && (matches as unknown as number[]).push(index);
        return m[prop][key];
      });
    }),
  );
}

function shouldScrollToTop(route: RouteLocationNormalizedLoaded) {
  const Pages = getMatchedComponents(route);
  if (Pages.length === 1) {
    const { options = {} } = Pages[0];
    return options.scrollToTop !== false;
  }
  return Pages.some(({ options }) => options && options.scrollToTop);
}

/**
 * COPY PASTE END
 */

const urlHashOverlayPrefix = '#overlay-';

type Position = { left: number; top: number };

let position: false | Position;

export default <RouterConfig>{
  scrollBehavior(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded, savedPosition) {
    if (!from.name) {
      return;
    }

    position = false;
    const isRouteChanged = to !== from;

    // Do nothing if overlay opens or closes
    if (
      to.path === from.path &&
      to.hash !== from.hash &&
      (to.hash.startsWith(urlHashOverlayPrefix) || (from.hash.startsWith(urlHashOverlayPrefix) && !to.hash))
    ) {
      return;
    }

    if (savedPosition) {
      position = savedPosition;
    } else if (isRouteChanged && shouldScrollToTop(to)) {
      position = { left: 0, top: 0 };
    }

    return new Promise((resolve) => {
      window.addEventListener(
        'transition-complete',
        () => {
          if (to.hash && !savedPosition) {
            // Do nothing, LayoutDefault logic takes care
            // Will not work here because first load is not entering here
            return;
          }

          const top = (position as Position)?.top;
          if (top === 0) {
            resolve({ left: 0, top: 0 });
            return;
          }

          // Hacky way to get to scroll position on browser-back while content is fetched and loaded dynamically
          // TODO: Rethink when async setup of vue3 is available in nuxt
          scrollToPosition(top, false);
          let delay = top !== window.scrollY ? 200 : 0;
          setTimeout(() => {
            scrollToPosition(top, false);
            delay = top !== window.scrollY ? 300 : 0;
            setTimeout(() => {
              scrollToPosition(top, false);
              resolve(position);
            }, delay);
          }, delay);
        },
        { once: true },
      );
    });
  },
};
