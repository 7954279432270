import { isClient, generateRandomChars } from '@mop/shared/utils/util';
import { localStorageGet, localStorageSet } from '@mop/shared/utils/localStorage';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { recommendationModel } from '@/models';
import type {
  RecommendationModel,
  RecommendationWidget,
  EpoqWidget,
  EpoqWidgetParameters,
  EpoqParams,
} from '@/types/recommendation';
import type { ProductModel } from '@/types/product';

const PRODUCTS_PER_REQUEST = 12;
const widgetRules: Record<RecommendationWidget, EpoqWidget[]> = {
  PRODUCT: [
    {
      widgetName: 'pdpAlternative',
    },
    {
      widgetName: 'pdpXsell',
    },
    {
      widgetName: 'pdpPopular',
    },
  ],
  CATEGORY: [
    {
      widgetName: 'categoryPage',
      parameters: ['CATEGORY_ID', 'FALLBACK_CATEGORY_PATH'],
    },
  ],
  HOMEPAGE: [
    {
      widgetName: 'homePage',
      parameters: ['CATEGORY_ID'],
    },
  ],
  CART: [
    {
      widgetName: 'cart',
    },
  ],
  CART_OVERLAY: [
    {
      widgetName: 'cartlayer',
    },
  ],
  ERROR_PAGE: [
    {
      widgetName: 'errorPage',
    },
  ],
  ORDER_CONFIRMATION: [
    {
      widgetName: 'orderConfirmationPage',
    },
  ],
  WISHLIST: [
    {
      widgetName: 'wishlistPage',
      parameters: ['PRODUCT_IDS'],
    },
  ],
  OTHER: [
    {
      widgetName: 'homePage',
    },
  ],
};

export default function useMopRecommendationClient() {
  const { productModelListRef, searchProductsByMopProductIds } = useMopProducts();
  const { activeCategoryPathRef } = useMopRouter();
  const { $apiEpoq, $mopConfig } = useNuxtApp();
  const { wishlistModelRef } = useMopWishlistClient();
  const { cartModelRef } = useMopCartClient();

  const recommendationModelRef = ref<RecommendationModel>(recommendationModel(null));
  const productModelsRef = ref<ProductModel[]>([]);

  function buildWidgetRule(widget: RecommendationWidget, parameters: EpoqWidgetParameters = {}): string {
    const rule = widgetRules[widget]
      .map((rule) => {
        const ruleParameters = Object.values({
          PRODUCTS_COUNT: PRODUCTS_PER_REQUEST,
          ...parameters,
        }).join(',');
        return `${rule.widgetName}(${ruleParameters})`;
      })
      .join(';');
    return rule;
  }

  function getEpoqParams(): EpoqParams {
    let sessionId = localStorageGet(constants.LOCAL_STORAGE.EPOQ_SESSION_ID);
    if (!sessionId) {
      sessionId = generateRandomChars(32);
      localStorageSet(constants.LOCAL_STORAGE.EPOQ_SESSION_ID, sessionId);
    }

    return {
      tenantId: $mopConfig.getEpoqTenantId(),
      widgetTheme: 'json2',
      sessionId,
      onlyids: true,
    };
  }

  async function searchAllProductRecommendations(mopProductId: string) {
    if (!isEnabled()) {
      return [];
    }
    recommendationModelRef.value = recommendationModel(
      await $apiEpoq.getRecommendation({
        ...getEpoqParams(),
        rules: buildWidgetRule('PRODUCT'),
        productId: mopProductId,
      }),
    );
    await searchProducts();

    // group by recos domains
    const productsByDomain: Array<ProductModel[]> = [];
    recommendationModelRef.value.getProductIdsByDomain().forEach((ids) => {
      productsByDomain.push(productModelsRef.value.filter((product) => ids.includes(product.getMopId())));
    });

    return productsByDomain;
  }

  async function searchRecommendation(widget: RecommendationWidget) {
    if (!isEnabled()) {
      return;
    }

    const parameters: EpoqWidgetParameters = {};
    if (widget === 'CATEGORY' || widget === 'HOMEPAGE') {
      let categoryId = activeCategoryPathRef.value[activeCategoryPathRef.value.length - 1].getMopId();
      let fallbackCategoryPath = (activeCategoryPathRef.value[1] ?? activeCategoryPathRef.value[0])
        .getMopId()
        .replace('-', '>');
      if (categoryId.startsWith('mopd')) {
        categoryId = categoryId.replace('mopd-', '').replace('mopd', '');
        fallbackCategoryPath = fallbackCategoryPath.replace('mopd>', '').replace('mopd', '');
      }
      parameters.CATEGORY_ID = categoryId;
      parameters.FALLBACK_CATEGORY_PATH = fallbackCategoryPath;
    } else if (widget === 'WISHLIST') {
      const wishlistProductIds = wishlistModelRef.value.getProducts().map((product) => product.getMopId());
      parameters.PRODUCT_IDS = wishlistProductIds.join('|');
    }

    const rules = buildWidgetRule(widget, parameters);
    let productId;

    if (widget === 'CART_OVERLAY') {
      productId = cartModelRef.value.getLineItems()[0]?.getProduct().getMopId();
    }

    recommendationModelRef.value = recommendationModel(
      await $apiEpoq.getRecommendation({
        ...getEpoqParams(),
        rules,
        productId,
      }),
    );
    await searchProducts();
  }

  async function searchProducts() {
    if (recommendationModelRef.value.hasError()) {
      return;
    }
    const productIds: string[] = recommendationModelRef.value.getProductIds();
    if (!productIds?.length) {
      return;
    }

    await searchProductsByMopProductIds(productIds, true);

    // Sort as per epoq response IDs order
    productModelsRef.value = productModelListRef.value.getProductModelList().sort((productA, productB) => {
      return productIds.indexOf(productA.getMopId()) - productIds.indexOf(productB.getMopId());
    });
  }

  function isEnabled() {
    return isClient && useRuntimeConfig().public.RECOMMENDATION_ENABLED && $apiEpoq;
  }

  async function reportEngagement(productId: string, recommendationId: string) {
    const { sessionId, tenantId } = getEpoqParams();
    await $apiEpoq.postFeedback({
      sessionId,
      tenantId,
      productId,
      recommendationId,
    });
  }

  function getEpoqWidgetName(widget: RecommendationWidget, widgetIndex = 0) {
    return widgetRules[widget][widgetIndex].widgetName;
  }

  return securedWrap({
    searchAllProductRecommendations,
    searchRecommendation,
    reportEngagement,
    productModelsRef,
    recommendationModelRef,
    getEpoqWidgetName,
  });
}
