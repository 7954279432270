import { logError } from '@mop/shared/utils/logger';
import axios from 'axios';
import type { ApiError } from '@/types/error';
import type { ApiBffConfig } from '@/types/apiInit';

export function handleAxiosError(error: any, debugData: any, disableLog = false): ApiError {
  // prevents logging request cancelations
  if (!disableLog && !axios.isCancel(error)) {
    const originalError: any = error?.toJSON ? error.toJSON() : debugData || error;
    let apiError: any = originalError;
    // transform timedout requests into grouped mode
    if ((originalError?.message as string)?.includes('timeout')) {
      apiError = {
        message: `${debugData?.api}: ${debugData?.method} [${originalError?.config?.timeout}ms]`,
        name: 'Timeout',
      };
    }

    logError(apiError);
  }
  return {
    code: error.response?.status ?? 0,
    message: error.message,
  };
}

export function bffErrorHandler<T>(callbackFn: Function): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      const result = callbackFn();
      result
        .then((response: any) => {
          const responseCode = response.data.code;
          if (responseCode !== 'OK' && !DATADOG_WHITELISTED_ERRORS.includes(responseCode)) {
            // Throw error for datadog
            try {
              throw new Error(`BFF_${responseCode}`);
            } catch (error) {
              logError(error);
            }
          }
          resolve(response);
        })
        .catch((error: any) => {
          logError(error);
          reject(error);
        });
    } catch (error) {
      logError(error);
      reject(error);
    }
  });
}

export function errorHandler<T>(callbackFn: Function): Promise<T> {
  return new Promise((resolve, reject) => {
    try {
      return resolve(callbackFn());
    } catch (error) {
      logError(error);
      reject(error);
    }
  });
}

export function getBffUrlWithParams(url: string, params: ApiBffConfig) {
  const concatinator = url.includes('?') ? '&' : '?';
  return `${url}${concatinator}country=${params.commercetoolsCountry.toLocaleLowerCase()}&language=${params.lang.toLocaleLowerCase()}&currency=${params.currency.toLocaleLowerCase()}`;
  // return `${url}${concatinator}country=${params.country.toLocaleLowerCase()}&language=${params.lang.toLocaleLowerCase()}&currency=${params.currency.toLocaleLowerCase()}`;
}
