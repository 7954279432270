<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { TabGroup, TabList, TabPanels } from '@headlessui/vue';
import { throttle } from '@mop/shared/utils/util';
import type { UiTabGroupAlign, uiTabGroupDivider } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2TabGroup',
});

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  divider: {
    type: String as PropType<uiTabGroupDivider>,
    default: 'normal',
  },
  align: {
    type: String as PropType<UiTabGroupAlign>,
    default: 'left',
  },
});

function changeTab(tabNumber: number) {
  emit('update:modelValue', tabNumber);
}

const isOverflown = ref(false);
const showLeftNav = ref(false);
const showRightNav = ref(false);
const scrollDiff = ref(0);
const scrollDistance = ref(0);
const tabListWrapRef = ref<HTMLElement>();
const tabListRef = ref<HTMLElement>();
let resizeObserver: ResizeObserver;

const calculateNavItemVisibility = throttle(() => {
  const el = tabListRef.value!;
  if (!el) {
    return;
  }
  showLeftNav.value = isOverflown.value && el.scrollLeft > 0;
  showRightNav.value = isOverflown.value && el.scrollLeft < scrollDiff.value;
}, 100);

onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    const el = tabListRef.value!;
    scrollDiff.value = el.scrollWidth - el.offsetWidth;
    scrollDistance.value = el.offsetWidth / 2;
    isOverflown.value = el.offsetWidth < el.scrollWidth;

    calculateNavItemVisibility();
  });

  tabListRef.value!.addEventListener('scroll', calculateNavItemVisibility, { passive: true });
  resizeObserver.observe(tabListRef.value!);
});

onBeforeUnmount(() => {
  // @ts-ignore
  tabListRef.value!.removeEventListener('scroll', calculateNavItemVisibility, { passive: true });
  resizeObserver.unobserve(tabListRef.value!);
  resizeObserver.disconnect();
});

function scroll(direction: 'left' | 'right') {
  tabListRef.value?.scrollBy({
    left: direction === 'left' ? -scrollDistance.value : scrollDistance.value,
    behavior: 'smooth',
  });
}
</script>

<template>
  <TabGroup as="div" class="ui-tabs__wrapper" :selected-index="props.modelValue" @change="changeTab">
    <TabList
      ref="tabListWrapRef"
      :class="[
        'ui-tabs__list-wrap',
        `ui-tabs__list-wrap--align-${align}`,
        {
          'ui-tabs__list-wrap--divider-shown': divider !== 'none',
          'ui-tabs__list-wrap--divider-shown-full': divider === 'full',
          'ui-tabs__list-wrap--overflown': isOverflown,
        },
      ]"
    >
      <div v-show="showLeftNav" class="ui-tabs__list-nav ui-tabs__list-nav--left">
        <button class="ui-tabs__list-nav-button" @click="scroll('left')">
          <Ui2Icon name="chevron-left" :height="20" :width="20" />
        </button>
      </div>
      <div v-show="showRightNav" class="ui-tabs__list-nav ui-tabs__list-nav--right">
        <button class="ui-tabs__list-nav-button" @click="scroll('right')">
          <Ui2Icon name="chevron-right" :height="20" :width="20" />
        </button>
      </div>
      <div ref="tabListRef" class="ui-tabs__list">
        <slot name="tabs" />
      </div>
    </TabList>
    <TabPanels v-if="$slots.panels" class="ui-tabs__panels">
      <slot name="panels" />
    </TabPanels>
  </TabGroup>
</template>

<style lang="scss">
.ui-tabs__list-wrap {
  display: flex;
  position: relative;
}
.ui-tabs__list-wrap--align-center {
  justify-content: center;
}

.ui-tabs__list-wrap--align-right {
  justify-content: flex-end;
}

.ui-tabs__list {
  display: flex;
  gap: $space-8;
  padding: $space-4 0px;
  overflow-y: hidden;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ui-tabs__list-nav-button {
  border-radius: $border-radius-rounded-full;
  border: 1px solid $color-border-primary;
  background: $color-surface-action-2;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow:
      0px 4px 8px -2px rgba(16, 24, 40, 0.1),
      0px 2px 4px -2px rgba(16, 24, 40, 0.06);

    background: $color-surface-primary;
  }

  &:focus-visible {
    outline: none;
    box-shadow:
      0px 0px 0px 2px $color-core-white,
      0px 0px 0px 4px $color-border-focus;
  }

  @include v2-apply-upto(mobile) {
    display: none;
  }
}

.ui-tabs__list-nav {
  @include v2-z(global, content);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 48px;
  display: flex;
  align-items: center;
  max-height: 120px;

  @include v2-apply-upto(mobile) {
    width: 40px;
  }
}
.ui-tabs__list-nav--left {
  justify-content: flex-start;
  left: 0;
  border-left: $space-16 solid $color-core-white;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, $color-core-white 100%);

  .ui-tabs__list-nav-button {
    margin-left: calc(-1 * $space-16);
  }

  @include v2-apply-upto(mobile) {
    border-left: 0;
  }
}
.ui-tabs__list-nav--right {
  justify-content: flex-end;
  right: 0;
  border-right: $space-16 solid $color-core-white;
  background: linear-gradient(270deg, $color-core-white 15.79%, rgba(255, 255, 255, 0) 100%);

  .ui-tabs__list-nav-button {
    margin-right: calc(-1 * $space-16);
  }

  @include v2-apply-upto(mobile) {
    border-right: 0;
  }
}

.ui-tabs__list-wrap--divider-shown .ui-tabs__list {
  position: relative;

  &::after {
    @include v2-z(global, content);
    background: $color-border-secondary;
    position: absolute;
    bottom: 0;
    left: $space-8;
    right: $space-8;
    height: 1px;
    content: '';
  }
}

.ui-tabs__list-wrap--divider-shown-full .ui-tabs__list {
  width: 100%;
}

.ui-tabs__list-wrap--divider-shown.ui-tabs__list-wrap--overflown {
  .ui-tabs__list::after {
    display: none;
  }

  // need to move underline divider in diff container to preserve full width when scroll is available, otherwise it is cut
  &::after {
    @include v2-z(global, content);
    background: $color-border-secondary;
    position: absolute;
    bottom: 0;
    left: $space-8;
    right: $space-8;
    height: 1px;
    content: '';
  }
}

.ui-tabs__panels {
  margin-top: $space-24;
}
</style>
