import type { AxiosInstance } from 'axios';

import { activateNewsletter, getNewsletterPreferences, setNewsletterPreferences } from './apiNewsletter';

import type {
  NewsletterActivationRequestData,
  NewsletterPreferenceRequestKeys,
  NewsletterPreferenceSettings,
} from '@/types/newsletter';

export default function initApiCrmMiddleware(apiInstance: AxiosInstance) {
  return {
    activateNewsletter: (params: NewsletterActivationRequestData) => activateNewsletter(apiInstance, params),
    getNewsletterPreferences: (pathKeys: NewsletterPreferenceRequestKeys) =>
      getNewsletterPreferences(apiInstance, pathKeys),
    setNewsletterPreferences: (pathKeys: NewsletterPreferenceRequestKeys, params: NewsletterPreferenceSettings) =>
      setNewsletterPreferences(apiInstance, pathKeys, params),
  };
}

export type ApiCrmMiddleware = ReturnType<typeof initApiCrmMiddleware>;
