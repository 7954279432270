import type { UiPriceParams } from '@mop/ui2';
import type { ShippingMethodModel } from '@/types/checkout';
import type { ApiResponse } from '@/types/apiInit';
import type { BffApiCartsShippingMethodsGet, BffShippingMethod } from '@/types/bff';

export function shippingMethodModel(response: BffShippingMethod | null) {
  return {
    isDefault() {
      return response?.isDefault === true;
    },

    getId() {
      return response?.id ?? '';
    },

    getName() {
      return response?.name || '';
    },

    getDeliveryTimeRange(formatDate: Function) {
      const deliveryTime = response?.calculatedDeliveryDurationDayRange;
      if (!deliveryTime) {
        return [];
      }
      const dateRange = deliveryTime?.split('-') || [];
      if (dateRange?.length < 2) {
        return [];
      }
      const begin = new Date();
      const end = new Date();
      begin.setDate(begin.getDate() + parseInt(dateRange[0], 10));
      end.setDate(end.getDate() + parseInt(dateRange[1], 10));
      return [
        formatDate(begin.toISOString(), { withoutYear: true, withWeekday: true }),
        formatDate(end.toISOString(), { withoutYear: true, withWeekday: true }),
      ];
    },

    getDeliveryType() {
      return response?.custom?.fields.deliveryType;
    },

    getShippingRate(currencyCode: string) {
      const zone = response?.zoneRates.find((zoneRate) => zoneRate.shippingRates.find((rate) => rate.isMatching));
      const shippingRate = zone?.shippingRates.find((rate) => rate.price.currencyCode === currencyCode);

      return shippingRate;
    },

    isFreeShipping(productPriceTotal: number, currencyCode: string) {
      const shippingRate = this.getShippingRate(currencyCode);

      return shippingRate?.freeAbove?.centAmount && shippingRate.freeAbove.centAmount < productPriceTotal * 100;
    },

    getPrice(productPriceTotal: number, currencyCode: string): UiPriceParams {
      const price = this.isFreeShipping(productPriceTotal, currencyCode)
        ? {
            centAmount: 0,
          }
        : this.getShippingRate(currencyCode)?.price ?? {
            centAmount: 0,
          };

      return {
        price: price.centAmount / 100,
        precision: 2,
        currency: currencyCode,
      };
    },

    getShippingMethodPriority(): number {
      return response?.custom?.fields.shippingMethodPriority ?? 0;
    },

    getDebugData(): any {
      return response;
    },
  };
}

export function shippingMethodListModel(responseData: ApiResponse<BffApiCartsShippingMethodsGet> | null) {
  const response = responseData ?? {};

  return {
    getList(): ShippingMethodModel[] {
      return response?.data?.shippingMethods?.map((item) => shippingMethodModel(item)) ?? [];
    },
  };
}
