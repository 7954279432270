/**
 * Composable used for global events on document and window
 */
import { isClient, getPassiveEventOption } from '@mop/shared/utils/util';

type UseGlobalEvents = {
  fullScreenChangeRef: Ref<HTMLElement | null>;
  hasUserInteractedRef: Ref<boolean>;
  triggerUserInteraction: Function;
};

type GlobalEventsStorage = {
  composable: UseGlobalEvents;
};

function getFullScreenElement(): HTMLElement | null {
  // @ts-ignore webkitFullscreenElement does not exist on HTMLElement
  return document.fullscreenElement || document.webkitFullscreenElement || null;
}

if (isClient && 'ontouchstart' in document.documentElement) {
  document.body.addEventListener(
    'touchstart',
    function touchStart() {
      // FIX because By default Safari on iOS does not use the :active state
      // unless there is a touchstart event handler on the relevant element or on the <body> element
    },
    getPassiveEventOption(),
  );
}

export default function useMopGlobalEventsClient(): UseGlobalEvents {
  const storage = initStorage<GlobalEventsStorage>('useGlobalEventsClient');
  const storageResult = storage.get('composable');
  if (storageResult !== null) {
    return storageResult;
  }

  const fullScreenChangeRef = ref<HTMLElement | null>(null);
  const hasUserInteractedRef = ref(false);

  function handleFullScreenChange() {
    fullScreenChangeRef.value = getFullScreenElement();
  }

  function handleAbTestTrackingEvent(event: CustomEvent) {
    const { id = 'N/A', name = 'N/A', variationId = 'N/A', variationName = 'N/A' } = event.detail;
    const category = `${id}: ${name}`;
    const label = `${variationId}: ${variationName}`;

    useNuxtApp().$gtm2.reportLegacyEngagement({
      event: 'abTasty',
      category,
      label,
    });
  }

  let userInteractionTimer: any = null;

  if (isClient) {
    // Events triggeres by ab tasty ab tests
    // @ts-ignore
    window.addEventListener('ab-test-trigger', handleAbTestTrackingEvent, false);

    document.addEventListener('fullscreenchange', handleFullScreenChange, false);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange, false);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange, false);

    document.addEventListener('mousemove', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);
    document.addEventListener('click', handleUserInteraction);
    userInteractionTimer = setTimeout(() => {
      handleUserInteraction();
    }, useNuxtApp().$mopConfig.getGtmDelay());
  }

  function handleUserInteraction() {
    if (hasUserInteractedRef.value) {
      return;
    }
    clearTimeout(userInteractionTimer);
    document.removeEventListener('mousemove', handleUserInteraction);
    document.removeEventListener('touchstart', handleUserInteraction);
    document.removeEventListener('click', handleUserInteraction);
    hasUserInteractedRef.value = true;

    // settimeout to avoid side effects - make sure, that first client side hydration has finished
    setTimeout(() => {
      // @ts-ignore
      window.__NUXT__.isHydrationDone = true;

      if (typeof console !== 'undefined' && console) {
        // eslint-disable-next-line no-console
        console.log('We are hiring ;-)');
      }
    }, 500);
  }

  return storage.saveAndGet('composable', {
    fullScreenChangeRef,
    hasUserInteractedRef,
    triggerUserInteraction: handleUserInteraction,
  });
}
