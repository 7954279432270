<script setup lang="ts">
import { computed } from 'vue';
import type { PropType } from 'vue';
import type { UiNotificationTypes, UiNotificationStatuses } from '@mop/ui2/types';

defineOptions({
  name: 'Ui2Notification',
});

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String as PropType<UiNotificationTypes>,
    default: 'primary',
  },
  status: {
    type: String as PropType<UiNotificationStatuses>,
    default: 'default',
  },
  title: {
    type: String,
    default: '',
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  showCloseButton: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const iconSize = computed<number>(() => (props.type === 'inline' ? 16 : 20));
</script>

<template>
  <div
    v-if="!showCloseButton || modelValue"
    :class="['ui-notification', `ui-notification--${type}`, `ui-notification--${status}`]"
  >
    <div v-if="showIcon" class="ui-notification__icon-wrap">
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--error"
        name="alert-circle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--warning"
        name="alert-triangle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--success"
        name="check-circle"
        :width="iconSize"
        :height="iconSize"
      />
      <Ui2Icon
        class="ui-notification__icon ui-notification__icon--default"
        name="info-circle"
        :width="iconSize"
        :height="iconSize"
      />
    </div>
    <div class="ui-notification__content-wrapper">
      <div v-if="title" class="ui-notification__title">
        {{ title }}
      </div>
      <div v-if="$slots.default" class="ui-notification__content">
        <slot />
      </div>
    </div>
    <div v-if="showCloseButton" class="ui-notification__close">
      <Ui2Button type="close" @click="emit('update:modelValue', false)" />
    </div>
  </div>
</template>

<style lang="scss">
.ui-notification {
  --ui2-notification-background-color: var(--color-surface-component-alert-neutral);
  --ui2-notification-border-color: var(--color-border-secondary);
  --ui2-notification-text-color: var(--color-text-body-secondary);

  display: flex;
  position: relative;
  gap: $space-16;
  padding: $space-16 $space-48 $space-16 $space-16;
}

.ui-notification--info {
  --ui2-notification-background-color: var(--color-surface-component-alert-information);
  --ui2-notification-border-color: var(--color-border-information);
  --ui2-notification-text-color: var(--color-text-information);
}

.ui-notification--error {
  --ui2-notification-background-color: var(--color-surface-component-alert-error);
  --ui2-notification-border-color: var(--color-border-error);
  --ui2-notification-text-color: var(--color-text-error);
}

.ui-notification--warning {
  --ui2-notification-background-color: var(--color-surface-component-alert-warning);
  --ui2-notification-border-color: var(--color-border-warning);
  --ui2-notification-text-color: var(--color-text-warning);
}

.ui-notification--success {
  --ui2-notification-background-color: var(--color-surface-component-alert-success);
  --ui2-notification-border-color: var(--color-border-success);
  --ui2-notification-text-color: var(--color-text-success);
}

.ui-notification__icon {
  display: none;
}

.ui-notification__icon-wrap {
  color: var(--ui2-notification-text-color);
}

.ui-notification__content-wrapper {
  display: flex;
  flex-direction: column;
  gap: $space-4;
  flex: 1;
}

.ui-notification__title {
  @include v2-text-style(md, highlight);
  color: $color-text-headings;
}

.ui-notification__content {
  @include v2-text-style(sm, regular);
  color: $color-text-body-primary;
}

.ui-notification__close {
  position: absolute;
  top: $space-12;
  right: $space-12;
  color: $color-text-action;
}

.ui-notification--error {
  .ui-notification__icon--error {
    display: block;
  }
}

.ui-notification--success {
  .ui-notification__icon--success {
    display: block;
  }
}
.ui-notification--warning {
  .ui-notification__icon--warning {
    display: block;
  }
}
.ui-notification--default,
.ui-notification--info {
  .ui-notification__icon--default {
    display: block;
  }
}

.ui-notification--primary,
.ui-notification--secondary {
  border-radius: $border-radius-rounded-lg;
  border: 1px solid transparent;
  border-color: var(--ui2-notification-border-color);
}

.ui-notification--primary {
  background-color: var(--ui2-notification-background-color);
}

.ui-notification--secondary {
  background-color: $color-surface-primary;
}

.ui-notification--inline {
  padding: 0;
  gap: $space-8;

  .ui-notification__icon-wrap {
    padding-top: $space-2;
  }

  .ui-notification__title,
  .ui-notification__content {
    color: var(--ui2-notification-text-color);
  }

  .ui-notification__close {
    top: calc(-1 * $space-4);
    right: 0;
  }
}
</style>
