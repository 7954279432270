import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type {
  BffApiAuthAnonymousPost,
  BffApiAuthLoginPost,
  BffApiAuthLogoutPost,
  BffApiAuthPasswordChangePost,
  BffApiAuthPasswordResetPost,
  BffApiAuthPasswordResetTokenPost,
  BffApiAuthRefreshPost,
  BffApiAuthRegisterEmailCheckPost,
  BffApiAuthRegisterPost,
  BffCustomerRegisterPayload,
} from '@/types/bff';
const API_NAME = 'AWS - BFF Auth';
const REGISTER_CUSTOMER_TIMEOUT = 60000;

export function login(
  apiConfig: ApiBffConfig,
  params: { email: string; password: string },
): Promise<ApiResponse<BffApiAuthLoginPost>> {
  const url = getBffUrlWithParams('/auth/login', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthLoginPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'login',
      url,
    }),
  }));
}

export function logout(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAuthLogoutPost>> {
  const url = getBffUrlWithParams('/auth/logout', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthLogoutPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, undefined, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'logout',
      url,
    }),
  }));
}

export function refreshToken(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAuthRefreshPost>> {
  const url = getBffUrlWithParams('/auth/refresh', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthRefreshPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, undefined, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'refreshToken',
      url,
    }),
  }));
}

export function registerEmailCheck(
  apiConfig: ApiBffConfig,
  params: { email: string },
): Promise<ApiResponse<BffApiAuthRegisterEmailCheckPost>> {
  const url = getBffUrlWithParams('/auth/register-email-check', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthRegisterEmailCheckPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'registerEmailCheck',
      url,
    }),
  }));
}

export function updatePassword(
  apiConfig: ApiBffConfig,
  params: { email: string; currentPassword: string; newPassword: string },
): Promise<ApiResponse<BffApiAuthPasswordChangePost>> {
  const url = getBffUrlWithParams('/auth/password-change', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthPasswordChangePost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updatePassword',
      url,
    }),
  }));
}

export function register(
  apiConfig: ApiBffConfig,
  params: BffCustomerRegisterPayload,
  skipAddressCheck = false,
): Promise<ApiResponse<BffApiAuthRegisterPost>> {
  let path = `/auth/register`;
  if (skipAddressCheck) {
    path = `${path}?skipAddressCheck=true`;
  }
  const url = getBffUrlWithParams(path, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthRegisterPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
        timeout: REGISTER_CUSTOMER_TIMEOUT,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'register',
      url,
    }),
  }));
}

// TODO: we can remove locale param if apiConfig will be implemented (like on CT api client)
export function passwordResetToken(
  apiConfig: ApiBffConfig,
  params: { email: string; locale: string },
): Promise<ApiResponse<BffApiAuthPasswordResetTokenPost>> {
  const url = getBffUrlWithParams('/auth/password-reset-token', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthPasswordResetTokenPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'passwordResetToken',
      url,
    }),
  }));
}

export function passwordReset(
  apiConfig: ApiBffConfig,
  params: { token: string; newPassword: string },
): Promise<ApiResponse<BffApiAuthPasswordResetPost>> {
  const url = getBffUrlWithParams('/auth/password-reset', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthPasswordResetPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'passwordReset',
      url,
    }),
  }));
}

export function createAnonymousSession(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAuthAnonymousPost>> {
  const url = getBffUrlWithParams('/auth/anonymous', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthAnonymousPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, null, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createAnonymousSession',
      url,
    }),
  }));
}
