import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import type {
  CmsStoryModel,
  CmsStoryParams,
  CmsStoriesParams,
  CmsStoryResponseData,
  CmsStoryListResponseData,
  CmsStoryListModel,
  CmsStoryConfigName,
} from '@/types/cms';
import { cmsStoryModel, cmsStoryListModel } from '@/models';
import { ssrCache } from '@/api/utils/ssrCache';
import type { ComposablesStorage } from '@/utils/composablesStorage';

type CmsGlobalComposableStorage = {
  cmsGlobalStoryListModelRef: Ref<CmsStoryListModel>;
  cmsRedirectModelRef: Ref<CmsStoryModel>;
};

type CmsComposableStorage = {
  [key: string]: any;
};

type LoadingState = {
  getStory: boolean;
  getStories: boolean;
  getCmsStoryByConfigId: boolean;
  loading: boolean;
};

export function useMopCmsGlobalComponents() {
  const nuxtApp = useNuxtApp();
  const storage = initStorage<CmsGlobalComposableStorage>('useCmsGlobalComponents');
  const CACHE_GLOBAL = `${nuxtApp.$mopI18n.locale}-${constants.GLOBAL_CACHE_VALUE_KEY}`;
  const CACHE_REDIRECT = `${nuxtApp.$mopI18n.locale}-redirects`;
  const globalResponseRef = useMopSSR<CmsStoryListResponseData | null>(CACHE_GLOBAL, null);
  const redirectsResponseRef = ref<CmsStoryResponseData | null>(null);
  const redisCache = ssrCache();
  const { $apiCms } = nuxtApp;

  const cmsGlobalStoryListModelRef: Ref<CmsStoryListModel> =
    storage.get('cmsGlobalStoryListModelRef') ??
    storage.saveAndGet('cmsGlobalStoryListModelRef', ref(cmsStoryListModel(globalResponseRef.value)));

  const cmsRedirectModelRef: Ref<CmsStoryModel> =
    storage.get('cmsRedirectModelRef') ??
    storage.saveAndGet('cmsRedirectModelRef', ref(cmsStoryModel(redirectsResponseRef.value)));

  /**
   * Called on appInit.
   * Do not init elsewhere.
   */
  async function initGlobalComponents() {
    // Also use cached version for storyblok preview to speed up page load
    globalResponseRef.value ??= await redisCache.get(CACHE_GLOBAL, () =>
      $apiCms.getGlobalStories('global', {
        resolve_relations: 'categories',
      }),
    );
    cmsGlobalStoryListModelRef.value = cmsStoryListModel(globalResponseRef.value);
  }

  /**
   * Called on appInit.
   * Do not init elsewhere.
   */
  async function initRedirects() {
    // Also use cached version for storyblok preview to speed up page load
    redirectsResponseRef.value ??= await redisCache.get(CACHE_REDIRECT, () => $apiCms.getStory('redirects', {}, true));
    cmsRedirectModelRef.value = cmsStoryModel(redirectsResponseRef.value);
  }

  return securedWrap({
    cmsGlobalStoryListModelRef,
    cmsRedirectModelRef,
    initGlobalComponents,
    initRedirects,
  });
}

export function useMopCms(ssrCacheId?: string, useClientComposableStorage = false) {
  const nuxtApp = useNuxtApp();
  let storage: ComposablesStorage<CmsComposableStorage> | null = null;
  if (useClientComposableStorage && isClient && ssrCacheId) {
    storage = initStorage<CmsComposableStorage>('useCms');
  }
  const { getCachedResponse } = useClientCache();
  const CACHE_STORY = `story-${ssrCacheId}`;
  const CACHE_STORY_LIST = `story-list-${ssrCacheId}`;
  const storyResponseRef: Ref<CmsStoryResponseData | null> = useMopSSR<CmsStoryResponseData | null>(CACHE_STORY, null);
  const storyListResponseRef: Ref<CmsStoryListResponseData | null> = useMopSSR<CmsStoryListResponseData | null>(
    CACHE_STORY_LIST,
    null,
  );

  const cmsStoryModelRef: Ref<CmsStoryModel> = storage
    ? storage.get(`cmsStoryModelRef-${ssrCacheId}`) ??
      storage.saveAndGet(`cmsStoryModelRef-${ssrCacheId}`, ref(cmsStoryModel(storyResponseRef.value)))
    : ref(cmsStoryModel(storyResponseRef.value));

  const cmsStoryListModelRef: Ref<CmsStoryListModel> | Ref<null> = storage
    ? storage.get(`cmsStoryListModelRef-${ssrCacheId}`) ??
      storage.saveAndGet(`cmsStoryListModelRef-${ssrCacheId}`, ref(cmsStoryListModel(storyListResponseRef.value)))
    : ref(cmsStoryListModel(storyListResponseRef.value));

  const loadingRef: Ref<LoadingState> = ref({
    getStory: false,
    getStories: false,
    getCmsStoryByConfigId: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const { $apiCms } = nuxtApp;

  async function getCmsStory(slug: string, params: CmsStoryParams = {}) {
    if (cmsStoryModelRef.value?.isInitialized()) {
      return;
    }
    loadingRef.value.getStory = true;
    if (!ssrCacheId) {
      storyResponseRef.value = null;
    }
    storyResponseRef.value ??= await $apiCms.getStory(slug, params);
    cmsStoryModelRef.value = cmsStoryModel(storyResponseRef.value);
    loadingRef.value.getStory = false;
  }

  async function getCmsStoryByUuid(uuid: string, params: CmsStoryParams = {}) {
    if (cmsStoryModelRef.value?.isInitialized()) {
      return;
    }
    loadingRef.value.getStory = true;
    params.find_by = 'uuid';
    if (!ssrCacheId) {
      storyResponseRef.value = null;
    }
    storyResponseRef.value ??= await $apiCms.getStory(uuid, params);
    cmsStoryModelRef.value = cmsStoryModel(storyResponseRef.value);
    loadingRef.value.getStory = false;
  }

  async function getCmsStoryByConfigId(
    configName: CmsStoryConfigName,
    configValue: unknown,
    cmsParams: CmsStoriesParams = {},
    cacheClienResponse: boolean = false,
  ) {
    if (cmsStoryModelRef.value?.isInitialized()) {
      return;
    }
    loadingRef.value.getCmsStoryByConfigId = true;
    const params: CmsStoriesParams = {
      filter_query: {
        [configName]: {
          in: configValue,
        },
      },
      ...cmsParams,
    };
    if (!ssrCacheId) {
      storyListResponseRef.value = null;
    }
    if (cacheClienResponse) {
      storyListResponseRef.value ??= await getCachedResponse(params, () => $apiCms.getStories(undefined, params));
    } else {
      storyListResponseRef.value ??= await $apiCms.getStories(undefined, params);
    }
    const storyList: CmsStoryListModel = cmsStoryListModel(storyListResponseRef.value);

    if (!storyList.isInitialized() || storyList.hasError()) {
      loadingRef.value.getCmsStoryByConfigId = false;
      return;
    }
    storyResponseRef.value = storyList.getStoryModelList()?.[0]?.getResponse() || {
      error: {
        code: constants.ERROR_CODE.NOT_FOUND,
      },
    };
    cmsStoryModelRef.value = cmsStoryModel(storyResponseRef.value);
    loadingRef.value.getCmsStoryByConfigId = false;
  }

  async function getCmsStoryList(slug?: string, params: CmsStoriesParams = {}) {
    if (cmsStoryListModelRef.value?.isInitialized()) {
      return;
    }
    loadingRef.value.getStories = true;
    if (!ssrCacheId) {
      storyListResponseRef.value = null;
    }
    storyListResponseRef.value ??= await $apiCms.getStories(slug, params);
    cmsStoryListModelRef.value = cmsStoryListModel(storyListResponseRef.value);
    loadingRef.value.getStories = false;
  }

  return securedWrap({
    getCmsStory,
    getCmsStoryByUuid,
    getCmsStoryList,
    getCmsStoryByConfigId,
    cmsStoryModelRef,
    cmsStoryListModelRef,
    loadingRef,
  });
}
