import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { CategoryModel } from '@/types/category';

const NAVIGATION_OPEN_DELAY = 200;

type NavigationComposableStorage = {
  openTimerRef: Ref<any>;
  openedSubNavigationsRef: Ref<string[]>;
};

export default function useMopNavigationClient() {
  const storage = initStorage<NavigationComposableStorage>('useNavigationClient');
  const { getCategoriesInPath } = useMopCategoryTree();

  const openedSubNavigationsRef =
    storage.get('openedSubNavigationsRef') ?? storage.saveAndGet('openedSubNavigationsRef', ref([]));

  const openTimerRef = storage.get('openTimerRef') ?? storage.saveAndGet('openTimerRef', ref());

  function openSubNavigation(category: CategoryModel, callback?: () => void) {
    stopOpening();
    openTimerRef.value = setTimeout(() => {
      openedSubNavigationsRef.value = getCategoriesInPath(category.getPath()).map((category) => category.getMopId());
      callback && callback();
    }, NAVIGATION_OPEN_DELAY);
  }

  function stopOpening() {
    clearTimeout(openTimerRef.value);
  }

  function isSubNavigationOpen(mopCategoryId: string) {
    return openedSubNavigationsRef.value.includes(mopCategoryId);
  }

  function isOpenAtLevel(level: number) {
    return Boolean(openedSubNavigationsRef.value[level]);
  }

  function hasOpenSubNavigation() {
    return openedSubNavigationsRef.value.length > 0;
  }

  function closeAll() {
    openedSubNavigationsRef.value = [];
  }

  return securedWrap({
    openSubNavigation,
    stopOpening,
    closeAll,
    isSubNavigationOpen,
    isOpenAtLevel,
    hasOpenSubNavigation,
  });
}
