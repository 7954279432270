import type { AxiosError } from 'axios';
import type { ApiResponse, ApiBffConfig } from '@/types/apiInit';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { BffSupportMailPostRequest, BffSupportMailPostResponse } from '@/types/bff';

const API_NAME = 'AWS - SUPPORT MAIL';

export function createSupportEmail(
  apiConfig: ApiBffConfig,
  params: BffSupportMailPostRequest,
): Promise<ApiResponse<BffSupportMailPostResponse>> {
  const url = getBffUrlWithParams('/support-mail', apiConfig);
  return bffErrorHandler<ApiResponse<BffSupportMailPostResponse>>(async () => ({
    data: (await apiConfig.apiClient.post(url, params)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createSupportEmail',
      url,
    }),
  }));
}
