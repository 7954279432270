import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type {
  BffApiBonusVoucherStepsGetResponse,
  BffApiAccountMeDeleteRequest,
  BffApiAccountMeDeleteResponse,
  BffApiAccountMeGet,
  BffApiAccountMePut,
  BffCustomerUpdateAttributes,
} from '@/types/bff';

const API_NAME = 'AWS - BFF ME';

export function getMe(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAccountMeGet>> {
  const url = getBffUrlWithParams('/account/me', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountMeGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getMe',
      url,
    }),
  }));
}

export function deleteCustomer(
  apiConfig: ApiBffConfig,
  feedback: BffApiAccountMeDeleteRequest,
): Promise<ApiResponse<BffApiAccountMeDeleteResponse>> {
  const url = getBffUrlWithParams('/account/me', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountMeDeleteResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.delete(url, {
        data: feedback,
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'deleteCustomer',
      url,
    }),
  }));
}

export function updateCustomerData(
  apiConfig: ApiBffConfig,
  customerData: BffCustomerUpdateAttributes,
): Promise<ApiResponse<BffApiAccountMePut>> {
  const url = getBffUrlWithParams('/account/me', apiConfig);
  return bffErrorHandler<any>(async () => ({
    data: (
      await apiConfig.apiClient.put(url, customerData, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateCustomerData',
      url,
    }),
  }));
}

export function getBonusVoucherSteps(
  apiConfig: ApiBffConfig,
): Promise<ApiResponse<BffApiBonusVoucherStepsGetResponse>> {
  const url = getBffUrlWithParams(`/bonus-voucher-steps/${apiConfig.country}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiBonusVoucherStepsGetResponse>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getBonusVoucherSteps',
      url,
    }),
  }));
}
