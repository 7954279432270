<script setup lang="ts">
import { ref } from 'vue';
import type { DeviceType, UiImageSettingsType, UiImageSettingsTypeKey, CmsImage } from '@mop/cms/types';
import { isCssVariablesSupported } from '@mop/shared/utils/util';
import imageSettings from '@mop/cms/imageSettings';
import { getCmsImageUrlBySize } from '@mop/ui2/utils/utils';

const maxMobileWidth = 767;
const DESKTOP = 'desktop';
const MOBILE = 'mobile';

defineOptions({
  name: 'Ui2Image',
});

const props = defineProps({
  image: {
    type: Object,
    default: null,
  },
  imageMobile: {
    type: Object,
    default: null,
  },
  src: {
    type: String,
    default: '',
  },
  config: {
    type: Object,
    default: null,
  },
  type: {
    type: String as PropType<UiImageSettingsTypeKey>,
    default: 'default',
  },
  alt: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    default: null,
  },
  lazy: {
    type: Boolean,
    default: false,
  },
  noBackground: {
    type: Boolean,
    default: false,
  },
  cover: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['loaded']);
const image: CmsImage | null = props.image as CmsImage;
const imageMobile: CmsImage | null = props.imageMobile as CmsImage;
// @ts-ignore
const typeConfig: UiImageSettingsType =
  // @ts-ignore
  props.config || imageSettings.types[props.type] || imageSettings.types.default;
const imgType: UiImageSettingsTypeKey | 'custom' = props.config ? 'custom' : props.type;
const isCmsImage: boolean = image !== null;
const renderEmptyImage: boolean = props.lazy;

const aspectRatioNames: string[] = [];
if (
  typeConfig.desktop.aspectRatio &&
  (!typeConfig.mobile?.aspectRatio || typeConfig.desktop.aspectRatio === typeConfig.mobile.aspectRatio)
) {
  typeConfig.desktop.aspectRatio && aspectRatioNames.push(typeConfig.desktop.aspectRatio as string);
} else {
  typeConfig.desktop.aspectRatio && aspectRatioNames.push(`desktop-${String(typeConfig.desktop.aspectRatio)}`);
  typeConfig.mobile?.aspectRatio && aspectRatioNames.push(`mobile-${String(typeConfig.mobile.aspectRatio)}`);
}
let srcSetWidths: number[] = typeConfig.desktop.srcSetWidths;
const srcSetWidthsMobile: number[] = typeConfig.mobile?.srcSetWidths || [];
// If there is no mobile image, use mobile widths for desktop aswell
if (!imageMobile?.url && srcSetWidthsMobile.length) {
  srcSetWidths = [...new Set(srcSetWidths.concat(srcSetWidthsMobile))];
  srcSetWidths.sort((a, b) => a - b);
}

const srcState: Ref = ref(getImageSources(renderEmptyImage));
let calculatedAspectRatios: Record<string, string> | undefined;
let imageObjectPositions: Record<string, string> | undefined;

if (isCmsImage && isCssVariablesSupported) {
  const shouldCalculateAspectRatio: boolean =
    !aspectRatioNames.length && (Boolean(image.aspectRatioWidth) || Boolean(imageMobile?.aspectRatioWidth));
  if (shouldCalculateAspectRatio && image.aspectRatioHeight && image.aspectRatioWidth) {
    const paddingTop = `${(image.aspectRatioHeight / image.aspectRatioWidth) * 100}%`;
    let paddingTopMobile: string = paddingTop;
    if (imageMobile?.aspectRatioWidth && imageMobile?.aspectRatioHeight) {
      paddingTopMobile = `${(imageMobile.aspectRatioHeight / imageMobile.aspectRatioWidth) * 100}%`;
    }
    calculatedAspectRatios = {
      '--aspect-ratio': `${paddingTop}`,
      '--aspect-ratio-mobile': `${paddingTopMobile}`,
    };
  }

  if (image.focusPoint || imageMobile?.focusPoint) {
    const objectPosition: string = image?.focusPoint
      ? `${image.focusPoint.xPercent}% ${image.focusPoint.yPercent}%`
      : '';
    let objectPositionMobile: string = objectPosition;
    if (imageMobile?.focusPoint) {
      objectPositionMobile = `${imageMobile.focusPoint.xPercent}% ${imageMobile.focusPoint.yPercent}%`;
    }
    imageObjectPositions = {
      '--object-position': `${objectPosition}`,
      '--object-position-mobile': `${objectPositionMobile}`,
    };
  }
}

function getImageSources(renderEmptyImage = false) {
  if (isCmsImage) {
    return getCmsImageSources(renderEmptyImage);
  } else {
    return getProductImageSources(renderEmptyImage);
  }
}

function getCmsImageSources(renderEmptyImage = false) {
  const getSrc = (viewport: DeviceType) => {
    const cmsImage: CmsImage | null = viewport === DESKTOP ? image : imageMobile;
    if (!cmsImage?.url || !typeConfig[viewport]) {
      return null;
    }
    return getCmsImageUrlBySize(cmsImage.url, String(getDefaultWidth(viewport)));
  };
  const getSrcSet = (viewport: DeviceType) => {
    const cmsImage: CmsImage | null = viewport === DESKTOP ? image : imageMobile;
    const widths = viewport === DESKTOP ? srcSetWidths : srcSetWidthsMobile;
    if (!cmsImage?.url || !widths?.length || (viewport === DESKTOP && !imageMobile && widths.length <= 1)) {
      return null;
    }
    return widths
      .map((srcSetWidth) => `${getCmsImageUrlBySize(cmsImage.url, String(srcSetWidth))} ${srcSetWidth}w`)
      .join(', ');
  };

  return {
    src: !renderEmptyImage ? getSrc(DESKTOP) : null,
    srcSet: !renderEmptyImage ? getSrcSet(DESKTOP) : null,
    srcMobile: !renderEmptyImage ? getSrc(MOBILE) : null,
    srcSetMobile: !renderEmptyImage ? getSrcSet(MOBILE) : null,
  };
}

function getProductImageSources(renderEmptyImage = false) {
  if (renderEmptyImage || !props.src) {
    return {
      src: null,
      srcSet: null,
    };
  }

  const src = `${props.src}?width=${getDefaultWidth(DESKTOP)}&quality=70`;
  const srcSet = srcSetWidths
    .map((srcSetWidth) => {
      return `${props.src}?width=${srcSetWidth}&quality=70 ${srcSetWidth}w`;
    })
    .join(', ');

  return {
    src,
    srcSet,
  };
}

function getDefaultWidth(viewport: DeviceType) {
  const widths = viewport === DESKTOP ? srcSetWidths : srcSetWidthsMobile;
  if (widths.length === 1) {
    return widths[0];
  }
  // Use the size-in-the-middle for desktop and the size-in-the-middle - 1 for mobile
  return widths[Math.ceil(widths.length / 2) - Number(viewport === MOBILE)];
}

function visibilityChangedHandler(isVisible: boolean) {
  if (!isVisible) {
    return;
  }
  srcState.value = getImageSources();
}

function getSizes(viewport: DeviceType): string | undefined {
  let sizes: Record<string, string>[] = typeConfig.sizes;
  if (!sizes?.length) {
    return '100vw';
  }
  if (viewport === 'mobile') {
    sizes = sizes.filter((size) => Number(Object.keys(size)[0]) <= maxMobileWidth);
  } else if (imageMobile?.url && viewport === DESKTOP) {
    sizes = sizes.filter((size) => Number(Object.keys(size)[0]) > maxMobileWidth);
  }
  if (sizes.length === 1) {
    return Object.keys(sizes[0])
      .map((key) => sizes[0][key])
      .join(',');
  }
  let lastWidthValue = 0;
  return sizes
    .map((size, i) =>
      Object.keys(size).map((key) => {
        const width = parseInt(key);
        const value = size[key];
        let sizesItem = '';
        if (sizes.length !== i + 1) {
          sizesItem = `(max-width: ${width}px) `;
          if (lastWidthValue) {
            sizesItem += `and (min-width: ${lastWidthValue + 1}px) `;
          }
        }
        sizesItem += `${value}`;
        lastWidthValue = width;
        return sizesItem;
      }),
    )
    .join(',');
}

const sizes = getSizes(DESKTOP);
const sizesMobile = isCmsImage && imageMobile?.url ? getSizes(MOBILE) : undefined;
const imgAlt = image?.alt || image?.title || props.alt || props.title;
const imgTitle = image?.title || props.title;
</script>

<template>
  <div
    v-observe-visibility:once="lazy ? visibilityChangedHandler : undefined"
    :class="[
      'img-wrapper',
      {
        'img-wrapper--cover': cover,
      },
    ]"
  >
    <div
      :class="[
        'img',
        lazy ? 'img--lazy' : 'img--not-lazy',
        aspectRatioNames.map((aspectRatioName) => `img--${aspectRatioName}`),
        aspectRatioNames.length > 0 || calculatedAspectRatios ? 'img--with-placeholder' : 'img--no-placeholder',
        {
          'img--calculated-aspect-ratio': calculatedAspectRatios,
          'img--no-bg': noBackground,
        },
      ]"
      :style="calculatedAspectRatios"
    >
      <picture v-if="srcState.src && srcState.srcMobile">
        <source
          v-if="srcState.srcMobile"
          :media="`(max-width: ${maxMobileWidth}px)`"
          :srcset="srcState.srcSetMobile"
          :sizes="sizesMobile"
        />
        <source
          v-if="srcState.srcSet"
          :media="srcState.srcMobile ? `(min-width: ${maxMobileWidth + 1}px)` : undefined"
          :srcset="srcState.srcSet"
          :sizes="sizes"
        />
        <!-- width and height to satisfy google lighthouse, will be overwritten by css anyway -->
        <img
          v-image-src="srcState.src"
          class="image"
          :style="imageObjectPositions"
          :alt="imgAlt || imgTitle || ''"
          :title="imgTitle"
          :data-type="imgType"
          width="100%"
          height="100%"
          :loading="lazy ? 'lazy' : 'eager'"
          @load="emit('loaded', $event)"
        />
      </picture>
      <!-- Only 1 image for desktop and mobile - render img with lazy loading -->
      <img
        v-else-if="srcState.src"
        class="image"
        :style="imageObjectPositions"
        :sizes="sizes"
        :srcset="srcState.srcSet"
        :src="srcState.src"
        :alt="imgAlt || imgTitle || ''"
        :title="imgTitle"
        :data-type="imgType"
        width="100%"
        height="100%"
        @load="emit('loaded', $event)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.img-wrapper {
  display: block;
  height: 100%;
}

.img {
  position: relative;
  background-color: $color-surface-secondary;
  height: 100%;
}

.img--calculated-aspect-ratio {
  padding-top: var(--aspect-ratio);

  @include v2-apply-upto(mobile) {
    padding-top: var(--aspect-ratio-mobile);
  }
}

.img--no-bg {
  background-color: transparent;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  transform: translateZ(0);
  object-position: var(--object-position, center top);

  @include apply-only-safari {
    image-rendering: auto;
  }

  @include v2-apply-upto(mobile) {
    object-position: var(--object-position-mobile, center top);
  }
}

.img--with-placeholder {
  .image {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.img-wrapper--cover {
  .image {
    object-fit: cover;
  }
}
</style>
