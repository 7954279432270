import type { ApiResponse } from '@/types/apiInit';
import type { BffApiTokenGet } from '@/types/bff';

export function commercetoolsTokenModel(responseData: ApiResponse<BffApiTokenGet> | null) {
  const response = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    getAccessToken(): string {
      return response?.data?.accessToken ?? '';
    },

    getExpiration(): number {
      return Date.now() + (response?.data?.expiresIn ?? 0) * 1000;
    },
  };
}
