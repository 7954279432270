import { securedWrap } from '@mop/shared/utils/securedWrap';
import type {
  ClientResponse,
  ProductPagedSearchResponse,
  ProductProjectionPagedQueryResponse,
  SearchQuery,
} from '@commercetools/platform-sdk';
import { productListModel } from '@/models/productModel';
import { paginationModel } from '@/models';

import type {
  ProductModelListRef,
  PaginationModelRef,
  ProductModel,
  ProductProjectionSearchParams,
  ProductSearchParams,
} from '@/types/product';
import { getProductProjectionParameters } from '@/models/utils/productUtils';

type LoadingProductsState = {
  searchProductsWithFuzzyFallback: boolean;
  loading: boolean;
};

export default function useMopProductSearch() {
  const { $mopI18n, $apiCommercetools } = useNuxtApp();
  const { customerModelRef } = useMopCustomer();
  const {
    productModelListRef: productModelListByIdsRef,
    paginationModelRef: paginationModelByIdsRef,
    searchProductsByMopProductIds,
    getChannelsQuery,
  } = useMopProducts();
  const loadingProductsRef: Ref<LoadingProductsState> = ref({
    searchProductsWithFuzzyFallback: false,
    loading: computed(() => isLoading(loadingProductsRef)),
  });

  const productSearchResponseRef = ref<ClientResponse<ProductPagedSearchResponse> | null>(null);
  const fuzzyProductSearchResponseRef = ref<ClientResponse<ProductProjectionPagedQueryResponse> | null>(null);
  const productModelListRef: ProductModelListRef = ref(productListModel(null));
  const paginationModelRef: PaginationModelRef = ref(paginationModel(null));
  const projectionProductModelListRef = ref(productListModel(null));
  const isFuzzySearchRef = ref(false);

  type SearchSuggestionSearchProducts = {
    searchTerm: string;
    limit?: number;
    page?: number;
    categoryId?: string;
    forceClientRefetch?: boolean;
  };

  async function searchProductsByWildcardOfTerms({
    searchTerm,
    limit,
    page,
    categoryId,
    forceClientRefetch = false,
  }: SearchSuggestionSearchProducts) {
    if (forceClientRefetch) {
      productSearchResponseRef.value = null;
    }
    const offset = ((page || 1) - 1) * (limit || 0);
    const searchTerms: SearchQuery[] = searchTerm.split(' ').map((keyword) => {
      const queryTerm = {
        language: $mopI18n.lang,
        value: `*${keyword}*`,
        caseInsensitive: true,
        mustMatch: 'any',
      };
      return {
        or: [
          {
            wildcard: {
              field: `key`,
              ...queryTerm,
              boost: 5,
            },
          },
          {
            fullText: {
              field: `variants.attributes.refinementColor.label`,
              fieldType: 'lenum',
              ...queryTerm,
              boost: 4,
            },
          },
          {
            fullText: {
              field: 'name',
              ...queryTerm,
              boost: 3.5,
            },
          },
          {
            wildcard: {
              field: 'name',
              ...queryTerm,
              boost: 3,
            },
          },
          {
            wildcard: {
              field: 'searchKeywords',
              ...queryTerm,
              boost: 2.5,
            },
          },
          {
            wildcard: {
              field: 'searchKeywords',
              ...queryTerm,
              boost: 2,
            },
          },
          {
            fullText: {
              field: 'variants.attributes.shortDescription',
              fieldType: 'ltext',
              ...queryTerm,
              boost: 1.5,
            },
          },
          {
            wildcard: {
              field: 'variants.attributes.shortDescription',
              fieldType: 'ltext',
              ...queryTerm,
              boost: 1,
            },
          },
        ],
      };
    });

    if (categoryId) {
      searchTerms.push({
        exact: {
          field: 'categoriesSubTree',
          value: categoryId,
        },
      });
    }

    searchTerms.push(getChannelsQuery());

    const searchParams: ProductSearchParams = {
      body: {
        query: {
          and: searchTerms,
        },
        limit,
        offset,
        productProjectionParameters: getProductProjectionParameters(
          $mopI18n,
          customerModelRef.value.getCommercetoolsCustomerGroupId(),
        ),
      },
    };

    productSearchResponseRef.value ??= await $apiCommercetools.searchProducts(searchParams);
    productModelListRef.value = productListModel(productSearchResponseRef.value);
    paginationModelRef.value = paginationModel(productSearchResponseRef.value);
  }

  async function searchProductsWithFuzzyFallback({
    searchTerm,
    limit,
    page,
    categoryId,
    forceClientRefetch = false,
  }: SearchSuggestionSearchProducts) {
    loadingProductsRef.value.searchProductsWithFuzzyFallback = true;

    const termKey = `text.${$mopI18n.lang}`;
    const offset = ((page || 1) - 1) * (limit || 0);
    const supplyChannelIds = WAREHOUSE_TYPES.filter(
      (warehouseType) => $mopI18n.country === 'de' || warehouseType === 'ARVATO',
    ).map((warehouseType) => getWarehouseId(warehouseType));

    const productsQuery: ProductProjectionSearchParams = {
      queryArgs: {
        storeProjection: $mopI18n.commercetoolsCountry.toUpperCase(),
        limit,
        offset,
        localeProjection: $mopI18n.lang,
        // fuzzyLevel: 0, https://docs.commercetools.com/api/projects/products-search#query-parameters
        fuzzy: true,
        [termKey]: searchTerm,
        filter: [`variants.availability.isOnStockInChannels:"${supplyChannelIds.join('","')}"`],
      },
    };

    if (categoryId) {
      (productsQuery.queryArgs!.filter! as string[]).push(`categories.id: subtree("${categoryId}")`);
    }

    // do partial search using *
    if (searchTerm?.includes('*')) {
      productsQuery.queryArgs!.fuzzy = false;
      productsQuery.queryArgs![termKey] = searchTerm.replace(/\*/gi, '');
    } else if (searchTerm && isProductId(searchTerm)) {
      productsQuery.queryArgs!.fuzzy = false;
    }

    if (forceClientRefetch) {
      fuzzyProductSearchResponseRef.value = null;
    }

    await Promise.allSettled([
      searchProductsByWildcardOfTerms({
        searchTerm,
        limit,
        page,
        categoryId,
        forceClientRefetch,
      }),
      (fuzzyProductSearchResponseRef.value ??= await $apiCommercetools.productProjectionSearch(productsQuery)),
    ]);

    const fuzzyResultCount = fuzzyProductSearchResponseRef.value?.body?.count || 0;
    const searchResultCount = productModelListRef.value.getProductModelList().length;

    isFuzzySearchRef.value = false;
    if (!searchResultCount && fuzzyResultCount) {
      // @ts-ignore
      projectionProductModelListRef.value = productListModel(fuzzyProductSearchResponseRef.value);

      const projectionSearchProducts = projectionProductModelListRef.value.getProductModelList();
      if (projectionSearchProducts.length > 0) {
        isFuzzySearchRef.value = true;
        await searchProductsByMopProductIds(
          projectionSearchProducts.map((product) => product.getMopId()),
          true,
        );
        productModelListRef.value = productModelListByIdsRef.value;
        paginationModelRef.value = paginationModelByIdsRef.value;
      }
    }

    loadingProductsRef.value.searchProductsWithFuzzyFallback = false;
  }

  function sortProductModelListByFuzzy(): ProductModel[] {
    // Use order from fuzzy, but product data from new search endpoint
    const productModelListSortedByFuzzy: ProductModel[] = [];
    const productModelList: ProductModel[] = productModelListRef.value.getProductModelList();
    projectionProductModelListRef.value.getProductModelList().forEach((fuzzyProduct) => {
      const product = productModelList.find((product) => product.getMopId() === fuzzyProduct.getMopId());
      if (product) {
        productModelListSortedByFuzzy.push(product);
      }
    });
    return productModelListSortedByFuzzy;
  }

  function isProductId(input: string): boolean {
    /*
    Covering cases such as:
      B0126542701103_790
      B0126542701103
      730285-102_464
      730285-102
      00126542701103_790
      000823102003_D01
      000823102003
    */
    return /^(\d|\w{1}\d|\d+-\d)+(_.+)?$/gi.test(input);
  }

  return securedWrap({
    productModelListRef,
    loadingProductsRef,
    paginationModelRef,
    isFuzzySearchRef,
    sortProductModelListByFuzzy,
    searchProductsWithFuzzyFallback,
  });
}
