import type { AxiosError } from 'axios';
import { handleAxiosError, errorHandler, getBffUrlWithParams } from '@/api/utils';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type { BffApiTokenGet } from '@/types/bff';
const API_NAME = 'AWS - BFF Token';

export function getToken(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiTokenGet>> {
  const url = getBffUrlWithParams('/token', apiConfig);
  // TODO: commercetools switch to bffErrorHandler, once "code" is returned from BFF
  return errorHandler<ApiResponse<BffApiTokenGet>>(async () => ({
    data: (await apiConfig.apiClient.get(url)).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getToken',
      url,
    }),
  }));
}
