import type { FilterValue, FilterValueModel } from '@/types/filters';

export function filterValueModel(valueResponseData: FilterValue, labels?: any) {
  return {
    getName(): string {
      return labels?.[this.getValue()] ?? valueResponseData.key ?? '';
    },
    getKey(): string {
      return valueResponseData.key ?? '';
    },
    getValue(): string {
      return convertToSlug(valueResponseData.key || '');
    },
    getMin(): number {
      return valueResponseData.min ?? 0;
    },
    getMax(): number {
      return valueResponseData.max ?? 0;
    },
    getMinPrice(): number {
      return this.getMin() / 100;
    },
    getMaxPrice(): number {
      return this.getMax() / 100;
    },
    isMaintained(): boolean {
      return labels?.[this.getValue()] !== undefined;
    },
    getDebugData(): any {
      return valueResponseData;
    },
  };
}

export function filterValuesModel(responseData: FilterValue[], labels?: any, fieldType?: 'ltext' | 'set' | 'lenum') {
  return responseData?.reduce((list: FilterValueModel[], value) => {
    const valueModel = filterValueModel(value, labels);
    if (['ltext', 'set', 'enum'].includes(fieldType as string) || valueModel.isMaintained()) {
      list.push(valueModel);
    }
    return list;
  }, []);
}
