import { isClient, loadScript } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { sessionStorageGet, sessionStorageRemove, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import type {
  Gtm2EcommerceParams,
  Gtm2EcommerceParamsProduct,
  Gtm2EcommerceItem,
  GtmProductListType,
  Gtm2EcommerceBaseEvent,
  Gtm2EcommerceEvent,
  Gtm2EcommerceEventType,
  Gtm2PageViewParams,
  Gtm2PromotionParams,
  Gtm2PromotionEvent,
  Gtm2Params,
  Gtm2Event,
  GtmProductTrackingParams,
  GtmPageViewEvent,
  GtmPageViewParams,
  GtmCheckoutEventParams,
  LegacyTrackingCartEventParams,
} from '@/types/gtm';
import type { CartLineItemModel } from '@/types/cart';
import type { ProductModel } from '@/types/product';

type TrackingPageViewParams = {
  gtm?: {
    data: GtmPageViewParams;
  };
};

const MAX_DATALAYER_BULK_SIZE = 25;
const DATA_NOT_AVAILABLE_VALUE = 'na';

export default defineNuxtPlugin((nuxtApp) => {
  const app = nuxtApp.vueApp.$nuxt;
  const { $mopI18n, $abTestServerSide, $mopConfig, $cookie } = app;
  if (!$mopI18n) {
    return;
  }

  let currentPage = '';

  const route = useRoute();
  const userIdFromGetParams = (route.query.sc_customer as string) || (route.query['contact-number'] as string) || '';
  if (userIdFromGetParams) {
    sessionStorageSet(constants.SESSION_STORAGE.USER_ID_FROM_GET_PARAMS, userIdFromGetParams);
  }

  const config = useRuntimeConfig();
  const { isConsentRequired } = useMopUsercentricsConsentClient();
  let isGtmScriptAdded = false;
  let datalayerBulk: Gtm2EcommerceBaseEvent[] = [];

  if (isEnabled()) {
    initGtm();
  }

  function initGtm() {
    window.dataLayer = window.dataLayer || [];
    window.addEventListener('beforeunload', commitDatalayer);
    window.addEventListener('blur', commitDatalayer);

    initVueRouterGuard();
  }

  function pushData(event: any, bulkOnly = false) {
    if (isGtmScriptAdded) {
      if (bulkOnly) {
        datalayerBulk.push(event);
      } else {
        window.dataLayer.push(event);
      }
    } else {
      datalayerBulk.push(event);
    }

    if (datalayerBulk.length >= MAX_DATALAYER_BULK_SIZE) {
      commitDatalayer();
    }
  }

  function isEnabled(): boolean {
    return (
      isClient &&
      config.public.GTM_ENABLE &&
      config.public.GTM_CONTAINER_ID !== '' &&
      !useRoute().query.disablegtm &&
      app.$storyblokLivePreview.isEnabled === false
    );
  }

  function initVueRouterGuard() {
    if (!isEnabled()) {
      return;
    }
    const router = useRouter();
    router.beforeEach((to) => {
      if (!to.fullPath) {
        return;
      }
      commitDatalayer();
    });
  }

  function pushEventToDataLayer(event: Gtm2Event) {
    if (!isEnabled()) {
      return;
    }
    pushData(event);
  }

  function pushEcommerceEventToDataLayer(event: Gtm2EcommerceBaseEvent, enableBundling = false) {
    if (!isEnabled()) {
      return;
    }
    pushData({ ecommerce: null });
    pushData(event, enableBundling);
  }

  function commitDatalayer() {
    if (!isEnabled() || isConsentRequired()) {
      return;
    }

    groupEvents();
    datalayerBulk.forEach((event) => {
      window.dataLayer.push(event);
    });
    datalayerBulk = [];
  }

  function groupEvents() {
    if (!isEnabled()) {
      return;
    }
    const allEvents: Gtm2EcommerceBaseEvent[] = [];
    const groupableEvents: Gtm2EcommerceEventType[] = ['view_item_list'];
    const groupedEventItems: { [key: string]: Gtm2EcommerceBaseEvent } = {};
    datalayerBulk.forEach((trackingEvent: Gtm2EcommerceBaseEvent) => {
      const event = trackingEvent.event;
      if (groupableEvents.includes(event)) {
        if (!groupedEventItems[event]) {
          groupedEventItems[event] = trackingEvent;
        } else if (trackingEvent.ecommerce.items?.length) {
          const newItem = trackingEvent.ecommerce.items.shift() as Gtm2EcommerceItem;
          groupedEventItems[event].ecommerce.items.push(newItem);
          groupedEventItems[event].ecommerce.value += getItemValue(newItem);
        }
        return;
      }
      allEvents.push(trackingEvent);
    });

    Object.keys(groupedEventItems).forEach((groupedEventItemKey) => {
      allEvents.push(groupedEventItems[groupedEventItemKey]);
    });

    datalayerBulk = allEvents;
  }

  function getCategoryPath(productTrackingParams: Gtm2EcommerceParamsProduct): string {
    let categoryPath = '';
    const product: ProductModel = productTrackingParams.product;

    if (!productTrackingParams.category) {
      categoryPath = [product.getBreadcrumbPath().split('-').join('/'), product.getMopId()].join('/');
    } else {
      const category: string = productTrackingParams.category;
      categoryPath = category.includes(product.getMopId()) ? category : [category, product.getMopId()].join('/');
    }
    return categoryPath.replace(/^\//, '');
  }

  function getGtmListTypeKey(product: ProductModel) {
    return `gtm_list_type_${product.getMopId()}`;
  }

  function getEcommerceItems(
    paramsProducts: Gtm2EcommerceParamsProduct[],
    event: Gtm2EcommerceEventType,
  ): Gtm2EcommerceItem[] {
    return paramsProducts.map((paramsProduct, index) => {
      const { product, variant } = paramsProduct;
      const priceInformation = variant ? variant.getPrice() : product.getPrice();

      const listTypeKey = getGtmListTypeKey(product);
      if (event === 'select_item' && paramsProduct.list) {
        sessionStorageSet(listTypeKey, paramsProduct.list);
      } else {
        paramsProduct.list = (sessionStorageGet(listTypeKey) as GtmProductListType) || paramsProduct.list;
      }

      const price = paramsProduct.price || (variant ? variant.getPrice().salePriceNet : priceInformation.salePriceNet);

      return {
        // GA4 default attributes
        item_id: product.getMopMasterId(),
        item_name: product.getName(),
        index,
        item_brand: product.getSimpleBrandName() || DATA_NOT_AVAILABLE_VALUE,
        item_category: getCategoryPath(paramsProduct) || DATA_NOT_AVAILABLE_VALUE,
        item_list_name: paramsProduct.list || DATA_NOT_AVAILABLE_VALUE,
        item_variant: product.getColorId() || DATA_NOT_AVAILABLE_VALUE,
        price: price || 0,
        quantity: paramsProduct.quantity || 1,

        // MOP Custom attributes
        product_id: product.getMopId(),
        product_variation_id: variant?.getSku() || DATA_NOT_AVAILABLE_VALUE,
        product_category: product.getPrimaryCategoryMopId() || DATA_NOT_AVAILABLE_VALUE,
        season: product.getSeason() || DATA_NOT_AVAILABLE_VALUE,
        gender: product.getGender() || DATA_NOT_AVAILABLE_VALUE,
        size: variant?.getSize() || DATA_NOT_AVAILABLE_VALUE,
        sustainablematerial: product.getSustainabilityId() || DATA_NOT_AVAILABLE_VALUE,
        sale: priceInformation.salePrice !== priceInformation.basePrice,
        salepercent: priceInformation.salePercentage ?? 0,
        saletotal: priceInformation.basePrice * ((priceInformation.salePercentage ?? 0) / 100),
        correctionGlasses: product.isCorrectionGlasses(),
        pdpVideo: Boolean(product.getVimeoId()),
        shipFromStoreProduct: variant?.getAvailability().isShipFromStore ?? DATA_NOT_AVAILABLE_VALUE,
        virtualTryOnPotential: product.isVirtualTryOnEnabled(),
        virtualTryOnDone: product.isProductTriedWithVirtualTryOn(),
        popularityFlag: product.getPopularityFlag($mopConfig)?.type || DATA_NOT_AVAILABLE_VALUE,
      } as Gtm2EcommerceItem;
    });
  }

  function trackPromotion(params: Gtm2PromotionParams) {
    const gtmEcommerceEvent: Gtm2PromotionEvent = {
      event: params.event,
      ecommerce: {
        creative_name: params.data.creativeName,
        creative_slot: params.data.creativeSlot,
        promotion_id: params.data.promotionId,
        promotion_name: params.data.promotionName,
        ...params.data.customData,
      },
    };

    pushEcommerceEventToDataLayer(gtmEcommerceEvent, params.enableBundling);
  }

  function getItemValue(ecommerceItem: Gtm2EcommerceItem) {
    return Number((ecommerceItem.price * (ecommerceItem.quantity || 1)).toFixed(2));
  }

  function trackEcommerce(params: Gtm2EcommerceParams) {
    const paramsProducts = params.data.products;
    const ecommerceItems = getEcommerceItems(paramsProducts, params.event);
    if (params.event === 'purchase') {
      paramsProducts.forEach((paramsProduct) => {
        sessionStorageRemove(getGtmListTypeKey(paramsProduct.product));
      });
    }
    const currency = params.data.currency || paramsProducts[0]?.product?.getPrice()?.currency;
    const value =
      params.data.value ??
      ecommerceItems.reduce((sum: number, ecommerceItem: Gtm2EcommerceItem) => {
        sum += getItemValue(ecommerceItem);
        return sum;
      }, 0);

    const gtmEcommerceEvent: Gtm2EcommerceEvent = {
      event: params.event,
      ecommerce: {
        currency,
        value,
        items: ecommerceItems,
        ...params.data.customData,
      },
    };

    pushEcommerceEventToDataLayer(gtmEcommerceEvent, params.enableBundling);
  }

  function initTracking() {
    const { customerModelRef } = useMopCustomer();
    if (isGtmScriptAdded || !isEnabled() || isConsentRequired() || !customerModelRef.value.isRealUser()) {
      return;
    }
    isGtmScriptAdded = true;
    commitDatalayer();
    loadScript({
      source: `https://www.googletagmanager.com/gtm.js?id=${config.public.GTM_CONTAINER_ID}`,
      callback: () => {
        pushData({
          event: 'gtm.js',
          'gtm.start': new Date().getTime(),
          'gtm.uniqueEventId': 0,
        });
      },
    });
  }

  type AbTastyTest = {
    name: string;
    variationName: string;
    variationID: string;
  };

  const reportedAbTastyTests: string[] = [];

  function getUnreportedABTastyTests(): AbTastyTest[] {
    if (typeof window.ABTasty === 'undefined') {
      return [];
    }
    let testsOnPage: any = {};
    try {
      testsOnPage = window.ABTasty?.getTestsOnPage() || {};
    } catch (err) {
      return [];
    }
    const unreportedTests: AbTastyTest[] = [];
    Object.keys(testsOnPage).forEach((testKey) => {
      if (reportedAbTastyTests.includes(testKey)) {
        return;
      }
      const { name, variationName, variationID } = testsOnPage[testKey];
      unreportedTests.push({
        name: (name || '').replace(/ /g, ''),
        variationName: (variationName || '').replace(/ /g, ''),
        variationID,
      });
      reportedAbTastyTests.push(testKey);
    });
    return unreportedTests;
  }

  function trackAbTasty() {
    getUnreportedABTastyTests().forEach((abTastyTest) => {
      reportLegacyEngagement({
        event: 'abTasty',
        category: abTastyTest.name,
        label: `${abTastyTest.variationName}_${abTastyTest.variationID}`,
      });
    });
  }

  function trackPageView(pageViewEvent: Gtm2PageViewParams) {
    if (!isClient) {
      return;
    }
    const customData = pageViewEvent.customData || {};

    const value: TrackingPageViewParams = {
      gtm: {
        data: {
          pageType: pageViewEvent.pageType,
          pageCategoryId: pageViewEvent.pageCategoryId,
          ...customData,
        },
      },
    };

    currentPage = pageViewEvent.pageType;

    if (value?.gtm?.data) {
      const { customerModelRef } = useMopCustomer();
      const { cartModelRef } = useMopCartClient();
      value.gtm.data.userId =
        value.gtm.data.userId ||
        customerModelRef.value.getCustomerNumber() ||
        sessionStorageGet(constants.SESSION_STORAGE.USER_ID_FROM_GET_PARAMS) ||
        '';
      value.gtm.data.pageCountryCode = $mopI18n.country.toUpperCase();
      value.gtm.data.pageLanguageCode = $mopI18n.locale.replace('-', '_');
      value.gtm.data.privacyOptOutPerformance = parseInt(
        $cookie.get(constants.COOKIE.PRIVACY_OPT_OUT_PERFORMANCE) || '0',
      );
      value.gtm.data.globalE = $mopI18n.isGlobalE;
      value.gtm.data.abTestName = $abTestServerSide.abTestName;
      value.gtm.data.abTestVariant = $abTestServerSide.abTestVariant;

      window.dataLayer?.push(GtmUtils.getPageViewEvent(value.gtm.data));
      trackView(useRoute().fullPath);
      trackAbTasty();
      trackCartContent(cartModelRef.value.getLineItems());
    }
  }

  function appendGtmListType(data: GtmProductTrackingParams, deleteSessionEntry = false) {
    const gtmTypeList: GtmProductListType = (data?.product &&
      sessionStorageGet(getGtmListTypeKey(data.product))) as GtmProductListType;
    if (gtmTypeList) {
      data.list = gtmTypeList;
    }
    if (gtmTypeList && deleteSessionEntry) {
      sessionStorageRemove(getGtmListTypeKey(data.product));
    }
  }

  // Legacy reporting
  function reportLegacyCartContent(data: LegacyTrackingCartEventParams) {
    if (!isClient) {
      return;
    }
    trackCartContent(data.items);
  }

  // Legacy reporting
  function reportLegacyCheckoutEvent(data: GtmCheckoutEventParams) {
    if (!isClient || !isEnabled()) {
      return;
    }
    data.products = data.products.map((data) => {
      appendGtmListType(data);
      return data;
    });

    pushData({ ecommerce: null });
    pushData(GtmUtils.getCheckoutEvent(data));
  }

  // Legacy reporting
  function reportLegacyEngagement(data: any, ignoreBundling = false) {
    if (!isClient || !isEnabled()) {
      return;
    }

    const {
      event = null,
      category = null,
      action = null,
      label = null,
      value = null,
      noninteraction = false,
      ...rest
    } = data;

    pushData(
      {
        event: event || 'interaction',
        target: category,
        ...action,
        'target-properties': label,
        value,
        'interaction-type': noninteraction,
        ...rest,
      },
      !ignoreBundling,
    );
  }

  function trackEvent(params: Gtm2Params) {
    const gtmEvent: Gtm2Event = {
      event: params.event,
      ...params.data,
    };

    pushEventToDataLayer(gtmEvent);
  }

  function trackViewAbTest(position: string = '') {
    trackEvent({
      event: 'view_mop_abtest',
      data: {
        parameter1: $abTestServerSide.abTestName,
        parameter2: $abTestServerSide.abTestVariant,
        parameter3: position,
      },
    });
  }

  function getPageCategoryId() {
    if (!isEnabled()) {
      return '';
    }
    const pageInformationData: Array<GtmPageViewEvent> = window.dataLayer.filter((item: any) => item.PageCategoryID);
    return pageInformationData.length > 0 ? pageInformationData?.pop()?.PageCategoryID : '';
  }

  function getCurrentPage() {
    return currentPage;
  }

  function trackCartContent(lineItems: CartLineItemModel[]) {
    if (!isEnabled()) {
      return;
    }
    const trackingEvents: GtmProductTrackingParams[] = [];
    lineItems.forEach((cartLineItem: CartLineItemModel, index: number) => {
      trackingEvents.push({
        product: cartLineItem.getProduct(),
        variant: cartLineItem.getVariant(),
        quantity: cartLineItem.getQuantity(),
        price: cartLineItem.getUnitPrice().salePriceNet,
        position: index + 1,
      });
    });
    pushData(GtmUtils.getCartContentEvent(trackingEvents, $mopI18n.currency));
  }

  function trackView(path: string) {
    if (!isEnabled()) {
      return;
    }
    pushData({
      event: 'page-view',
      dl_page: path,
    });
  }

  nuxtApp.provide(
    'gtm2',
    securedWrap({
      initTracking,
      trackEcommerce,
      trackPageView,
      trackPromotion,
      trackEvent,
      trackViewAbTest,
      getPageCategoryId,
      getCurrentPage,

      reportLegacyEngagement,
      reportLegacyCheckoutEvent,
      reportLegacyCartContent,
    }),
  );
});
