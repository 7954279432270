import type { ClientResponse, Category, CategoryPagedQueryResponse } from '@commercetools/platform-sdk';
import type { MopCategoryData, MopCategoryDataCustomFields, QueryCategoryParams } from '@/types/category';
import type { ApiCommercetoolsConfig } from '@/types/apiInit';

type TempMopCategoryData = MopCategoryData & {
  tempId?: string;
  tempParentId?: string;
};

export const defaultCategoryCustomFields: any = {
  redirect: '',
};

export function getMappedCategories({
  commercetoolsCategories,
  lang,
  country,
  commercetoolsCountry,
  returnFullMapping = true,
}: {
  commercetoolsCategories: ClientResponse<CategoryPagedQueryResponse>;
  lang: string;
  country: string;
  commercetoolsCountry: string;
  returnFullMapping: boolean;
}): TempMopCategoryData[] {
  if (commercetoolsCategories.statusCode !== 200 || !commercetoolsCategories.body?.results) {
    return [];
  }
  const categoriesIdMap: { [key: string]: Category } = {};
  commercetoolsCategories.body.results.forEach((commercetoolsCategory) => {
    categoriesIdMap[commercetoolsCategory.id] = commercetoolsCategory;
  });

  const categories: TempMopCategoryData[] = [];
  commercetoolsCategories.body.results.forEach((commercetoolsCategory: Category) => {
    const commercetoolsParentCategory = commercetoolsCategory.parent?.id
      ? categoriesIdMap[commercetoolsCategory.parent.id]
      : null;

    const customFields = commercetoolsCategory.custom?.fields || {};
    const customFieldsParent = commercetoolsParentCategory?.custom?.fields || {};
    // Faking nested attributes for isOffline, excludedCountries and isHidden

    if (customFieldsParent.isOffline || customFieldsParent.excludedCountries?.toLowerCase().includes(country)) {
      customFields.isOffline = true;
    }
    if (customFieldsParent.isHidden === true) {
      customFields.isHidden = true;
    }

    if (customFields.isOffline) {
      return;
    }

    if (customFields.excludedCountries?.toLowerCase().includes(country)) {
      return;
    }

    const id = commercetoolsCategory.id;
    const depth = commercetoolsCategory.slug[lang]?.split('_').length ?? 1;
    const path = commercetoolsCategory.slug[lang] ?? id;
    const name = commercetoolsCategory.name[lang] ?? '';
    const mopId = commercetoolsCategory.key || '';

    let categoryCount = 0;
    try {
      const countJson = JSON.parse(customFields.productCountJson);
      categoryCount = countJson[commercetoolsCountry.toUpperCase()];
    } catch (error) {
      // Do nothing
    }
    const isVisible =
      customFields.isHidden === false || (customFields.isHidden !== true && categoryCount > 0) ? '1' : '0';

    const countData = customFields.showProductCount ? `|${String(categoryCount)}` : '';

    const category: TempMopCategoryData = {
      ...(returnFullMapping ? commercetoolsCategory : {}),
      ...(returnFullMapping ? commercetoolsCategory.custom?.fields || {} : {}),
      // To shrink dom size
      data: `${id}|${path}|${name}|${depth}|${mopId}|${isVisible}${countData}`,
      tempId: id,
      tempParentId: commercetoolsCategory.parent?.id,
    };
    if (
      customFields.productQueryIncludeProductsOnSale === true ||
      customFields.productQueryIncludeProductsOnSale === false
    ) {
      category.includeProductsOnSale = customFields.productQueryIncludeProductsOnSale;
    }

    Object.keys(customFields)?.forEach((key: any) => {
      const customFieldName = key as keyof MopCategoryDataCustomFields;
      const customFieldValue = customFields[customFieldName];
      const defaultValue: string = defaultCategoryCustomFields[customFieldName];
      const isCustomFieldValueNeededInFrontend = defaultValue !== undefined;
      const isCustomFieldValueDifferentFromDefault = defaultValue !== customFieldValue;
      if (!isCustomFieldValueNeededInFrontend || !isCustomFieldValueDifferentFromDefault) {
        return;
      }
      if (customFieldValue === 'true' || customFieldValue === true) {
        category[customFieldName] = '1' as any;
      } else if (customFieldValue === 'false' || customFieldValue === false) {
        category[customFieldName] = '0' as any;
      } else {
        category[customFieldName] = customFieldValue;
      }
    });

    categories.push(category);
  });
  return categories;
}

function getCategoryTree(commercetoolsCategories: TempMopCategoryData[]) {
  const categoriesIdMap: { [key: string]: TempMopCategoryData } = {};
  const categoryRoot: MopCategoryData[] = [];

  commercetoolsCategories.forEach((commercetoolsCategory) => {
    categoriesIdMap[commercetoolsCategory.tempId!] = commercetoolsCategory;
    delete commercetoolsCategory.tempId;
    commercetoolsCategory.children = [];
  });

  commercetoolsCategories.forEach((commercetoolsCategory) => {
    const commercetoolsParentCategory = commercetoolsCategory.tempParentId
      ? categoriesIdMap[commercetoolsCategory.tempParentId]
      : null;
    delete commercetoolsCategory.tempParentId;
    if (commercetoolsParentCategory) {
      commercetoolsParentCategory.children!.push(commercetoolsCategory);
    } else {
      categoryRoot.push(commercetoolsCategory);
    }
  });

  return categoryRoot;
}

function wrapListResponse(commercetoolsCategories: MopCategoryData[]) {
  return {
    data: commercetoolsCategories,
  };
}

function wrapResponse(commercetoolsCategory: MopCategoryData) {
  return {
    data: commercetoolsCategory,
  };
}

export async function queryCategoryTree(apiConfig: ApiCommercetoolsConfig) {
  // Will work with max 1000 categories only, but its the fastest solution
  const MAX_FETCH_COUNT = 500;
  const SORT_QUERY = ['orderHint ASC', 'id ASC'];
  const [categoryResponse, additionalCategoryRresponse] = await Promise.all([
    apiConfig.apiClient
      .categories()
      .get({ queryArgs: { limit: MAX_FETCH_COUNT, sort: SORT_QUERY } })
      .execute(),
    apiConfig.apiClient
      .categories()
      .get({ queryArgs: { limit: MAX_FETCH_COUNT, sort: SORT_QUERY, offset: MAX_FETCH_COUNT } })
      .execute(),
    apiConfig.apiClient
      .categories()
      .get({ queryArgs: { limit: MAX_FETCH_COUNT, sort: SORT_QUERY, offset: MAX_FETCH_COUNT * 2 } })
      .execute(),
  ]);

  categoryResponse.body.results.push(...additionalCategoryRresponse.body.results);

  return wrapListResponse(
    getCategoryTree(
      getMappedCategories({
        commercetoolsCategories: categoryResponse,
        lang: apiConfig.lang,
        country: apiConfig.country,
        commercetoolsCountry: apiConfig.commercetoolsCountry,
        returnFullMapping: false,
      }),
    ),
  );
}

export async function queryCategories(apiConfig: ApiCommercetoolsConfig, params: QueryCategoryParams) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.limit = params.queryArgs.limit || 500;
  return wrapListResponse(
    getMappedCategories({
      commercetoolsCategories: await apiConfig.apiClient.categories().get(params).execute(),
      lang: apiConfig.lang,
      country: apiConfig.country,
      commercetoolsCountry: apiConfig.commercetoolsCountry,
      returnFullMapping: true,
    }),
  );
}

export async function queryCategoryByIds(
  apiConfig: ApiCommercetoolsConfig,
  ids: string[],
  params?: QueryCategoryParams,
) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.limit = params.queryArgs.limit || 500;
  params.queryArgs.where = `id in (${ids.map((id) => `"${id}"`).join(', ')})`;
  return wrapListResponse(
    getMappedCategories({
      commercetoolsCategories: await apiConfig.apiClient.categories().get(params).execute(),
      lang: apiConfig.lang,
      country: apiConfig.country,
      commercetoolsCountry: apiConfig.commercetoolsCountry,
      returnFullMapping: true,
    }),
  );
}

export async function queryCategoryById(apiConfig: ApiCommercetoolsConfig, id: string, params?: QueryCategoryParams) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.where = `id = "${id}"`;
  params.queryArgs.limit = 1;
  return wrapResponse(
    getMappedCategories({
      commercetoolsCategories: await apiConfig.apiClient.categories().get(params).execute(),
      lang: apiConfig.lang,
      country: apiConfig.country,
      commercetoolsCountry: apiConfig.commercetoolsCountry,
      returnFullMapping: true,
    })[0],
  );
}

export async function queryCategoryByMopId(
  apiConfig: ApiCommercetoolsConfig,
  mopId: string,
  params?: QueryCategoryParams,
) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.where = `key = "${mopId}"`;
  params.queryArgs.limit = 1;
  return wrapResponse(
    getMappedCategories({
      commercetoolsCategories: await apiConfig.apiClient.categories().get(params).execute(),
      lang: apiConfig.lang,
      country: apiConfig.country,
      commercetoolsCountry: apiConfig.commercetoolsCountry,
      returnFullMapping: true,
    })[0],
  );
}

export async function queryCategoryByPath(
  apiConfig: ApiCommercetoolsConfig,
  path: string,
  params?: QueryCategoryParams,
) {
  params = params || {};
  params.queryArgs = params.queryArgs || {};
  params.queryArgs.where = `slug(${apiConfig.lang}="${path.replace(/\//g, '_')}")`;
  params.queryArgs.limit = 1;
  return wrapResponse(
    getMappedCategories({
      commercetoolsCategories: await apiConfig.apiClient.categories().get(params).execute(),
      lang: apiConfig.lang,
      country: apiConfig.country,
      commercetoolsCountry: apiConfig.commercetoolsCountry,
      returnFullMapping: true,
    })[0],
  );
}
