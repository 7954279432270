import type { CentPrecisionMoney } from '@commercetools/platform-sdk';
import { getToken } from './apiToken';
import { getCollectionPoints } from './apiCheckout';
import { getStore, getStoreByGln, getStores, getOpticalStores } from './apiStores';
import { getOrders, getOrder, getCheckoutOrder, finishGlobalEOrder } from './apiOrders';
import {
  login,
  logout,
  refreshToken,
  registerEmailCheck,
  updatePassword,
  register,
  passwordResetToken,
  passwordReset,
  createAnonymousSession,
} from './apiAuth';
import { getMe, updateCustomerData, getBonusVoucherSteps, deleteCustomer } from './apiMe';
import {
  addCartItem,
  getCart,
  removeCartItem,
  submitArvatoPayment,
  setGuestShippingAddress,
  setGuestBillingAddress,
  assignCustomerShippingAddress,
  assignCustomerBillingAddress,
  getShippingMethods,
  setShippingMethod,
  getPaymentMethods,
  setPaymentMethod,
  updateCartItem,
  submitGiftCardPayment,
  submitAdyenPayment,
  submitAdyenPaymentAdditionalDetails,
  getPaymentCheckStatus,
  assignGiftCardPayment,
  unassignGiftCardPayment,
  setCartNewsletterSubscription,
  addVoucher,
  removeVoucher,
  setCartEmployeeId,
} from './apiCart';
import { getWishlist, addWishlistItem, removeWishlistItem } from './apiWishlist';
import {
  getAddresses,
  updateAddress,
  createAddress,
  createLocationAddress,
  removeAddress,
  addressRiskCheck,
} from './apiAddress';
import {
  getReservations,
  getReservationById,
  createReservation,
  requestReservation,
  deleteReservation,
} from './apiReservation';
import { setNewsletterSubscription, createNewsletterUnsubscriptionFeedback } from './apiNewsletter';
import { getStockData } from './apiGetStock';
import { registerBackInStockNotification } from './apiBackInStock';
import { createNpsScore } from './apiNpsScore';
import { createSupportEmail } from './apiSupportEmail';
import { searchCustomers, loginOnBehalf } from './apiOrderOnBehalf';
import type { CreateReservationData, ReservationStatus } from '@/types/reservation';
import type { CartLineItemCustomFields } from '@/types/cart';
import type { CollectionPointSearchParams } from '@/types/collectionPoint';
import type { ApiBffConfig } from '@/types/apiInit';
import type {
  BffApiNewsletterSubscriptionPostRequest,
  BffApiAccountMeDeleteRequest,
  BffCartAddress,
  BffCustomerRegisterPayload,
  BffCustomerUpdateAttributes,
  BffApiBackinstockSubscriptionPostRequest,
  BffApiNpsScorePostRequest,
  BffApiOpticalStoresGetRequest,
  BffApiStockPostRequest,
  BffSupportMailPostRequest,
  BffApiNewsletterUnsubscribeFeedbackPostRequest,
  BffSearchCustomerPayload,
} from '@/types/bff';

import type { StoreSearchParams } from '@/types/store';

export function initApiBff(apiConfig: ApiBffConfig) {
  return {
    getToken: () => getToken(apiConfig),
    deleteCustomer: (feedback: BffApiAccountMeDeleteRequest) => deleteCustomer(apiConfig, feedback),
    getBonusVoucherSteps: () => getBonusVoucherSteps(apiConfig),
    getCart: (params?: { recalculate?: boolean }) => getCart(apiConfig, params),
    addCartItem: (params: {
      sku: string;
      quantity: number;
      supplyChannelId: string;
      customFields?: CartLineItemCustomFields;
    }) => addCartItem(apiConfig, params),
    updateCartItem: (params: { sku: string; quantity: number }) => updateCartItem(apiConfig, params),
    removeCartItem: (params: { sku: string }) => removeCartItem(apiConfig, params),
    submitGiftCardPayment: () => submitGiftCardPayment(apiConfig),
    submitArvatoPayment: (params: { paymentMethod: string; paymentAmount: CentPrecisionMoney }) =>
      submitArvatoPayment(apiConfig, params),
    submitAdyenPayment: (params: {
      returnUrl: string;
      riskData: any; // adyen auto param
      clientStateDataIndicator: boolean; // adyen auto param
      paymentMethod: any; // adyen auto param
      paymentAmount: CentPrecisionMoney;
    }) => submitAdyenPayment(apiConfig, params),
    submitAdyenPaymentAdditionalDetails: (params: {
      paymentId: string;
      details: any; // adyen auto param
    }) => submitAdyenPaymentAdditionalDetails(apiConfig, params),
    getPaymentCheckStatus: (params: { paymentId: string }) => getPaymentCheckStatus(apiConfig, params),
    getPaymentMethods: () => getPaymentMethods(apiConfig),
    setPaymentMethod: (params: { paymentMethod: string }) => setPaymentMethod(apiConfig, params),
    getShippingMethods: (lang: string) => getShippingMethods(apiConfig, lang),
    setShippingMethod: (shippingMethodId: string) => setShippingMethod(apiConfig, shippingMethodId),
    setGuestShippingAddress: (address: BffCartAddress, skipAddressCheck: boolean) =>
      setGuestShippingAddress(apiConfig, address, skipAddressCheck),
    setGuestBillingAddress: (address: BffCartAddress, skipAddressCheck: boolean) =>
      setGuestBillingAddress(apiConfig, address, skipAddressCheck),
    assignCustomerShippingAddress: (addressId: string) => assignCustomerShippingAddress(apiConfig, addressId),
    assignCustomerBillingAddress: (addressId: string) => assignCustomerBillingAddress(apiConfig, addressId),
    login: (params: { email: string; password: string }) => login(apiConfig, params),
    logout: () => logout(apiConfig),
    refreshToken: () => refreshToken(apiConfig),
    getWishlist: () => getWishlist(apiConfig),
    addWishlistItem: (params: { productKey: string; quantity?: number }) => addWishlistItem(apiConfig, params),
    removeWishlistItem: (params: { productKey: string }) => removeWishlistItem(apiConfig, params),
    updatePassword: (params: { email: string; currentPassword: string; newPassword: string }) =>
      updatePassword(apiConfig, params),
    registerEmailCheck: (email: string) => registerEmailCheck(apiConfig, { email }),
    register: (params: BffCustomerRegisterPayload, skipAddressCheck: boolean) =>
      register(apiConfig, params, skipAddressCheck),
    passwordResetToken: (params: { email: string; locale: string }) => passwordResetToken(apiConfig, params),
    passwordReset: (params: { token: string; newPassword: string }) => passwordReset(apiConfig, params),
    createAnonymousSession: () => createAnonymousSession(apiConfig),
    collectionPoints: (params: CollectionPointSearchParams) => getCollectionPoints(apiConfig, params),
    getStores: (params: StoreSearchParams) => getStores(apiConfig, params),
    getStore: (id: string, params?: { ean?: string }) => getStore(apiConfig, id, params),
    getStoreByGln: (gln: string) => getStoreByGln(apiConfig, gln),
    getOpticalStores: (params: BffApiOpticalStoresGetRequest) => getOpticalStores(apiConfig, params),
    getCheckoutOrder: (params: { orderId: string; externalOrderId: string }) => getCheckoutOrder(apiConfig, params),
    finishGlobalEOrder: (params: { externalOrderId: string }) => finishGlobalEOrder(apiConfig, params),
    getOrders: () => getOrders(apiConfig),
    getOrder: (orderId: string, customerNumber: string) => getOrder(apiConfig, orderId, customerNumber),
    getAddresses: () => getAddresses(apiConfig),
    updateAddress: (address: BffCartAddress, options: { setAsDefault?: boolean; skipAddressCheck?: boolean }) =>
      updateAddress(apiConfig, address, options),
    createAddress: (address: BffCartAddress, options: { setAsDefault?: boolean; skipAddressCheck?: boolean }) =>
      createAddress(apiConfig, address, options),
    createLocationAddress: (params: BffCartAddress) => createLocationAddress(apiConfig, params),
    removeAddress: (id: string) => removeAddress(apiConfig, id),
    addressRiskCheck: (address: BffCartAddress) => addressRiskCheck(apiConfig, address),
    assignGiftCardPayment: (giftcardId: string) => assignGiftCardPayment(apiConfig, giftcardId),
    unassignGiftCardPayment: () => unassignGiftCardPayment(apiConfig),
    setCartNewsletterSubscription: (isSubscribed: boolean) => setCartNewsletterSubscription(isSubscribed, apiConfig),
    addVoucher: (params: { email: string; code: string }) => addVoucher(apiConfig, params),
    removeVoucher: (params: { code: string }) => removeVoucher(apiConfig, params),
    getMe: () => getMe(apiConfig),
    updateCustomerData: (customerData: BffCustomerUpdateAttributes) => updateCustomerData(apiConfig, customerData),
    getReservations: () => getReservations(apiConfig),
    getReservationById: (reservationId: string) => getReservationById(apiConfig, reservationId),
    createReservation: (reservationData: CreateReservationData) => createReservation(apiConfig, reservationData),
    requestReservation: (reservationStatus: ReservationStatus, reservationId: string) =>
      requestReservation(apiConfig, reservationStatus, reservationId),
    deleteReservation: (reservationId: string) => deleteReservation(apiConfig, reservationId),
    setNewsletterSubscription: (params: BffApiNewsletterSubscriptionPostRequest) =>
      setNewsletterSubscription(apiConfig, params),
    createNewsletterUnsubscriptionFeedback: (params: BffApiNewsletterUnsubscribeFeedbackPostRequest) =>
      createNewsletterUnsubscriptionFeedback(apiConfig, params),
    getStockData: (params: BffApiStockPostRequest) => getStockData(apiConfig, params),
    registerBackInStockNotification: (params: BffApiBackinstockSubscriptionPostRequest) =>
      registerBackInStockNotification(apiConfig, params),
    createNpsScore: (npsScore: BffApiNpsScorePostRequest) => createNpsScore(apiConfig, npsScore),
    createSupportEmail: (params: BffSupportMailPostRequest) => createSupportEmail(apiConfig, params),
    searchCustomers: (params: BffSearchCustomerPayload) => searchCustomers(apiConfig, params),
    loginOnBehalf: (customerId: string) => loginOnBehalf(apiConfig, { customerId }),
    setCartEmployeeId: (employeeId: string) => setCartEmployeeId(apiConfig, employeeId),
  };
}

export type ApiBff = ReturnType<typeof initApiBff>;
