import { isClient } from '@mop/shared/utils/util';
import { logError } from '@mop/shared/utils/logger';
import { createI18n } from 'vue-i18n';

import {
  localeList,
  cmsLivePreviewLanguageMappings,
  getLocalesFromCountry,
  extractCountriesFromLocaleList,
  getCountryFromLocale,
} from '@/i18n/localeList';
import type { LocaleObject, PriceParams, AvailableCountries, MopI18n } from '@/types/locale';

export default defineNuxtPlugin(async (nuxtApp) => {
  const { $storyblokLivePreview, $cookie } = nuxtApp.vueApp.$nuxt;

  const isStoryblokLivePreviewEnabled = Boolean($storyblokLivePreview.isEnabled);
  const isDebugCookieEnabled = Boolean($cookie.get(constants.COOKIE.DEBUG));
  const route = useRoute();
  const headers = useRequestHeaders();
  const filteredLocaleList: LocaleObject[] = localeList.filter((locale) => {
    return !locale.isDisabled || isStoryblokLivePreviewEnabled || isDebugCookieEnabled;
  });

  const defaultLocale = filteredLocaleList[0]?.code || localeList[0].code;
  let localeFromRoute = route.path.split('/')[1];
  if (isStoryblokLivePreviewEnabled && cmsLivePreviewLanguageMappings[localeFromRoute]) {
    localeFromRoute = cmsLivePreviewLanguageMappings[localeFromRoute];
  }

  const availableCountries: AvailableCountries = extractCountriesFromLocaleList(filteredLocaleList);
  // Right locale is always set -> requestValidation middleware takes care of it
  const locale = filteredLocaleList.find((config) => config.code === localeFromRoute) || localeList[0];

  if ($cookie.get(constants.COOKIE.LANGUAGE) !== locale.code) {
    $cookie.store(constants.COOKIE.LANGUAGE, locale.code);
  }

  const country: string = getCountryFromLocale(locale);

  function formatPrice(params: PriceParams): string {
    const { price, precision = 2 } = params;
    const priceCountry: string = params.country || country;
    const currency: string = params.currency || locale?.currency || 'EUR';
    const displayPrice: number = typeof price === 'string' ? parseFloat(price) : price;
    const priceLang: string = locale!.code.split('-')[0];
    const formatConfig: Intl.NumberFormatOptions = {
      style: 'currency',
      currency,
      minimumFractionDigits: precision,
    };
    let localeCode = `${priceLang}-${priceCountry.toUpperCase()}`;
    // For Austria we want to use specific format, otherwise it will be formatted incorrectly, "-€ 20,00" instead of "€ -20,00"
    if (priceCountry === 'at') {
      localeCode = 'nl-BE';
    }

    if (isClient && !window.Intl) {
      // fallback to old browsers
      return displayPrice.toLocaleString(localeCode, formatConfig);
    }
    return new Intl.NumberFormat(localeCode, formatConfig).format(displayPrice);
  }

  function formatDate(datetime: string, options: any) {
    const reducedTime: string = datetime.split('T')[0]?.replace(' ', 'T');
    const withoutYear: boolean = options?.withoutYear;
    const withWeekday: boolean = options?.withWeekday;
    const date: Date = new Date(Date.parse(reducedTime));
    const formattedDate = `${date.toLocaleDateString(locale!.code, {
      weekday: withWeekday ? 'short' : undefined,
      day: '2-digit',
      month: '2-digit',
      year: withoutYear ? undefined : 'numeric',
    })}`;

    return formattedDate;
  }
  let messagesForLocale = {};
  let localeFetched = false;
  try {
    // Attempt to dynamically import the locale file
    messagesForLocale = await import(`@/i18n/locale/${locale.lang}.json`);
    localeFetched = true;
  } catch (error) {
    logError(`Failed to import locale file for ${locale.lang}`);
    logError(error);

    if (isClient) {
      nuxtApp.callHook('vue:error', error, null, '');
    }
  }
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: locale.lang,
    missing: (locale, key) => {
      if (!key) {
        return;
      }
      if (localeFetched) {
        logError(`Translation key '${key}' not found in locale '${locale}'`);
      }
    },
    messages: {
      [locale.lang]: messagesForLocale,
    },
  });

  const localePath = (localeRoute: string) => {
    let newPath = localeRoute.replace(/%2F/g, '/');
    // Needed for urls, that are based on queries, i.e. router.resolve({ query }).fullPath;
    newPath = /^\/[a-z]{2}-[a-z]{2}\//.test(newPath) ? newPath.substring(6) : newPath;
    newPath = `/${locale.code}${newPath}`;
    if (newPath.startsWith('/it-it/herren') || newPath.startsWith('/it-it/damen')) {
      // eslint-disable-next-line no-console
      console.log('ERROR', route.path, newPath, localeRoute);
    }
    return newPath;
  };
  // @ts-ignore
  const { t, te, tm } = i18n.global;
  nuxtApp.provide('mopI18n', {
    locale: locale.code,
    cmsFallbackLanguage: locale.cmsFallbackLanguage,
    lang: locale.lang,
    localePath,
    t,
    te,
    tm,
    country,
    currency: locale.currency,
    isGlobalE: locale.isGlobalE,
    geCurrency: locale.geCurrency,
    commercetoolsCountry: locale.commercetoolsCountry,
    alpha3: locale.alpha3,
    localeList: filteredLocaleList,
    availableCountries,
    formatPrice,
    formatDate,
    getLocalesFromCountry: (country) => getLocalesFromCountry(filteredLocaleList, country),
    getBestMatchingBrowserLocale: (country) =>
      getBestMatchingBrowserLocale(headers, filteredLocaleList, defaultLocale, country),
  } as MopI18n);
});
