import { securedWrap } from '@mop/shared/utils/securedWrap';
// Direct import to avoid circular dependency
import type {
  ClientResponse,
  ProductPagedSearchResponse,
  SearchQuery,
  SearchSorting,
} from '@commercetools/platform-sdk';
import type { LocationQuery } from 'vue-router';
import type {
  Filter,
  FilterListModelRef,
  FilterModel,
  FilterValue,
  FilterValueModel,
  FilterListResponseData,
} from '@/types/filters';
import type { SortingOption } from '@/types/sorting';
import { productListModel } from '@/models/productModel';
import { paginationModel, filterModel, filterListModel } from '@/models';

import type {
  ProductModelListRef,
  PaginationModelRef,
  ProductSearchParams,
  MopSearchableAttribute,
} from '@/types/product';
import { getProductProjectionParameters } from '@/models/utils/productUtils';

type AppliedSortingRef = Ref<SortingOption | null>;
type AppliedFilterRef = Ref<FilterModel[] | null>;

type MopProductsComposableStorage = {
  appliedFiltersRef: AppliedFilterRef;
  filtersModelRef: FilterListModelRef;
  categoryFiltersModelRef: FilterListModelRef;
  lastCategoryIdRef: Ref<string>;
  productCountRef: Ref<number>;
  filterHash: Ref<string>;
  appliedSorting: AppliedSortingRef;
};

type LoadingProductsState = {
  searchProductsByMopProductIds: boolean;
  searchProductsBySkus: boolean;
  searchProducts: boolean;
  searchProductsByMasterKey: boolean;
  loading: boolean;
};
type LoadingFiltersState = {
  initFilters: boolean;
  loading: boolean;
};

const defaultSorting: SearchSorting[] = [
  {
    field: 'variants.attributes.sortKey',
    fieldType: 'text',
    order: 'asc',
  },
  {
    field: 'variants.attributes.onlineSince',
    fieldType: 'date',
    order: 'desc',
  },
];

const sortingOptions: SortingOption[] = [
  {
    key: 'newest',
    label: 'newest',
    sortValue: 'variants.attributes.onlineSince',
    fieldType: 'date',
    sortDirectionValue: 'desc',
  },
  {
    key: 'price-low-to-high',
    label: 'price_low_to_high',
    sortValue: 'variants.prices.currentCentAmount',
    sortDirectionValue: 'asc',
    mode: 'min',
  },
  {
    key: 'price-high-to-low',
    label: 'price_high_to_low',
    sortValue: 'variants.prices.currentCentAmount',
    sortDirectionValue: 'desc',
    mode: 'max',
  },
];

export default function useMopProducts(cacheId?: string) {
  const { $mopI18n, $mopConfig, $apiCommercetools } = useNuxtApp();
  const route = useRoute();
  const storage = initStorage<MopProductsComposableStorage>('useMopProducts');
  const { getCachedResponse } = useClientCache();
  const { getQueryParamValue, getLocalePathFromQuery } = useMopRouter();
  const { getCurrentCategoryWithFallbackToParent } = useMopCategoryTree();
  const { categoryKeysListRef } = useMopRootCategories();
  const { searchableAttributes } = useMopSearchableAttributes();
  const { customerModelRef } = useMopCustomer();
  const loadingProductsRef: Ref<LoadingProductsState> = ref({
    searchProductsByMopProductIds: false,
    searchProductsBySkus: false,
    searchProducts: false,
    searchProductsByMasterKey: false,
    loading: computed(() => isLoading(loadingProductsRef)),
  });
  const loadingFiltersRef: Ref<LoadingFiltersState> = ref({
    initFilters: false,
    loading: computed(() => isLoading(loadingProductsRef)),
  });

  const productSearchResponseRef = ref<ClientResponse<ProductPagedSearchResponse> | null>(null);
  const productModelListRef: ProductModelListRef = ref(productListModel(null));
  const paginationModelRef: PaginationModelRef = ref(paginationModel(null));

  const categoryFilterListResponseRef = useMopSSR<FilterListResponseData | null>(`filters-${cacheId}`, null);

  const appliedFiltersRef: AppliedFilterRef =
    storage.get('appliedFiltersRef') ?? storage.saveAndGet('appliedFiltersRef', ref(buildFilterModel(null)));

  const filtersModelRef: FilterListModelRef =
    storage.get('filtersModelRef') ?? storage.saveAndGet('filtersModelRef', ref(filterListModel(null)));

  const categoryFiltersModelRef: FilterListModelRef =
    storage.get('categoryFiltersModelRef') ?? storage.saveAndGet('categoryFiltersModelRef', ref(filterListModel(null)));

  const lastCategoryIdRef: Ref<string> =
    storage.get('lastCategoryIdRef') ?? storage.saveAndGet('lastCategoryIdRef', ref(''));

  const productCountRef: Ref<number> = storage.get('productCountRef') ?? storage.saveAndGet('productCountRef', ref(0));

  const filterHash: Ref<string> = storage.get('filterHash') ?? storage.saveAndGet('filterHash', ref(''));
  const appliedSortingRef = storage.get('appliedSorting') ?? storage.saveAndGet('appliedSorting', ref(null));

  function buildFilterModel(param: Filter[] | null): FilterModel[] | null {
    if (!param) {
      return [];
    }

    return param.map((filter) => filterModel(filter, searchableAttributes.value));
  }

  function getQueryfield(attr: MopSearchableAttribute[0]) {
    return attr.fieldType === 'enum' ? `${attr.field}.key` : attr.field;
  }

  async function initFilters(checkIfFiltersAreSet = false) {
    if (!searchableAttributes.value) {
      return;
    }

    const filterKeyList = FILTER_KEYS.join('|');
    const urlContainsFilterKeys = new RegExp(`(${filterKeyList})=`, 'gi').test(route.fullPath);
    if (checkIfFiltersAreSet && !urlContainsFilterKeys && !filterHash.value) {
      appliedFiltersRef.value = buildFilterModel(null);
      return;
    }
    const category = getCurrentCategoryWithFallbackToParent();
    const routeQuery = route.query;

    // calculate filter hash to allow back/forward navigation with filter reload
    const hash: string =
      Object.keys(routeQuery).reduce((hash: string, key: string) => {
        if (new RegExp(filterKeyList).test(key)) {
          return hash + `${key}-${JSON.stringify(routeQuery[key])}`;
        }
        return hash;
      }, '') || '';

    if (
      (lastCategoryIdRef.value && lastCategoryIdRef.value === category?.getId()) ||
      (hash && hash === filterHash.value)
    ) {
      return;
    }

    filterHash.value = hash;
    loadingFiltersRef.value.initFilters = true;

    if (category?.isInitialized()) {
      lastCategoryIdRef.value = category.getId();
    }

    const query: SearchQuery[] = [
      {
        exact: {
          field: 'categories',
          value: category?.getId(),
        },
      },
      getChannelsQuery(),
    ];

    categoryFilterListResponseRef.value ??= await $apiCommercetools.searchProducts({
      body: {
        facets: Object.keys(searchableAttributes.value).map((attrKey) => {
          const attr = searchableAttributes.value![attrKey];
          return {
            distinct: {
              name: attrKey,
              field: getQueryfield(attr),
              fieldType: attr.fieldType,
              language: $mopI18n.lang,
              limit: attr.limit ?? 200,
              filter: attr.filter ?? undefined,
            },
          };
        }),
        query: {
          and: query,
        },
        productProjectionParameters: getProductProjectionParameters(
          $mopI18n,
          customerModelRef.value.getCommercetoolsCustomerGroupId(),
        ),
        limit: 0,
      },
    });
    categoryFiltersModelRef.value = filterListModel(categoryFilterListResponseRef.value, searchableAttributes.value);
    setAppliedFiltersFromUrl();
    loadingFiltersRef.value.initFilters = false;
  }

  function setAppliedFiltersFromUrl(): void {
    const { query: queryParams } = route;
    const appliedFilters: Filter[] = [];
    const filterModelList: FilterModel[] = categoryFiltersModelRef.value.getFilterModelList();
    if (filterModelList.length === 0) {
      appliedFiltersRef.value = buildFilterModel(null);
      return;
    }

    Object.keys(queryParams).forEach((queryParamKey) => {
      const filter: FilterModel | undefined = filterModelList.find(
        (filterModel) => filterModel.getSlug() === queryParamKey,
      );
      if (!filter) {
        return;
      }
      const queryParamValues: string[] | undefined = getQueryParamValue(queryParamKey)?.split(',');
      if (!queryParamValues?.length) {
        return;
      }

      const filterValues: FilterValue[] = [];
      if (filter.isPriceRange()) {
        const min = parseInt(queryParamValues[0]);
        const max = parseInt(queryParamValues[1]);
        if (isNaN(min) || isNaN(max) || min > max) {
          return;
        }
        filterValues.push({
          key: String(min),
          count: 0,
        });
        filterValues.push({
          key: String(max),
          count: 0,
        });
      } else {
        // Try to get filter values
        queryParamValues.forEach((queryParamValue: string) => {
          const foundValue = filter.getValues().find((value) => queryParamValue === value.getValue());
          if (!foundValue) {
            return;
          }
          filterValues.push({
            key: foundValue.getKey(),
            count: 0,
          });
        });
      }

      const filterItem: Filter = {
        name: filter.getRawName(),
        buckets: filterValues,
      };

      appliedFilters.push(filterItem);
    });

    appliedFiltersRef.value = buildFilterModel(appliedFilters);
  }

  async function addFilterValue(filter: FilterModel, filterValue: FilterValueModel | { min: number; max: number }) {
    const query = { ...route.query };
    if (filter.isPriceRange()) {
      // @ts-ignore
      query[filter.getSlug()] = `${String(filterValue.min)},${String(filterValue.max)}`;
    } else {
      const queryValues: string[] = getSelectedQueryValues(filter);
      filterValue && queryValues.push(String((filterValue as FilterValueModel).getValue()));
      query[filter.getSlug()] = queryValues.join(',');
    }

    if (query.page) {
      delete query.page;
    }

    const localeUrl = getLocalePathFromQuery(getSortedQuery(query, filter));
    await useRouter().push(localeUrl);
    setAppliedFiltersFromUrl();
  }

  async function removeFilterValue(filter: FilterModel, filterValue?: FilterValueModel) {
    const query = { ...route.query };
    if (filter.isPriceRange()) {
      delete query[filter.getSlug()];
    } else {
      const queryValues: string[] = getSelectedQueryValues(filter);
      if (filterValue) {
        const index: number = queryValues.findIndex((id: string) => id === String(filterValue.getValue()));
        index !== -1 && queryValues.splice(index, 1);
      }
      query[filter.getSlug()] = queryValues.join(',');
      if (query[filter.getSlug()] === undefined || query[filter.getSlug()] === '') {
        delete query[filter.getSlug()];
      }
    }

    if (query.page) {
      delete query.page;
    }

    // a weird reactivity case where empty query params trigger route update too late
    if (!Object.keys(query).length) {
      // @ts-ignore
      query.empty = undefined;
    }
    const localeUrl = getLocalePathFromQuery(getSortedQuery(query, filter));
    await useRouter().push(localeUrl);
    setAppliedFiltersFromUrl();
  }

  async function removeAllFilters() {
    const query = { ...route.query };

    appliedFiltersRef.value?.forEach((appliedFilter: FilterModel) => {
      delete query[appliedFilter.getSlug()];
    });

    // Reset page param after changing the filters
    if (query.page) {
      delete query.page;
    }

    if (query.sort) {
      delete query.sort;
    }

    // a weird reactivity case where empty query params trigger route update too late
    if (!Object.keys(query).length) {
      // @ts-ignore
      query.empty = undefined;
    }

    const localeUrl = getLocalePathFromQuery(query);
    await useRouter().push(localeUrl);
    setAppliedFiltersFromUrl();
  }

  function getSelectedQueryValues(filter: FilterModel) {
    const foundAppliedFilter: FilterModel | undefined = appliedFiltersRef.value?.find(
      (appliedFilter: FilterModel) => appliedFilter.getSlug() === filter.getSlug(),
    );

    return (
      foundAppliedFilter
        ?.getValues()
        .map((selectedFilterValue) => selectedFilterValue.getValue())
        ?.sort() ?? []
    );
  }

  function updateProductCount(count = 0) {
    productCountRef.value = count;
  }

  async function searchProductsByMopProductIds(productKeys: string[], forceClientRefetch = false) {
    if (!productKeys || productKeys.length === 0) {
      return;
    }

    if (productKeys.length > constants.MAX_CT_PRODUCT_IDS_PER_REQUEST) {
      // Will be removed, once CT enhanced/increased API limit
      productKeys = productKeys.slice(0, constants.MAX_CT_PRODUCT_IDS_PER_REQUEST);
    }

    loadingProductsRef.value.searchProductsByMopProductIds = true;

    if (forceClientRefetch) {
      productSearchResponseRef.value = null;
    }

    // Manipulate a bit, so "or" query will work
    if (productKeys.length === 1) {
      productKeys.push(productKeys[0]);
    }

    productSearchResponseRef.value ??= await $apiCommercetools.searchProducts(
      {
        body: {
          query: {
            exact: {
              field: 'key',
              fieldType: 'text',
              values: productKeys.map((key) => key.toUpperCase()),
            },
          },
          limit: productKeys.length,
          productProjectionParameters: getProductProjectionParameters(
            $mopI18n,
            customerModelRef.value.getCommercetoolsCustomerGroupId(),
          ),
        },
      },
      categoryKeysListRef.value,
    );
    productModelListRef.value = productListModel(productSearchResponseRef.value);
    paginationModelRef.value = paginationModel(productSearchResponseRef.value);
    loadingProductsRef.value.searchProductsByMopProductIds = false;
  }

  async function searchProductsBySkus(productEans: string[], forceClientRefetch = false) {
    if (!productEans || productEans.length === 0) {
      return;
    }
    loadingProductsRef.value.searchProductsBySkus = true;

    if (forceClientRefetch) {
      productSearchResponseRef.value = null;
    }

    // Manipulate a bit, so "or" query will work
    if (productEans.length === 1) {
      productEans.push(productEans[0]);
    }

    productSearchResponseRef.value ??= await $apiCommercetools.searchProducts(
      {
        body: {
          query: {
            exact: {
              field: 'variants.key',
              fieldType: 'text',
              values: productEans,
            },
          },
          limit: productEans.length,
          productProjectionParameters: getProductProjectionParameters(
            $mopI18n,
            customerModelRef.value.getCommercetoolsCustomerGroupId(),
          ),
        },
      },
      categoryKeysListRef.value,
    );
    productModelListRef.value = productListModel(productSearchResponseRef.value);
    loadingProductsRef.value.searchProductsBySkus = false;
  }

  function getChannelsQuery() {
    const channelIds = WAREHOUSE_TYPES.filter(
      (warehouseType) => $mopI18n.country === 'de' || warehouseType === 'ARVATO',
    ).map((warehouseType) => getWarehouseId(warehouseType));
    const channelsQueries: SearchQuery = {
      or: [
        {
          exact: {
            field: 'variants.attributes.sellableWithoutStock',
            fieldType: 'boolean',
            value: true,
          },
        },
        {
          exact: {
            field: 'variants.availability.isOnStockForChannel',
            values: channelIds.length ? channelIds : [''],
          },
        },
      ],
    };
    return channelsQueries;
  }

  function getFacetsQuery(): Record<string, SearchQuery | { or: SearchQuery[] }> | undefined {
    if (!searchableAttributes.value) {
      return;
    }
    let hasValue = false;
    const attributes: Record<string, SearchQuery | { or: SearchQuery[] }> = {};
    appliedFiltersRef.value?.forEach((appliedFilter: FilterModel) => {
      const appliedFilterValues: FilterValueModel[] = appliedFilter.getValues();
      const attr = searchableAttributes.value![appliedFilter.getRawName()];
      const field = getQueryfield(attr);
      const attributeValues: SearchQuery[] = appliedFilterValues.map((filterValue: FilterValueModel) => {
        let query;
        const filterKey = filterValue.getKey();
        if (filterKey === 'prices') {
          query = {
            range: {
              field: attr.field,
              gte: filterValue.getMin(),
              lte: filterValue.getMax(),
            },
          };
        } else {
          query = {
            exact: {
              field,
              fieldType: attr.fieldType,
              language: $mopI18n.lang,
              value: filterKey,
            },
          };
        }

        return query;
      });

      if (attributeValues[0]) {
        hasValue = true;
        if (appliedFilter.isPriceRange()) {
          attributes['variants.prices.currencyCode'] = {
            exact: {
              field: 'variants.prices.currencyCode',
              value: $mopI18n.currency,
            },
          };
        }

        attributes[field] = attributeValues.length > 1 ? { or: attributeValues } : attributeValues[0];
      }
    });

    return hasValue ? attributes : undefined;
  }

  function getSortQuery() {
    if (!appliedSortingRef.value) {
      return defaultSorting;
    }
    const sort: SearchSorting[] = [
      {
        field: appliedSortingRef.value.sortValue,
        order: appliedSortingRef.value.sortDirectionValue ?? 'asc',
        mode: appliedSortingRef.value.mode,
        fieldType: appliedSortingRef.value.fieldType,
      },
      {
        field: 'key',
        order: 'desc',
      },
    ];
    return sort;
  }

  function getSortedQuery(query: LocationQuery, filter: FilterModel) {
    const appliedFilters: FilterModel[] = appliedFiltersRef.value?.slice() || [];
    const isFilterApplied: boolean = appliedFilters.some(
      (appliedFilter: FilterModel) => appliedFilter.getSlug() === filter.getSlug(),
    );
    if (!isFilterApplied) {
      appliedFilters.push(filter);
    }

    // Lowercase because of weird safari behaviour
    appliedFilters.sort((a, b) => a.getSlug().toLowerCase().localeCompare(b.getSlug().toLowerCase()));

    const filterQuery: LocationQuery = {};
    appliedFilters.forEach((appliedFilter) => {
      if (query[appliedFilter.getSlug()] !== '') {
        filterQuery[appliedFilter.getSlug()] = query[appliedFilter.getSlug()];
      }
      delete query[appliedFilter.getSlug()];
    });

    // Add to filter query in front of other parameters
    return { ...filterQuery, ...query };
  }

  async function searchProducts(
    params: {
      categoryId?: string;
      page?: number;
      applyFilters?: boolean;
      includeSaleProducts?: boolean;
    },
    cacheClienResponse = false,
  ) {
    loadingProductsRef.value.searchProducts = true;

    const page = params?.page || 1;
    const maxProductsPerPage = $mopConfig.getMaxProductsPerPage();

    const categoryQueries: SearchQuery[] = [];
    if (params.categoryId) {
      categoryQueries.push({
        exact: {
          field: 'categories',
          value: params.categoryId,
        },
      });
      if (params.includeSaleProducts === true) {
        categoryQueries.push({
          fullText: {
            field: 'variants.attributes.isOnSaleIn',
            fieldType: 'set_text',
            value: $mopI18n.commercetoolsCountry.toUpperCase(),
          },
        });
      } else if (params.includeSaleProducts === false) {
        categoryQueries.push({
          not: [
            {
              fullText: {
                field: 'variants.attributes.isOnSaleIn',
                fieldType: 'set_text',
                value: $mopI18n.commercetoolsCountry.toUpperCase(),
              },
            },
          ],
        });
      }
    }

    const query: SearchQuery[] = [...categoryQueries, getChannelsQuery()];

    const searchParams: ProductSearchParams = {
      body: {
        query: {
          and: query,
        },
        sort: getSortQuery(),
        productProjectionParameters: getProductProjectionParameters(
          $mopI18n,
          customerModelRef.value.getCommercetoolsCustomerGroupId(),
        ),
        limit: maxProductsPerPage,
        offset: (page - 1) * maxProductsPerPage,
      },
    };

    if (params.applyFilters) {
      const facetQuery = getFacetsQuery();
      const facetValues = facetQuery ? Object.values(facetQuery) : [];
      const facetKeys = facetQuery ? Object.keys(facetQuery) : [];
      if (facetValues?.length) {
        facetValues.push({
          exact: {
            field: 'variants.prices.country',
            value: $mopI18n.country.toUpperCase(),
          },
        });
        facetValues.push(getChannelsQuery());

        // @ts-ignore read only
        searchParams.body.postFilter = {
          and: [...facetValues],
        };

        // @ts-ignore read only
        searchParams.body.facets = Object.keys(searchableAttributes.value).map((attrKey) => {
          const attr = searchableAttributes.value![attrKey];
          const field = getQueryfield(attr);
          const facetQueryEntry = {
            distinct: {
              name: attrKey,
              field,
              fieldType: attr.fieldType,
              language: $mopI18n.lang,
              limit: attr.limit ?? 200,
              filter: attr.filter ?? undefined,
            },
          };

          if (facetQuery && facetKeys.length) {
            const filters = [];
            if (attr.filter) {
              filters.push(attr.filter);
            }
            facetKeys.forEach((facetField) => {
              if (facetField === field) {
                return;
              }
              filters.push(facetQuery[facetField]);
            });

            if (filters.length) {
              if (filters.length > 1) {
                facetQueryEntry.distinct.filter = { and: [...filters] };
              } else {
                facetQueryEntry.distinct.filter = { ...filters[0] };
              }
            }
          }

          return facetQueryEntry;
        });
      } else {
        // @ts-ignore read only
        searchParams.body.facets = Object.keys(searchableAttributes.value).map((attrKey) => {
          const attr = searchableAttributes.value![attrKey];
          return {
            distinct: {
              name: attrKey,
              field: getQueryfield(attr),
              fieldType: attr.fieldType,
              language: $mopI18n.lang,
              limit: attr.limit ?? 200,
              filter: attr.filter ?? undefined,
            },
          };
        });
      }
    }

    if (cacheClienResponse) {
      productSearchResponseRef.value ??= await getCachedResponse(searchParams, () =>
        $apiCommercetools.searchProducts(searchParams, categoryKeysListRef.value),
      );
    } else {
      productSearchResponseRef.value ??= await $apiCommercetools.searchProducts(
        searchParams,
        categoryKeysListRef.value,
      );
    }

    paginationModelRef.value = paginationModel(productSearchResponseRef.value);
    productModelListRef.value = productListModel(productSearchResponseRef.value);

    if (params.applyFilters) {
      if (categoryFiltersModelRef.value.isEmpty()) {
        lastCategoryIdRef.value = getCurrentCategoryWithFallbackToParent()?.getId() || '';
        categoryFiltersModelRef.value = filterListModel(productSearchResponseRef.value, searchableAttributes.value);
      }
      filtersModelRef.value = filterListModel(productSearchResponseRef.value, searchableAttributes.value);
    }

    loadingProductsRef.value.searchProducts = false;
  }

  function setAppliedSortingFromUrl(): void {
    const sortingParamValue = getQueryParamValue(constants.QUERY_PARAMETERS.SORT_QUERY);
    if (sortingParamValue === undefined) {
      appliedSortingRef.value = null;
      return;
    }

    appliedSortingRef.value = sortingOptions.find((sortingOption) => sortingOption.key === sortingParamValue) || null;
  }

  function addSorting(key: string) {
    const query = { ...useRoute().query };
    query.sort = key;
    if (query.page) {
      delete query.page;
    }

    const localeUrl = getLocalePathFromQuery(query);
    useRouter().push(localeUrl);
  }

  function removeSorting() {
    const query = { ...useRoute().query };
    if (query.page) {
      delete query.page;
    }
    if (query.sort) {
      delete query.sort;
    }
    const localeUrl = getLocalePathFromQuery(query);
    useRouter().push(localeUrl);
  }

  return securedWrap({
    productModelListRef,
    loadingProductsRef,
    loadingFiltersRef,
    paginationModelRef,
    getChannelsQuery,
    searchProducts,
    searchProductsByMopProductIds,
    searchProductsBySkus,
    filtersModelRef,
    categoryFiltersModelRef,
    initFilters,
    removeFilterValue,
    addFilterValue,
    removeAllFilters,
    appliedFiltersRef,
    updateProductCount,
    productCountRef,
    filterList: FILTER_KEYS.map((item) => item.toLowerCase()),
    sortingOptions,
    appliedSortingRef,
    setAppliedSortingFromUrl,
    addSorting,
    removeSorting,
  });
}
