import type {
  CategoryModel,
  MopCategoryData,
  MopCategoryResponseData,
  MopCategoryListResponseData,
  MopCategoryDataCustomFields,
  SortingType,
} from '@/types/category';
import type { Alternate } from '@/types/cms';
import type { CountryConfigModel } from '@/types/countryConfig';
import { localeList } from '@/i18n/localeList';

function getCategoryModelList(data: MopCategoryData[] | undefined, showHidden = false) {
  if (data === undefined) {
    return [];
  }

  return data.reduce((list: CategoryModel[], categoryData) => {
    const category: CategoryModel = categoryModel({ data: categoryData });
    if (showHidden || category.isVisible()) {
      list.push(category);
    }
    return list;
  }, []);
}

export function categoryModel(responseData: MopCategoryResponseData | null) {
  const response = responseData ?? {};
  let mobileMenuCategories: CategoryModel[];

  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    getDataSplit(): any {
      return response.data?.data?.split('|') ?? [];
    },

    getId(): string {
      return String(this.getDataSplit()[0] ?? '');
    },

    getPath(): string {
      const path = (this.getDataSplit()[1] ?? '').replace(/_/g, '/');
      return path[0] === '/' ? path : `/${path}`;
    },

    getName($mopConfig?: CountryConfigModel): string {
      const customName = $mopConfig?.getCategoryPreference(this.getMopId())?.customName;
      return customName || this.getDataSplit()[2] || '';
    },

    getDepth(): number {
      return parseInt(this.getDataSplit()[3]) || 0;
    },

    getMopId(): string {
      return this.getDataSplit()[4] ?? '';
    },

    isVisible() {
      return Boolean(parseInt(this.getDataSplit()[5] ?? '0'));
    },

    getProductCount() {
      return this.getDataSplit()[6] ? parseInt(this.getDataSplit()[6]) : undefined;
    },

    getSorting(sortingType: SortingType = 'default'): string {
      return `${this.getId()}-${sortingType}`;
    },

    isSaleCategory(): boolean {
      return this.getPath().includes(`/${constants.CATEGORY_SLUG.SALE}`);
    },

    getSlug(): string {
      return this.getPath().substring(this.getPath().lastIndexOf('/') + 1);
    },

    getUrl(): string {
      const redirect: string = this.getRedirect();
      if (redirect) {
        return redirect.indexOf('/') === 0 ? redirect : `/${redirect}`;
      }
      return `${this.getPath()}`;
    },

    getChildren(showHidden = false) {
      return getCategoryModelList(response.data?.children, showHidden);
    },

    hasChildren(): boolean {
      if (!response.data?.children?.length) {
        return false;
      }
      return response.data.children.some((child) => Boolean(parseInt((child.data.split('|') ?? [])[5] ?? '0')));
    },

    getCustomFieldValue(customFieldName: keyof MopCategoryDataCustomFields): string | number | boolean {
      return response.data?.[customFieldName] || '';
    },

    getExcludedCountries(): string {
      return response.data?.excludedCountries?.toLowerCase() || '';
    },

    getProductQueryIncludeProductsOnSale(): boolean | undefined {
      return response.data?.includeProductsOnSale;
    },

    hasMobileMenuCategories(): boolean {
      return this.getMobileMenuCategories().length > 0;
    },

    getMobileMenuCategories() {
      if (mobileMenuCategories !== undefined) {
        return mobileMenuCategories;
      }
      if (response.data?.children === undefined) {
        mobileMenuCategories = [];
        return mobileMenuCategories;
      }
      mobileMenuCategories = getCategoryModelList(response.data?.children);
      return mobileMenuCategories;
    },

    getAlternates(paramSlug = ''): Alternate[] {
      const alternates: Alternate[] = [];
      localeList.forEach((mopLocale) => {
        const country = mopLocale.code.split('-')[1];
        if (this.getExcludedCountries().includes(country)) {
          return;
        }
        alternates.push({
          href: `/${response.data?.slug?.[mopLocale.lang]?.replace(/_/g, '/') || ''}${paramSlug}`,
          lang: mopLocale.code,
        });
      });
      return alternates;
    },

    getRedirect(): string {
      return response.data?.redirect?.trim() ?? '';
    },

    isHomeCategory(): boolean {
      return ['men', 'women'].includes(this.getMopId());
    },

    getDebugData(): MopCategoryData | undefined {
      return response.data;
    },
  };
}

export function categoryListModel(responseData: MopCategoryListResponseData | null) {
  const response = responseData ?? {};

  function flattenListOfObjects(list: MopCategoryData[] | undefined) {
    if (!list) {
      return [];
    }
    const result: MopCategoryData[] = [];

    function recurse(node: MopCategoryData) {
      const { children, ...nodeWithoutChildren } = node;
      result.push(nodeWithoutChildren);
      if (children && children.length > 0) {
        children.forEach((child) => recurse(child));
      }
    }

    list.forEach((obj) => recurse(obj));
    return result;
  }

  return {
    getCategoryModelList(showHidden = false): CategoryModel[] {
      return getCategoryModelList(response.data, showHidden);
    },

    getFlattenCategoryModelList(showHidden = false) {
      const list = flattenListOfObjects(responseData?.data);
      return getCategoryModelList(list, showHidden);
    },
  };
}
