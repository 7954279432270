import type { AxiosError } from 'axios';
import type { CentPrecisionMoney } from '@commercetools/platform-sdk';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { CartLineItemCustomFields } from '@/types/cart';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type {
  BffApiCartsAddLineItemPost,
  BffApiCartsApplyGiftCardPost,
  BffApiCartsAssignCustomerBillingAddressPut,
  BffApiCartsAssignCustomerShippingAddressPut,
  BffApiCartsChangeLineItemQuantityPost,
  BffApiCartsCurrentOrdersPost,
  BffApiCartsGet,
  BffApiCartsNewsletterSubscriptionPost,
  BffApiCartsPaymentMethodsPost,
  BffApiCartsRemoveGiftCardPost,
  BffApiCartsRemoveLineItemPost,
  BffApiCartsSetGuestBillingAddressPost,
  BffApiCartsSetGuestShippingAddressPost,
  BffApiCartsSetPaymentMethodPost,
  BffApiCartsSetShippingMethodPost,
  BffApiCartsShippingMethodsGet,
  BffApiCartsSubmitAdyenAdditionalPaymentDetailsPost,
  BffApiCartsSubmitAdyenPaymentPost,
  BffApiCartsSubmitArvatoPaymentPost,
  BffApiCartsVouchers,
  BffCartAddress,
  BffShippingMethod,
  BffApiPaymentsCheckStatus,
  BffApiCartsEmployeeIdPost,
} from '@/types/bff';
const API_NAME = 'AWS - BFF CART';

export function getCart(
  apiConfig: ApiBffConfig,
  params?: { recalculate?: boolean },
): Promise<ApiResponse<BffApiCartsGet>> {
  const url = getBffUrlWithParams(`/carts${params?.recalculate ? '?recalculate=true' : ''}`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getCart',
      url,
    }),
  }));
}

export function addCartItem(
  apiConfig: ApiBffConfig,
  params: { sku: string; quantity: number; supplyChannelId: string; customFields?: CartLineItemCustomFields },
): Promise<ApiResponse<BffApiCartsAddLineItemPost>> {
  const url = getBffUrlWithParams('/carts/add-line-item', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsAddLineItemPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'addCartItem',
      url,
    }),
  }));
}

export function updateCartItem(
  apiConfig: ApiBffConfig,
  params: { sku: string; quantity: number },
): Promise<ApiResponse<BffApiCartsChangeLineItemQuantityPost>> {
  const url = getBffUrlWithParams('/carts/change-line-item-quantity', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsChangeLineItemQuantityPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateCartItem',
      url,
    }),
  }));
}

export function removeCartItem(
  apiConfig: ApiBffConfig,
  params: { sku: string },
): Promise<ApiResponse<BffApiCartsRemoveLineItemPost>> {
  const url = getBffUrlWithParams('/carts/remove-line-item', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsRemoveLineItemPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'removeCartItem',
      url,
    }),
  }));
}

export function submitArvatoPayment(
  apiConfig: ApiBffConfig,
  params: {
    paymentMethod: string;
    paymentAmount: CentPrecisionMoney;
  },
): Promise<ApiResponse<BffApiCartsSubmitArvatoPaymentPost>> {
  const url = getBffUrlWithParams('/carts/submit-arvato-payment', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSubmitArvatoPaymentPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'submitArvatoPayment',
      url,
    }),
  }));
}

export function getPaymentMethods(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiCartsPaymentMethodsPost>> {
  const url = getBffUrlWithParams('/carts/payment-methods', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsPaymentMethodsPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, null, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getPaymentMethods',
      url,
    }),
  }));
}

export function setPaymentMethod(
  apiConfig: ApiBffConfig,
  params: { paymentMethod: string },
): Promise<ApiResponse<BffApiCartsSetPaymentMethodPost>> {
  const url = getBffUrlWithParams('/carts/set-payment-method', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSetPaymentMethodPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setPaymentMethod',
      url,
    }),
  }));
}

export function submitGiftCardPayment(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiCartsCurrentOrdersPost>> {
  const url = getBffUrlWithParams('/carts/current/orders', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsCurrentOrdersPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, null, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'submitGiftCardPayment',
      url,
    }),
  }));
}

export function submitAdyenPayment(
  apiConfig: ApiBffConfig,
  params: {
    returnUrl: string;
    riskData: any; // adyen auto param
    clientStateDataIndicator: boolean; // adyen auto param
    paymentMethod: any; // adyen auto param
    paymentAmount: CentPrecisionMoney;
  },
): Promise<ApiResponse<BffApiCartsSubmitAdyenPaymentPost>> {
  const url = getBffUrlWithParams('/carts/submit-adyen-payment', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSubmitAdyenPaymentPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'submitAdyenPayment',
      url,
    }),
  }));
}
export function submitAdyenPaymentAdditionalDetails(
  apiConfig: ApiBffConfig,
  params: {
    paymentId: string;
    details: any; // adyen auto param
  },
): Promise<ApiResponse<BffApiCartsSubmitAdyenAdditionalPaymentDetailsPost>> {
  const url = getBffUrlWithParams('/carts/submit-adyen-additional-payment-details', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSubmitAdyenAdditionalPaymentDetailsPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'submitAdyenPaymentAdditionalDetails',
      url,
    }),
  }));
}

export function getPaymentCheckStatus(
  apiConfig: ApiBffConfig,
  params: {
    paymentId: string;
  },
): Promise<ApiResponse<BffApiPaymentsCheckStatus>> {
  const url = getBffUrlWithParams(`/payments/${params.paymentId}/check-status`, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiPaymentsCheckStatus>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getPaymentCheckStatus',
      url,
    }),
  }));
}

export function setGuestShippingAddress(
  apiConfig: ApiBffConfig,
  address: BffCartAddress,
  skipAddressCheck = false,
): Promise<ApiResponse<BffApiCartsSetGuestShippingAddressPost>> {
  let path = '/carts/set-guest-shipping-address';
  if (skipAddressCheck) {
    path = `${path}?skipAddressCheck=true`;
  }
  const url = getBffUrlWithParams(path, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSetGuestShippingAddressPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, address, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setGuestShippingAddress',
      url,
    }),
  }));
}

export function setGuestBillingAddress(
  apiConfig: ApiBffConfig,
  address: BffCartAddress,
  skipAddressCheck = false,
): Promise<ApiResponse<BffApiCartsSetGuestBillingAddressPost>> {
  let path = '/carts/set-guest-billing-address';
  if (skipAddressCheck) {
    path = `${path}?skipAddressCheck=true`;
  }
  const url = getBffUrlWithParams(path, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSetGuestBillingAddressPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, address, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setGuestBillingAddress',
      url,
    }),
  }));
}

export function assignCustomerShippingAddress(
  apiConfig: ApiBffConfig,
  addressId: string,
): Promise<ApiResponse<BffApiCartsAssignCustomerShippingAddressPut>> {
  const url = getBffUrlWithParams('/carts/assign-customer-shipping-address', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsAssignCustomerShippingAddressPut>>(async () => ({
    data: (
      await apiConfig.apiClient.put(
        url,
        { addressId },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'assignCustomerShippingAddress',
      url,
    }),
  }));
}

export function assignCustomerBillingAddress(
  apiConfig: ApiBffConfig,
  addressId: string,
): Promise<ApiResponse<BffApiCartsAssignCustomerBillingAddressPut>> {
  const url = getBffUrlWithParams('/carts/assign-customer-billing-address', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsAssignCustomerBillingAddressPut>>(async () => ({
    data: (
      await apiConfig.apiClient.put(
        url,
        { addressId },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'assignCustomerBillingAddress',
      url,
    }),
  }));
}

export function getShippingMethods(
  apiConfig: ApiBffConfig,
  lang: string,
): Promise<ApiResponse<BffApiCartsShippingMethodsGet>> {
  const url = getBffUrlWithParams('/carts/shipping-methods', apiConfig);
  const mapLocalisedShippingValues = (responseData: { shippingMethods: BffShippingMethod[] }, lang: string) => {
    responseData?.shippingMethods?.forEach((result) => {
      // @ts-ignore readonly attr
      result.name = result.localizedName?.[lang] || result.localizedName?.en || result.name || '';
    });

    return responseData;
  };

  return bffErrorHandler<ApiResponse<BffApiCartsShippingMethodsGet>>(async () => ({
    data: mapLocalisedShippingValues(
      (
        await apiConfig.apiClient.get(url, {
          withCredentials: true,
        })
      ).data,
      lang,
    ),
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getShippingMethods',
      url,
    }),
  }));
}

export function setShippingMethod(
  apiConfig: ApiBffConfig,
  shippingMethodId: string,
): Promise<ApiResponse<BffApiCartsSetShippingMethodPost>> {
  const url = getBffUrlWithParams('/carts/set-shipping-method', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsSetShippingMethodPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          shippingMethodId,
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setShippingMethod',
      url,
    }),
  }));
}

export function assignGiftCardPayment(
  apiConfig: ApiBffConfig,
  giftcardId: string,
): Promise<ApiResponse<BffApiCartsApplyGiftCardPost>> {
  const url = getBffUrlWithParams('/carts/apply-gift-card', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsApplyGiftCardPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          giftcardId,
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'assignGiftCardPayment',
      url,
    }),
  }));
}
export function unassignGiftCardPayment(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiCartsRemoveGiftCardPost>> {
  const url = getBffUrlWithParams('/carts/remove-gift-card', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsRemoveGiftCardPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, null, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'unassignGiftCardPayment',
      url,
    }),
  }));
}

export function setCartNewsletterSubscription(
  isSubscribed: boolean,
  apiConfig: ApiBffConfig,
): Promise<ApiResponse<BffApiCartsNewsletterSubscriptionPost>> {
  const url = getBffUrlWithParams('/carts/newsletter-subscription', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiCartsNewsletterSubscriptionPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          subscribeNewsletter: isSubscribed,
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setCartNewsletterSubscription',
      url,
    }),
  }));
}

export function addVoucher(
  apiConfig: ApiBffConfig,
  params: { email: string; code: string },
): Promise<ApiResponse<BffApiCartsVouchers>> {
  const url = getBffUrlWithParams('/carts/current/vouchers', apiConfig);

  return bffErrorHandler<ApiResponse<BffApiCartsVouchers>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'addVoucher',
      url,
    }),
  }));
}

export function removeVoucher(
  apiConfig: ApiBffConfig,
  params: { code: string },
): Promise<ApiResponse<BffApiCartsVouchers>> {
  const url = getBffUrlWithParams(`/carts/current/vouchers/${params.code}`, apiConfig);

  return bffErrorHandler<ApiResponse<BffApiCartsVouchers>>(async () => ({
    data: (
      await apiConfig.apiClient.delete(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'removeVoucher',
      url,
    }),
  }));
}

export function setCartEmployeeId(
  apiConfig: ApiBffConfig,
  employeeId: string,
): Promise<ApiResponse<BffApiCartsEmployeeIdPost>> {
  const url = getBffUrlWithParams('/carts/employee-id', apiConfig);

  return bffErrorHandler<ApiResponse<BffApiCartsEmployeeIdPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        { employeeId },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'setCartEmployeeId',
      url,
    }),
  }));
}
