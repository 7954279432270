import { logError } from '@mop/shared/utils/logger';
import { isClient } from '@mop/shared/utils/util';
import { sessionStorageGet, sessionStorageSet } from '@mop/shared/utils/sessionStorage';

export default defineNuxtPlugin((nuxtApp) => {
  if (isClient) {
    useRouter().onError(() => performRefresh());

    // triggered from the onErrorCaptured hook in layout
    nuxtApp.hook('vue:error', (error: unknown) => {
      if (
        error instanceof Error &&
        error.message.includes(ERROR_TYPE.MISSING_ASSET) &&
        error.message.includes('/_nuxt')
      ) {
        performRefresh();
      }
    });

    window.addEventListener(
      'error',
      function (event: any) {
        event.preventDefault();
        event.stopPropagation();
        const isMopScriptError = event.target?.src?.includes(`/_nuxt`) || event.target?.href?.indexOf(`/_nuxt`);
        if (!isMopScriptError) {
          return;
        }

        performRefresh();
      },
      true,
    );

    function performRefresh() {
      const lastCheckTime: number | undefined =
        parseInt(sessionStorageGet('ServerCodeVersionCheckTime') || '') || undefined;
      // 5 mins
      if (!lastCheckTime || Date.now() - lastCheckTime > 300000) {
        sessionStorageSet('ServerCodeVersionCheckTime', String(Date.now()));
        logError(`Page reload. Version: ${nuxtApp.$config.public.VERSION}`);
        location.reload();
      }
    }
  } else {
    nuxtApp.hook('app:error', (error) => {
      // @ts-ignore
      if (process.logMonitoring) {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: error,
        });
      }
    });

    nuxtApp.hook('vue:error', (error) => {
      // @ts-ignore
      if (process.logMonitoring) {
        // @ts-ignore
        process.logMonitoring.trackException({
          exception: error,
        });
      }
    });
  }
});
