<script setup lang="ts">
import SVGMopLogoSignet from '@mop/shared/images/logo/logo_mop_signet.svg?component';

defineOptions({
  name: 'MopHeaderNavigationOverlay',
});

const { $urls, $rootCategories } = useNuxtApp();
const rootCategoriesRef = computed(() => $rootCategories.getCategoryModelList());
const { customerModelRef } = useMopCustomer();
const newsletterUrl = !customerModelRef.value.isGuest()
  ? $urls.ACCOUNT_CONTACT_SETTINGS
  : $urls.NEWSLETTER_REGISTRATION;
const navigation = useMopNavigationClient();
</script>

<template>
  <nav class="navigation-overlay">
    <MopHeaderSubNavigation
      v-for="category in rootCategoriesRef"
      :key="category.getMopId()"
      :parent-category="category"
      :level="1"
      :class="[
        'navigation-overlay__subnavigation',
        {
          'navigation-overlay__subnavigation--opened': navigation.isSubNavigationOpen(category.getMopId()),
        },
      ]"
    />

    <ul class="navigation-overlay__footer">
      <li>
        <NuxtLink v-if="$mopConfig.isLoyaltyProgramEnabled()" :to="$mopI18n.localePath($urls.MEMBERS)" no-prefetch>
          {{ $mopI18n.t('common.members') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="$mopI18n.localePath(newsletterUrl)" no-prefetch>
          {{ $mopI18n.t('common.newsletter') }}
        </NuxtLink>
      </li>
      <li>
        <NuxtLink :to="$config.public.HOME_URL" no-prefetch :title="$mopI18n.t('common.brandName')">
          <SVGMopLogoSignet class="navigation-overlay__footer-logo" width="33" height="33" />
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.navigation-overlay__subnavigation {
  left: 0;
  padding: 0 0 $space20 $global-padding;
  position: absolute;
  white-space: nowrap;
  display: none;
  flex-direction: column;
}

.navigation-overlay__subnavigation--opened {
  display: flex;
  background-color: $white;
}

.navigation-overlay__footer {
  --color: #{$black};

  position: fixed;
  bottom: 20px;
  left: 20px;
}

.navigation-overlay__footer a {
  @include text-style(strong);
  @include link-not-underlined(0);

  text-transform: uppercase;
}

.navigation-overlay__footer-logo {
  margin-top: $space15;
}
</style>
