import { isClient, createCanonicalTag } from '@mop/shared/utils/util';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import LogoSVG from '@mop/shared/images/logo/logo_mop.svg?url';
import type { Alternate, CmsStoryModel, SeoBreadcrumb } from '@/types/cms';
import type { ProductModel, VariantModel } from '@/types/product';
import type { CategoryModel } from '@/types/category';
import type { LocaleObject } from '@/types/locale';
import type { SeoHeadParameters } from '@/types';

type SeoComposableStorage = {
  alternates: Alternate[];
};

function createDescriptionTag(description: string) {
  if (!description) {
    return;
  }
  return {
    name: 'description',
    content: description,
  };
}

function createRobotsTag(robots: string) {
  if (!robots) {
    return;
  }
  return {
    name: 'robots',
    content: robots,
  };
}

function createAlternateHtmlTag(hreflang: string, href: string) {
  const alternateElement: HTMLLinkElement = document.createElement('link');
  alternateElement.rel = 'alternate';
  alternateElement.hreflang = hreflang;
  alternateElement.href = href;
  return alternateElement;
}

function createAlternates(baseUrl: string, alternates: Alternate[]) {
  if (!isClient || !alternates || alternates.length === 0) {
    return [];
  }

  const DEFAULT_LANGUAGE = 'en-IE';
  const additionalEntries: { [key: string]: string } = {
    'de-DE': 'de',
    'fr-FR': 'fr',
    'nl-NL': 'nl',
    [DEFAULT_LANGUAGE]: 'en',
  };

  const overrideEntries: { [key: string]: string } = {
    'cs-CZ': 'cs',
    'pl-PL': 'pl',
    'it-IT': 'it',
    'es-ES': 'es',
  };

  const alternateHtmlTags: HTMLLinkElement[] = alternates.flatMap((alternate: Alternate) => {
    const [lang, country] = alternate.lang?.split('-');
    const langCountry = `${lang}-${country.toUpperCase()}`;
    const overrideEntry = overrideEntries[langCountry];
    const hreflang = overrideEntry || langCountry;
    const result = [createAlternateHtmlTag(hreflang, `${baseUrl}/${alternate.lang}${alternate.href}`)];
    const additionalEntry = additionalEntries[langCountry];
    if (additionalEntry) {
      result.push(createAlternateHtmlTag(additionalEntry, `${baseUrl}/${alternate.lang}${alternate.href}`));
    }
    return result;
  });

  const defaultAlternate = alternates.find((alternate) => alternate.lang === DEFAULT_LANGUAGE.toLowerCase());

  if (defaultAlternate) {
    alternateHtmlTags.push(
      createAlternateHtmlTag('x-default', `${baseUrl}/${defaultAlternate.lang}${defaultAlternate.href}`),
    );
  }

  // Load alternate tags on client only, to avoid cache issues
  const existingHtmlTags: HTMLLinkElement[] = [].slice.call(document.head.querySelectorAll('[rel="alternate"]'));
  existingHtmlTags.forEach((existingHtmlTag) => existingHtmlTag.remove());
  if (typeof document.head.append === 'function') {
    document.head.append(...alternateHtmlTags);
  }
}

export default function useMopSeo() {
  const storage = initStorage<SeoComposableStorage>('useSeo');
  const { $mopI18n, $storyblokLivePreview, $mopConfig } = useNuxtApp();
  const route = useRoute();
  const config = useRuntimeConfig();

  const SEO_BASE_URL = config.public.SEO_BASE_URL || config.public.BASE_URL;

  function getAlternates(): Alternate[] {
    return storage.get('alternates') || ([] as Alternate[]);
  }

  function setAlternates(alternates: Alternate[]) {
    storage.saveAndGet('alternates', alternates);
  }

  function getHeadObject(seoHeadParameters: SeoHeadParameters) {
    const { title, description, isIndexable = true } = seoHeadParameters;
    const canonical = decodeURIComponent(seoHeadParameters.canonical || '');
    let { alternates = [] } = seoHeadParameters;

    const descriptionObject = createDescriptionTag(description);
    let metaRobots: string = constants.META.NOT_INDEXABLE;
    let metaCanonicalObject;
    const localeList = $mopI18n.localeList;
    if (isIndexable && config.public.IS_INDEXBLE) {
      // only pages that are indexable, should have a canonical and alternates href langs.
      metaRobots = constants.META.INDEXABLE;
      metaCanonicalObject = createCanonicalTag(SEO_BASE_URL, canonical);
      if (alternates.length === 0) {
        const canonicalWithoutLocale = canonical.substring(6);
        alternates = $mopI18n.localeList.map((locale: LocaleObject) => ({
          href: canonicalWithoutLocale,
          lang: locale?.code ?? '',
        }));
      }
      if (alternates.length > 0 && isProductionBuild && !$storyblokLivePreview.isEnabled) {
        alternates = alternates!.filter((alternate) => {
          const locale = localeList.find((locale) => locale.code === alternate.lang);
          return !locale?.isDisabled;
        });
      }
      const hrefLangAlternates = alternates!.filter(
        (alternate) => !constants.DISALLOWED_ALTERNATE_SLUGS.includes(alternate.lang),
      );
      const isCanonicalPartOfAlternates =
        hrefLangAlternates?.some((alternate) => `/${alternate.lang}${alternate.href}` === canonical) ?? false;
      const isCanonicalUrlBrowserUrl = canonical === route.fullPath;
      if (isCanonicalPartOfAlternates && isCanonicalUrlBrowserUrl) {
        createAlternates(SEO_BASE_URL, hrefLangAlternates);
      }
    }

    const robotsObject = createRobotsTag(metaRobots);

    return {
      title,
      meta: [{ ...descriptionObject }, { ...robotsObject }],
      link: [{ ...metaCanonicalObject }],
      script: [] as any,
    };
  }

  function handleHeadForCmsPage(cmsStoryModel: CmsStoryModel) {
    const seoData = cmsStoryModel?.getSeo();
    const canonical = cmsStoryModel?.getCanonical() || route.path;
    const alternates = cmsStoryModel?.getAlternates();
    setAlternates(alternates);
    const storyName = cmsStoryModel?.getName() || '';
    const title = seoData?.title || storyName || `${$mopI18n.t('common.meta.default.title')}`;
    const description = seoData?.description || `${$mopI18n.t('common.meta.default.description')} - ${storyName}`;
    const isIndexable =
      !constants.DISALLOWED_ALTERNATE_SLUGS.includes($mopI18n.locale) && seoData?.isIndexable !== false;

    return getHeadObject({
      title,
      description,
      isIndexable,
      canonical,
      alternates,
    });
  }

  function getBreadcrumbSchema(breadcrumbs: SeoBreadcrumb[]) {
    return {
      type: 'application/ld+json',
      children: {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs.map((breadcrumb, i) => {
          return {
            '@type': 'ListItem',
            position: ++i,
            name: breadcrumb.name,
            item: `${SEO_BASE_URL}${$mopI18n.localePath(breadcrumb.url)}`,
          };
        }),
      },
    };
  }

  function getProductSchema(product: ProductModel, variant: VariantModel, category: CategoryModel) {
    const url = `${SEO_BASE_URL}${$mopI18n.localePath(product.getUrl())}`;

    return {
      type: 'application/ld+json',
      children: {
        '@context': 'https://schema.org',
        '@type': 'Product',
        audience: {
          '@type': 'Audience',
          audienceType: $mopI18n.t(product.isMen() ? 'common.men' : 'common.women').toString(),
        },
        brand: {
          '@type': 'Brand',
          name: $mopI18n.t('common.brandName').toString(),
        },
        category: category.getName($mopConfig),
        color: product.getRefinementColorName(),
        productID: product.getMopMasterId(),
        sku: variant.getSku(),
        gtin13: variant.getSku(),
        description: product.getLongDescription(),
        image: product.getStandardImage(),
        name: product.getCompositeName(),
        url,
        offers: {
          '@type': 'Offer',
          priceCurrency: product.getPrice().currency,
          price: product.getPrice().salePrice,
          url,
          availability: `http://schema.org/${variant.getAvailability().isInStock ? 'InStock' : 'OutOfStock'}`,
          priceValidUntil: '',
          seller: {
            '@type': 'Organization',
            name: product.getBrandName(),
          },
          itemCondition: 'http://schema.org/NewCondition',
        },
      },
    };
  }

  function getHomepageSchemas() {
    const url = `${SEO_BASE_URL}/${$mopI18n.locale}`;
    return [
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: SEO_BASE_URL,
          logo: LogoSVG,
        },
      },
      {
        type: 'application/ld+json',
        children: {
          '@context': 'https://schema.org',
          '@type': 'WebSite',
          url,
          potentialAction: {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: url + '/search?q={search_term_string}',
            },
            'query-input': 'required name=search_term_string',
          },
        },
      },
    ];
  }

  return securedWrap({
    getAlternates,
    setAlternates,
    getHeadObject,
    handleHeadForCmsPage,
    getBreadcrumbSchema,
    getProductSchema,
    getHomepageSchemas,
  });
}
