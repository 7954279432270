<script setup lang="ts">
import type { CategoryModel } from '@/types/category';

const PARENT_ITEM_HEIGHT = 20;
const PARENT_ITEM_HEIGHT_LARGE = 28;

defineOptions({
  name: 'MopHeaderSubNavigation',
});

const props = defineProps({
  parentCategory: {
    type: Object as PropType<CategoryModel>,
    required: true,
  },
  level: {
    type: Number,
    default: 0,
  },
});

const { $gtm2, $resize, $mopConfig, $mopI18n } = useNuxtApp();
const router = useRouter();
const headerOverlay = useMopOverlay();
const subNavigationRef = ref<HTMLElement>();
// center subnavigation regarding parent menu item, but not higher than relative container
const marginTop = computed<number>(() => {
  // for proper calculation of position we need to consider parent line height, hardcoded to avoid additional calculations in every component
  const parentMenuLineHeight = props.level === 1 ? PARENT_ITEM_HEIGHT_LARGE : PARENT_ITEM_HEIGHT;

  if (!subNavigationRef.value?.clientHeight) {
    return 0;
  }

  let marginTop = -Math.min(
    (subNavigationRef.value?.clientHeight ?? 0) / 2 - parentMenuLineHeight / 2,
    subNavigationRef.value?.offsetTop ?? 0,
  );

  // if element cropped by bottom (possible for very low hight scerens) - place it on the top
  if (
    subNavigationRef.value &&
    subNavigationRef.value.getBoundingClientRect().bottom > $resize.viewportHeightRef.value
  ) {
    marginTop = -subNavigationRef.value?.offsetTop;
  }
  return marginTop;
});

const navigation = useMopNavigationClient();
const { activeCategoryPathRef } = useMopRouter();

function isActiveCategory(categoryId: string) {
  return activeCategoryPathRef.value.find((category) => category.getMopId() === categoryId);
}

function isSubNavigationOpen(mopCategoryId: string) {
  return (
    navigation.isSubNavigationOpen(mopCategoryId) ||
    (isActiveCategory(mopCategoryId) && !navigation.isOpenAtLevel(props.level))
  );
}

function handleClick(category: CategoryModel) {
  $gtm2.reportLegacyEngagement({
    event: 'navigation',
    category: $gtm2.getCurrentPage(),
    label: category.getPath(),
    parameter3: $gtm2.getPageCategoryId(),
    parameter4: category.getName($mopConfig),
    parameter5: category.getDepth().toString(),
  });
  if (isTouchDevice() && !navigation.isSubNavigationOpen(category.getMopId()) && category.hasChildren()) {
    navigation.openSubNavigation(category);
    return;
  }
  navigation.closeAll();
  headerOverlay.closeAll();
  router.push($mopI18n.localePath(category.getUrl()));
}
</script>

<template>
  <ul
    ref="subNavigationRef"
    :class="[
      'mop-header-subnavigation',
      {
        'mop-header-subnavigation--large': level === 1,
      },
    ]"
    :style="`--margin-top: ${marginTop}px`"
    data-cy="header-sub-navigation-links"
  >
    <li
      v-if="level > 1 && parentCategory.getChildren().length > 1"
      class="mop-header-subnavigation__item mop-header-subnavigation__item--show-all"
    >
      <a
        :href="$mopI18n.localePath(parentCategory.getUrl())"
        class="mop-header-subnavigation__item-link"
        @click.prevent="handleClick(parentCategory)"
        @mouseenter="navigation.openSubNavigation(parentCategory)"
        @mouseleave="navigation.stopOpening()"
      >
        {{ $mopI18n.t('common.show_all') }}
      </a>
    </li>

    <li
      v-for="category of parentCategory.getChildren()"
      :key="category.getMopId()"
      :class="[
        'mop-header-subnavigation__item',
        {
          'mop-header-subnavigation__item--has-offset':
            $mopConfig.getCategoryPreference(category.getMopId()).addSpaceAboveMenuItem === true,
        },
      ]"
    >
      <MopHeaderSubNavigation
        v-if="category.hasChildren() && isSubNavigationOpen(category.getMopId())"
        :parent-category="category"
        :level="level + 1"
      />

      <a
        :href="$mopI18n.localePath(category.getUrl())"
        :class="[
          'mop-header-subnavigation__item-link',
          {
            'mop-header-subnavigation__item-link--large': level === 1,
            'mop-header-subnavigation__item-link--active': isActiveCategory(category.getMopId()),
          },
        ]"
        :data-cy="`header-sub-navigation-link-${category.getMopId()}`"
        :style="[
          {
            '--color': $mopConfig.getCategoryPreference(category.getMopId()).textColor || undefined,
          },
        ]"
        @click.prevent="handleClick(category)"
        @mouseenter="() => !isTouchDevice() && navigation.openSubNavigation(category)"
        @mouseleave="() => !isTouchDevice() && navigation.stopOpening()"
      >
        {{ category.getName($mopConfig) }}
        <MopHeaderCategorySubscripts v-if="level > 0" :category="category" class="mop-header-subnavigation__flag" />
      </a>

      <MopHeaderNavigationArrow
        v-show="category.hasChildren()"
        :is-visible="isSubNavigationOpen(category.getMopId())"
        :is-large="level === 1"
      />
    </li>
  </ul>
</template>

<style scoped lang="scss">
.mop-header-subnavigation {
  position: absolute;
  left: 100%;
  background-color: $white;
  margin-top: var(--margin-top);

  &::after,
  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: inherit;
  }

  &::before {
    top: auto;
    bottom: 100%;
  }
}

.mop-header-subnavigation--large {
  margin-top: 0;
}

.mop-header-subnavigation__item {
  padding-left: $space10;
  padding-right: $space30;
}

.mop-header-subnavigation--large > .mop-header-subnavigation__item {
  padding-left: 0;
}

.mop-header-subnavigation__item--has-offset {
  margin-top: $space10;
}

.mop-header-subnavigation__item-link {
  @include text-style(strong);
  @include link-not-underlined(0, left-right);

  line-height: 20px;
  text-transform: uppercase;
  vertical-align: middle;
}

.mop-header-subnavigation__flag {
  @include text-style(strong-small);

  text-transform: none;
  align-self: end;
  margin-left: $space5;
  font-size: 12px;
  line-height: 20px;
}

.mop-header-subnavigation__item--show-all {
  margin-bottom: $space10;
}

.mop-header-subnavigation__item-link--large {
  @include link-not-underlined(0, left-right);
  @include text-style(strong);

  text-transform: uppercase;
  letter-spacing: 0.011em;
  line-height: 1;
  // make extra line height by adding padding to keep proper position of underline
  padding: 4px 0;
  font-size: 24px;

  &::after {
    // overwrite default border size
    border-bottom-width: 2px;
  }

  .mop-header-subnavigation__flag {
    @include text-style(strong-small);
  }
}
</style>
