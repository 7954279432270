import type { ProductDiscountResponseData, ProductDiscountData } from '@/types/productDiscount';

export function productDiscountModel(discountData: ProductDiscountData) {
  const [keyType, keyAppearance] = (discountData.key || '').split('_');

  return {
    isActive() {
      return discountData.isActive === true;
    },

    getId() {
      return discountData.id || '';
    },

    getKey() {
      return discountData.key || '';
    },

    getName() {
      return discountData.name || '';
    },

    getDescription() {
      return discountData.description || '';
    },

    getCalloutFlag() {
      return {
        message: String(discountData.description || ''),
        appearance: ['default', 'sale', 'highlight', 'sustainability'].includes(keyAppearance)
          ? keyAppearance
          : 'default',
        showPriceOnlyInCart: keyType === 'cart',
      };
    },

    getValidFrom() {
      return discountData.validFrom;
    },

    getValidUntil() {
      return discountData.validUntil;
    },

    getDebugData() {
      return discountData;
    },
  };
}

export function productDiscountListModel(responseData: ProductDiscountResponseData | null) {
  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return responseData?.statusCode !== undefined && responseData.statusCode !== 200;
    },

    getDiscountModelList() {
      return responseData?.body?.results.map((discountData) => productDiscountModel(discountData)) ?? [];
    },

    getDebugData() {
      return responseData;
    },
  };
}
