// @ts-ignore
import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk/dist/commercetools-platform-sdk.browser.cjs';
// @ts-ignore
import { ClientBuilder } from '@commercetools/sdk-client-v2/dist/commercetools-sdk-client-v2.browser.cjs';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.provide('commercetools', {
    createApiBuilderFromCtpClient,
    ClientBuilder,
  });
});
