import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ssrCache } from '@/api/utils/ssrCache';
import { categoryListModel } from '@/models';
import type { MopCategoryListResponseData, CategoryListModel, CategoryModel } from '@/types/category';

type UseRootCategories = {
  rootCategoryListRef: Ref<CategoryListModel>;
  categoryKeysListRef: Ref<Record<string, string>>;
  searchRootCategories: () => Promise<void>;
};

type RootCategoryComposableStorage = {
  rootCategoryListRef: Ref<CategoryListModel>;
  categoryKeysListRef: Ref<Record<string, string>>;
};

export default function useMopRootCategories(): UseRootCategories {
  const nuxtApp = useNuxtApp();
  const storage = initStorage<RootCategoryComposableStorage>('useRootCategory');
  const cacheId = `root-categories-${nuxtApp.$mopI18n.locale}`;
  const redisCache = ssrCache();
  const responseRef = useMopSSR<MopCategoryListResponseData | null>(cacheId, null);
  const rootCategoryListRef =
    storage.get('rootCategoryListRef') ??
    storage.saveAndGet('rootCategoryListRef', ref(categoryListModel(responseRef.value)));
  const categoryKeysListRef = storage.get('categoryKeysListRef') ?? storage.saveAndGet('categoryKeysListRef', ref({}));

  /**
   * Called on app init.
   * Do not init elsewhere.
   */
  async function searchRootCategories() {
    responseRef.value ??= await redisCache.get<MopCategoryListResponseData>(
      cacheId,
      getEnrichedRootCategoriesCommercetools,
    );
    rootCategoryListRef.value = categoryListModel(responseRef.value);
    categoryKeysListRef.value = getCategoryKeysList();
  }

  async function getEnrichedRootCategoriesCommercetools() {
    return await nuxtApp.$apiCommercetools.queryCategoryTree();
  }

  function getCategoryKeysList(categoryList?: CategoryModel[], accumulatedList = {}): Record<string, string> {
    if (categoryList === undefined) {
      categoryList = rootCategoryListRef.value?.getCategoryModelList(true);
    }
    if (!categoryList.length) {
      return {};
    }
    return categoryList.reduce((acc: Record<string, string>, curr) => {
      acc[curr.getId()] = curr.getMopId();
      getCategoryKeysList(curr.getChildren(true), accumulatedList);
      return acc;
    }, accumulatedList);
  }

  return securedWrap({
    rootCategoryListRef,
    categoryKeysListRef,
    searchRootCategories,
  });
}
