import type { ClientResponse, LocalizedString, ProductTypePagedQueryResponse } from '@commercetools/platform-sdk';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { ssrCache } from '@/api/utils/ssrCache';
import type { MopSearchableAttribute } from '@/types/product';

type MopSearchableAttributesStorage = {
  searchableAttributes: Ref<MopSearchableAttribute | null>;
};

const NOT_AVAILABLE_LABEL = '--NA--'; // this is hardcoded together with backend to indicate not translated labels

export default function useMopSearchableAttributes() {
  const storage = initStorage<MopSearchableAttributesStorage>('useMopSearchableAttributes');
  const nuxtApp = useNuxtApp();
  const { lang, currency } = nuxtApp.$mopI18n;
  const cacheId = `searchable-attributes-${nuxtApp.$mopI18n.locale}`;
  const redisCache = ssrCache();

  const searchableAttributes =
    storage.get('searchableAttributes') ??
    storage.saveAndGet('searchableAttributes', useMopSSR<MopSearchableAttribute | null>(cacheId, null));

  /**
   * Called on app init.
   * Do not init elsewhere.
   */
  async function initSearchableAttributes() {
    searchableAttributes.value ??= (await redisCache.get<MopSearchableAttribute>(
      cacheId,
      getMappedSearchableAttributes,
    )) as MopSearchableAttribute;
  }

  async function getMappedSearchableAttributes() {
    return mapAttributes(await nuxtApp.$apiCommercetools.queryProductTypes());
  }

  function getLocalisedValue(value: LocalizedString) {
    const translatedValue = value[lang];
    return NOT_AVAILABLE_LABEL === translatedValue ? '' : value[lang];
  }

  function mapAttributes(responseData: ClientResponse<ProductTypePagedQueryResponse>) {
    const result = (responseData?.body?.results ?? []).reduce(
      (list: MopSearchableAttribute, item) => {
        const attributes = item?.attributes?.filter((attr) => attr.isSearchable) ?? [];
        attributes.forEach((attr) => {
          if (!FILTER_KEYS.includes(attr.name)) {
            return;
          }
          const fieldType = attr.type.name === 'set' ? attr.type.elementType.name : attr.type.name;

          let field = 'variants.attributes.' + attr.name;
          if (fieldType === 'lenum') {
            field += '.key';
          }
          if (attr.type.name === 'set' && attr.type.elementType.name === 'lenum') {
            const labelValues: Record<string, string> = {};
            attr.type.elementType.values.reduce((labelList, value) => {
              if (!value?.key || !value?.label) {
                return labelList;
              }
              const localisedLabel = getLocalisedValue(value.label);
              if (localisedLabel) {
                labelList[convertToSlug(value.key)] = localisedLabel;
              }
              return labelList;
            }, labelValues);

            list[attr.name] = {
              label: getLocalisedValue(attr.label),
              labelValues,
              field,
              fieldType,
              values: attr.type.elementType,
            };
          } else {
            list[attr.name] = {
              label: getLocalisedValue(attr.label),
              field,
              fieldType,
            };
          }
        });
        return list;
      },
      {
        prices: {
          label: 'prices',
          field: 'variants.prices.currentCentAmount',
          fieldType: 'money',
          limit: 200,
          filter: {
            exact: {
              field: 'variants.prices.currencyCode',
              value: currency,
            },
          },
        },
      },
    );
    return result;
  }

  return securedWrap({
    initSearchableAttributes,
    searchableAttributes,
  });
}
