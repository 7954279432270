import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { getExpirationInSeconds } from '@/api/utils/ssrCache';

const allowedScriptSrcElements = [
  'https://js.monitor.azure.com',
  'https://*.cloudfront.net',
  'https://*.amazonaws.com',
  'https://mop.azure-api.net',
  'https://*.googletagmanager.com',
  'https://*.googleapis.com',
  'https://*.sevensenders.com',
  'https://*.unown-fashion.de',
  'https://*.visualstudio.com',
  'https://*.marc-o-polo.com',
  // Google Tag Manager (GTM) / Marketing stuff
  'https://*.google.com',
  'https://*.gstatic.com',
  'https://*.doubleclick.net',
  'https://*.cdn-eso.me',
  'https://*.omnichannelengagementhub.com',
  'https://*.facebook.net',
  'https://*.facebook.com',
  'https://*.mediards.de',
  'https://*.mediards.com',
  'https://*.scarabresearch.com',
  'https://*.criteo.net',
  'https://*.criteo.com',
  'https://*.adnymics.com',
  'https://www.googlecommerce.com',
  'https://www.googleadservices.com',
  'https://*.pinimg.com',
  '*.outbrain.com',
  'https://bat.bing.com',
  'https://book.timify.com',
  'https://static.ads-twitter.com',
  'https://*.twitter.com',
  'https://*.stylight.net',
  'https://*.clarity.ms',
  'https://*.serving-sys.com',
  'https://*.abtasty.com',
  'https://*.bglobale.com',
  'https://*.global-e.com',
  'https://*.pinterest.com',
  'https://*.pinterest.de',
  'https://*.pinterest.fr',
  'https://*.pinterest.dk',
  'https://*.adscale.com',
  'https://*.google-analytics.com',
  'https://*.analytics.google.com',
  'https://*.epoq.de',
  'https://analytics.tiktok.com',
  'https://sc-static.net',
  'https://*.dwin1.com',
  'https://*.awin1.com',
  'https://*.sentry.io',
  'https://*.storyblok.com',
  'https://*.azureedge.net',
  'https://*.z01.azurefd.net',
  'https://*.fittingbox.com',
  'https://*.oppwa.com',
  'https://oppwa.com',
  'https://*.adyen.com',
  'https://*.przelewy24.pl',
  'https://przelewy24.pl',
  'https://*.paypal.com',
  'https://saiz-recommender-staging.com',
  'https://saiz-recommender.com',
  'https://*.arvato-scs.digital',
  'https://tags.creativecdn.com',
  'https://*.taboola.com',
  'https://*.usercentrics.eu',
  'https://*.realperson.cloud',
  'https://*.bloomreach.com',
  'wss://localhost:*',
];

const allowedFontSrcElements = ['https://*.marc-o-polo.com', 'https://*.realperson.cloud', 'https://*.z01.azurefd.net'];

const allowedStyleDomains = [
  'https://*.sevensenders.com',
  'https://*.googleapis.com',
  'https://*.googletagmanager.com',
  'https://*.vimeo.com',
  'https://*.unown-fashion.de',
  'https://*.azureedge.net',
  'https://*.z01.azurefd.net',
  'https://*.abtasty.com',
  'https://*.bglobale.com',
  'https://*.global-e.com',
  'https://*.oppwa.com',
  'https://oppwa.com',
  'https://*.adyen.com',
  'https://*.marc-o-polo.com',
  'https://*.sevensenders.com',
  'https://*.storyblok.com',
  'https://saiz-recommender-staging.com',
  'https://saiz-recommender.com',
  'https://*.arvato-scs.digital',
  'https://*.taboola.com',
  'https://*.usercentrics.eu',
  'https://*.realperson.cloud',
  'https://*.bloomreach.com',
];

const allowedFormActionDomains = [
  'https://*.global-e.com',
  'https://*.cardinalcommerce.com',
  'https://*.arcot.com',
  'https://*.przelewy24.pl',
  'https://przelewy24.pl',
  'https://*.ppipe.net',
  'https://*.paypal.com',
  'https://*.oppwa.com',
  'https://oppwa.com',
  'https://*.adyen.com',
  'https://*.marc-o-polo.com',
  'https://*.storyblok.com',
  'https://*.z01.azurefd.net',
];

export function headersMiddleware(route: RouteLocationNormalizedLoaded) {
  const {
    node: { req, res },
  } = useRequestEvent();

  let cacheControlValue = 'no-store, max-age=0';
  const isStoryblokLivePreview = Boolean(route.query._storyblok_lang);
  if (isCDNEnabled && !isStoryblokLivePreview) {
    let cacheMaxAgeInSeconds = route.meta.cacheMaxAgeInSeconds as number;
    const url = new URL('https://marc-o-polo.com' + req.url || '/');
    if (url.pathname.includes('damen/mop-denim') || url.searchParams.get('q')) {
      cacheMaxAgeInSeconds = 86400;
    }

    if (cacheMaxAgeInSeconds !== undefined) {
      const maxExpirationInSeconds = getExpirationInSeconds();
      if (cacheMaxAgeInSeconds > maxExpirationInSeconds) {
        cacheMaxAgeInSeconds = maxExpirationInSeconds;
      }
      cacheControlValue = `public, max-age=${cacheMaxAgeInSeconds}`;
    }
  }

  res.setHeader('Cache-Control', cacheControlValue);

  /* Security Headers */
  // feature to support on your site and strengthens your implementation of TLS by getting the User Agent to enforce the use of HTTPS.
  res.setHeader('Strict-Transport-Security', 'max-age=63072000; includeSubDomains; preload');
  // stops a browser from trying to MIME-sniff the content type and forces it to stick with the declared content-type.
  res.setHeader('X-Content-Type-Options', 'nosniff');
  res.setHeader('X-XSS-Protection', '1');
  if (isProductionBuild) {
    // tells the browser whether you want to allow your site to be framed or not. By preventing a browser from framing your site you can defend against attacks like clickjacking.
    res.setHeader('X-Frame-Options', 'SAMEORIGIN');
    // is an effective measure to protect your site from XSS attacks. By whitelisting sources of approved content, you can prevent the browser from loading malicious assets
    // https://content-security-policy.com/

    const securityPolicy: string[] = [
      "default-src 'self' https://*.marc-o-polo.com https://*.z01.azurefd.net",
      `style-src 'self' 'unsafe-inline' ${allowedStyleDomains.join(' ')}`,
      // TODO: find a workaround for vue-18n to not use eval
      `script-src 'self' 'unsafe-inline' 'unsafe-eval' ${allowedScriptSrcElements.join(' ')}`,
      'worker-src blob:',
      "img-src 'self' https: data:",
      "connect-src 'self' https: wss:",
      "object-src 'self' https://*.marc-o-polo.com",
      "frame-src 'self' https: data:",
      `font-src 'self' ${allowedFontSrcElements.join(' ')}`,
      `form-action 'self' ${allowedFormActionDomains.join(' ')}`,
      "manifest-src 'self' https://*.marc-o-polo.com https://*.z01.azurefd.net",
      "frame-ancestors 'self' https://*.marc-o-polo.com https://*.z01.azurefd.net https://*.storyblok.com https://*.netlify.app",
    ];
    res.setHeader('Content-Security-Policy', securityPolicy.join(';'));
  }
  // allows a site to control which features and APIs can be used in the browser.
  res.setHeader('Feature-Policy', "sync-xhr 'self'");
  // allows a site to control how much information the browser includes with navigations away from a document and should be set by all sites.
  res.setHeader('Referrer-Policy', 'strict-origin');
  // Add cloudfront's country code (ISO 3166-1 alpha-2)
  if (req.headers['cloudfront-viewer-country']) {
    res.setHeader('cloudfront-viewer-country', req.headers['cloudfront-viewer-country']);
  }
}
