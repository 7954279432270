<script setup lang="ts">
defineOptions({
  name: 'MopCmsPromotionItem',
});

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const element = getCmsPromoTile(props.data);
</script>

<template>
  <div>
    <MopTile :data="element!" :image-type="element!.imageType" />
  </div>
</template>
