import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type {
  BffApiAccountAddressAddressIdPut,
  BffApiAccountAddressAddressIdDelete,
  BffApiAccountAddressGet,
  BffApiAccountAddressPost,
  BffApiAddressCheckPost,
  BffCartAddress,
} from '@/types/bff';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
const API_NAME = 'AWS - BFF ADDRESS';

export function getAddresses(apiConfig: ApiBffConfig): Promise<ApiResponse<BffApiAccountAddressGet>> {
  const url = getBffUrlWithParams('/account/address', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountAddressGet>>(async () => ({
    data: (
      await apiConfig.apiClient.get(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'getAddresses',
      url,
    }),
  }));
}

export function updateAddress(
  apiConfig: ApiBffConfig,
  address: BffCartAddress,
  options: { setAsDefault?: boolean; skipAddressCheck?: boolean },
): Promise<ApiResponse<BffApiAccountAddressAddressIdPut>> {
  let url = getBffUrlWithParams(`/account/address/${address.id}`, apiConfig);
  if (options.setAsDefault) {
    url = `${url}&setAsDefault=true`;
  }
  if (options.skipAddressCheck) {
    url = `${url}&skipAddressCheck=true`;
  }
  return bffErrorHandler<ApiResponse<BffApiAccountAddressAddressIdPut>>(async () => ({
    data: (
      await apiConfig.apiClient.put(
        url,
        {
          ...address,
          country: address.country.toUpperCase(),
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'updateAddress',
      url,
    }),
  }));
}

export function createAddress(
  apiConfig: ApiBffConfig,
  address: BffCartAddress,
  options: { setAsDefault?: boolean; skipAddressCheck?: boolean },
): Promise<ApiResponse<BffApiAccountAddressPost>> {
  let url = getBffUrlWithParams(`/account/address`, apiConfig);
  if (options.setAsDefault) {
    url = `${url}&setAsDefault=true`;
  }
  if (options.skipAddressCheck) {
    url = `${url}&skipAddressCheck=true`;
  }
  return bffErrorHandler<ApiResponse<BffApiAccountAddressPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          ...address,
          country: address.country.toUpperCase(),
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createAddress',
      url,
    }),
  }));
}

export function addressRiskCheck(
  apiConfig: ApiBffConfig,
  address: BffCartAddress,
): Promise<ApiResponse<BffApiAddressCheckPost>> {
  const url = getBffUrlWithParams('/address/check', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAddressCheckPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(
        url,
        {
          ...address,
          country: address.country.toUpperCase(),
        },
        {
          withCredentials: true,
        },
      )
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createAddress',
      url,
    }),
  }));
}

export function createLocationAddress(
  apiConfig: ApiBffConfig,
  params: BffCartAddress,
): Promise<ApiResponse<BffApiAccountAddressPost>> {
  const path = `/account/address`;
  const url = getBffUrlWithParams(path, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountAddressPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'createLocationAddress',
      url,
    }),
  }));
}

export function removeAddress(
  apiConfig: ApiBffConfig,
  id: string,
): Promise<ApiResponse<BffApiAccountAddressAddressIdDelete>> {
  const path = `/account/address/${id}`;
  const url = getBffUrlWithParams(path, apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAccountAddressAddressIdDelete>>(async () => ({
    data: (
      await apiConfig.apiClient.delete(url, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'removeAddress',
      url,
    }),
  }));
}
