import type { ReservationModel, ReservationProductModel } from '@/types/reservation';
import type {
  BffApiReservationGet,
  BffApiReservationPost,
  BffApiReservationsGet,
  BffReservation,
  BffStore,
} from '@/types/bff';
import { reservationProductModel, storeModel } from '@/models';
import type { ApiResponse } from '@/types/apiInit';

export function reservationModel(responseData: ApiResponse<BffApiReservationGet | BffApiReservationPost> | null) {
  const reservationData: BffReservation | undefined =
    responseData?.data && 'reservation' in responseData.data ? responseData.data.reservation : undefined;

  return {
    hasError(): boolean {
      return responseData?.error !== undefined || responseData?.data?.code !== 'OK';
    },

    isInitialized(): boolean {
      return responseData !== undefined;
    },

    getId(): string {
      return reservationData?.reservationId || '';
    },

    getReservationStatus() {
      return reservationData?.reservationStatus || 'requested';
    },

    getCount(): string {
      return this.getReservationStatus() === 'created' ? '1' : '';
    },

    getReservationProduct(): ReservationProductModel | undefined {
      const product = reservationData?.product;

      if (product) {
        return reservationProductModel(product);
      }
    },

    getStore() {
      // Fulfill data to satisfy storeModel
      const store = {
        id: reservationData?.store?.id || '',
        name: reservationData?.store?.name || '',
        mail: reservationData?.store?.mail || '',
        openingTimes: reservationData?.store?.openingTimes || [],
        phoneNumber: reservationData?.store?.phoneNumber || '',
        country: reservationData?.store?.country || '',
        gln: reservationData?.store.gln || '',
        collection: [],
        services: [],
        showInStoreFinder: false,
      } satisfies BffStore;

      if (store) {
        return storeModel(store);
      }
    },

    hasActiveReservation(): boolean {
      return this.getReservationStatus() === 'created';
    },

    getSku(): string {
      return reservationData?.variantId ?? '';
    },
  };
}

export function reservationListModel(responseData: ApiResponse<BffApiReservationsGet> | null) {
  return {
    isInitialized(): boolean {
      return responseData !== null;
    },

    hasError(): boolean {
      return responseData?.error !== undefined;
    },

    getReservationModelList(): ReservationModel[] {
      if (responseData?.data === undefined || responseData?.data.code !== 'OK') {
        return [];
      }

      return responseData?.data.reservations.map((reservation) =>
        reservationModel({
          data: {
            reservation,
            code: 'OK',
            isMopApiResponse: true,
            statusCode: 200,
          },
        }),
      );
    },
  };
}
