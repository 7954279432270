<script setup lang="ts">
import type { SlideEventData } from '@mop/types';
import { getElementUiObject } from '@mop/cms/utils/utils';
import type { CmsPromoTileLayout } from '@mop/cms/types';
import type { CmsPromoTile, CmsUiProductCarousel } from '@/types/cms';

defineOptions({
  name: 'MopCmsProductCarousel',
});

const emit = defineEmits(['slide-change']);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
});

const data: any = props.data;
const rootElement = ref<HTMLElement | null>(null);

function getTiles(attribute: string, layout: CmsPromoTileLayout, imageType: string) {
  return data[attribute]?.map((item: any) => {
    const tile: CmsPromoTile = getCmsPromoTile(item) as CmsPromoTile;
    tile.layout = layout;
    tile.imageType = imageType;
    if (tile.media) {
      tile.media.imageType = imageType;
    }
    return tile;
  });
}

const element: CmsUiProductCarousel = getElementUiObject(data, {
  heroTiles: getTiles('heroItems', 'hero', 'product-tile-hero'),
  tiles: getTiles('items', 'single', 'product-tile-reco'),
  position: parseInt(data.position || 0),
});

function handleSlideChange(slideData: SlideEventData) {
  emit('slide-change', rootElement.value, slideData);
}
</script>

<template>
  <div ref="rootElement" v-storyblok-editable="element" class="cms-product-carousel">
    <div v-if="element.heroTiles.length" class="cms-product-carousel__heros">
      <MopTile
        v-for="(heroTile, index) in element.heroTiles"
        :key="`${index}-hero-${heroTile._uid}`"
        class="cms-product-carousel__hero-tile"
        :data="heroTile"
        :image-type="heroTile.imageType"
        :enable-popularity-flag="enablePopularityFlag"
        track-position="hero"
        disable-image-hover-change
      />
    </div>

    <UiSliderNew
      v-if="element.tiles.length"
      show-arrows
      show-peek
      :max-slides-count="4"
      :class="['cms-product-carousel__slider', `cms-product-carousel__slider--${element.tiles.length}`]"
      @slide-change="handleSlideChange"
    >
      <MopTile
        v-for="(tile, index) in element.tiles"
        :key="`${index}-${tile._uid}`"
        :data="tile"
        :image-type="tile.imageType"
        :enable-popularity-flag="enablePopularityFlag"
        show-reco-image
        :track-position="(index + 1).toString()"
      />
    </UiSliderNew>
  </div>
</template>

<style lang="scss" scoped>
.cms-product-carousel {
  padding-right: $global-padding;
  padding-left: $global-padding;
  margin-bottom: $space20;
}

.cms-product-carousel__heros {
  display: flex;
  width: 100%;
  gap: $space-8;
  padding-bottom: $space10;
  justify-content: space-around;
}

.cms-product-carousel__hero-tile {
  flex-basis: 50%;
  width: 50%;

  @include apply-upto(small) {
    flex-basis: 100%;
    width: 100%;

    &:not(:first-child) {
      display: none;
    }
  }
}
</style>
