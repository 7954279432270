import type { ProductVariant } from '@commercetools/platform-sdk';
import type { VariantModel, VariantAvailability } from '@/types/product';
import { unwrapLocalizedStringVariantAttributes } from '@/models/utils/productUtils';
import { WAREHOUSE_TYPES } from '@/utils/constants';
import { getMopPriceFromCommercetools } from '@/models/utils/priceUtils';

export function variantModel(productVariant: ProductVariant) {
  const attributes = unwrapLocalizedStringVariantAttributes(productVariant);

  return {
    getId(): number {
      return productVariant.id;
    },

    getPrice() {
      return getMopPriceFromCommercetools(productVariant.price);
    },

    hasPrice() {
      return Boolean(productVariant.price);
    },

    getSize(): string {
      return attributes.size ?? '';
    },

    getSku(): string {
      return productVariant.key ?? '';
    },

    isGiftCard(): boolean {
      return attributes.masterKey === '999999999999';
    },

    isGiveaway(): boolean {
      return attributes.isSellableForFree ?? false;
    },

    getAvailability(stockInTransit = false): VariantAvailability {
      const prioWarehouseType =
        WAREHOUSE_TYPES.find(
          (warehouseType) =>
            (productVariant?.availability?.channels?.[getWarehouseId(warehouseType)]?.availableQuantity ?? 0) > 0,
        ) ?? 'ARVATO';
      const warehouseId = getWarehouseId(prioWarehouseType);
      const channel = productVariant?.availability?.channels?.[warehouseId];

      const quantity = channel?.availableQuantity ?? 0;
      const isLowStock = quantity < constants.INVENTORY_CALL_THRESHOLD;
      const isSellableWithoutStock = attributes.sellableWithoutStock ?? false;
      const isComingSoon: boolean = quantity === 0 && isSellableWithoutStock;
      const isInStock = channel?.isOnStock ?? false;
      const isInTransit: boolean = quantity === 0 && stockInTransit;
      let stockText = 'product.stock.exists';

      if (isLowStock) {
        stockText = 'product.stock.low';
      }
      if (!isInStock) {
        stockText = 'product.stock.soldout';
      }
      if (isInTransit) {
        stockText = 'product.flag.soon_available';
      }
      return {
        isComingSoon,
        isInStock,
        isLowStock,
        isBackOrder: prioWarehouseType !== 'ARVATO',
        isShipFromStore: prioWarehouseType === 'SHIP_FROM_STORE',
        isInTransit,
        quantity,
        stockText,
        backOrderText: 'product.backorder',
        deliveryText: 'product.delivery',
        warehouseId,
      };
    },

    getDebugData() {
      return productVariant;
    },
  };
}

export function variantListModel(productVariants: ProductVariant[] | null) {
  return {
    getVariantModelList(): VariantModel[] {
      if (productVariants === null) {
        return [];
      }
      return productVariants.map((variant) => variantModel(variant));
    },
  };
}
