import ui2Plugin from '@mop/ui2/config/ui2Plugin';
import type { NuxtApp } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  const { $mopI18n, $mopConfig } = nuxtApp.vueApp.$nuxt as NuxtApp;
  if (!$mopI18n) {
    return;
  }

  // bridge to ui2 components localisation, config etc
  nuxtApp.vueApp.use(ui2Plugin, {
    i18n: $mopI18n,
    isGlobalEEnabled: () => $mopI18n.isGlobalE,
    isSaleEnabled: $mopConfig.isSaleEnabled,
    isSavingLabelEnabled: $mopConfig.isSavingLabelEnabled,
  });
});
