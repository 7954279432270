import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { Ref } from 'vue';
import type { ClientResponse, ProductProjectionPagedSearchResponse } from '@commercetools/platform-sdk';
import { productListModel } from '@/models';
import type { BffApiBackinstockSubscriptionPostRequest, BffApiStockPostRequest } from '@/types/bff';
import { getProductProjectionParameters } from '@/models/utils/productUtils';
import type { ProductProjectionSearchParams } from '@/types/product';

type LoadingState = {
  searchProductByMopProductId: boolean;
  searchProductBySku: boolean;
  searchProductWithSwatches: boolean;
  loading: boolean;
};

export default function useMopProduct(cacheId?: string) {
  const loadingRef: Ref<LoadingState> = ref({
    searchProductByMopProductId: false,
    searchProductBySku: false,
    searchProductWithSwatches: false,
    loading: computed(() => isLoading(loadingRef)),
  });
  const { $apiCommercetools, $mopI18n, $apiBff } = useNuxtApp();
  const { categoryKeysListRef } = useMopRootCategories();
  const { customerModelRef } = useMopCustomer();
  const productSearchResponseRef = cacheId
    ? useMopSSR<ClientResponse<ProductProjectionPagedSearchResponse> | null>(`product-search-${cacheId}`, null)
    : ref(null);
  const productModelRef = ref(productListModel(productSearchResponseRef.value).getFirstProductModel());
  const swatchesModelListRef = ref(productListModel(productSearchResponseRef.value));

  async function registerBackInStockNotification(payload: BffApiBackinstockSubscriptionPostRequest) {
    const result = await $apiBff.registerBackInStockNotification(payload);
    return result.data?.code;
  }

  async function getProductStock(payload: BffApiStockPostRequest) {
    const result = await $apiBff.getStockData(payload);
    return result.data;
  }

  // ean = sku
  async function searchProductBySku(sku: string) {
    if (!sku) {
      return;
    }
    loadingRef.value.searchProductBySku = true;
    const productsQuery: ProductProjectionSearchParams = {
      queryArgs: {
        ...getProductProjectionParameters($mopI18n, customerModelRef.value.getCommercetoolsCustomerGroupId()),
        limit: 100,
        filter: [
          `variants.key:"${sku}"`,
          `variants.attributes.countrySalePermission:"${$mopI18n.commercetoolsCountry.toUpperCase()}"`,
        ],
      },
    };

    // keep this endpoint to get faster stock updates
    productSearchResponseRef.value ??= await $apiCommercetools.productProjectionSearch(
      productsQuery,
      categoryKeysListRef.value,
    );
    productModelRef.value = productListModel(productSearchResponseRef.value).getFirstProductModel();
    loadingRef.value.searchProductBySku = false;
  }

  // mopProductId = master_color
  async function searchProductByMopProductId(mopProductId: string, forceClientRefetch = false) {
    if (!mopProductId) {
      return;
    }
    loadingRef.value.searchProductByMopProductId = true;

    if (forceClientRefetch) {
      productSearchResponseRef.value = null;
    }

    const productsQuery: ProductProjectionSearchParams = {
      queryArgs: {
        ...getProductProjectionParameters($mopI18n, customerModelRef.value.getCommercetoolsCustomerGroupId()),
        limit: 100,
        filter: [
          `key:"${mopProductId.toUpperCase()}"`,
          `variants.attributes.countrySalePermission:"${$mopI18n.commercetoolsCountry.toUpperCase()}"`,
        ],
      },
    };

    // keep this endpoint to get faster stock updates
    productSearchResponseRef.value ??= await $apiCommercetools.productProjectionSearch(
      productsQuery,
      categoryKeysListRef.value,
    );
    productModelRef.value = productListModel(productSearchResponseRef.value).getFirstProductModel();

    loadingRef.value.searchProductByMopProductId = false;
  }

  // Create productListModel for swatches
  async function searchProductWithSwatches(mopProductId: string, forceClientRefetch = false) {
    loadingRef.value.searchProductWithSwatches = true;
    mopProductId = mopProductId.toUpperCase();
    const productMasterKey = mopProductId.split('_')[0];

    if (forceClientRefetch) {
      productSearchResponseRef.value = null;
    }

    const productsQuery: ProductProjectionSearchParams = {
      queryArgs: {
        ...getProductProjectionParameters($mopI18n, customerModelRef.value.getCommercetoolsCustomerGroupId()),
        limit: 100,
        filter: [
          `variants.attributes.masterKey:"${productMasterKey}"`,
          `variants.attributes.countrySalePermission:"${$mopI18n.commercetoolsCountry.toUpperCase()}"`,
        ],
      },
    };

    // keep this endpoint to get faster stock updates
    productSearchResponseRef.value ??= await $apiCommercetools.productProjectionSearch(
      productsQuery,
      categoryKeysListRef.value,
    );
    swatchesModelListRef.value = productListModel(productSearchResponseRef.value);
    loadingRef.value.searchProductWithSwatches = false;
  }

  return securedWrap({
    loadingRef,
    productModelRef,
    swatchesModelListRef,
    searchProductBySku,
    searchProductByMopProductId,
    searchProductWithSwatches,
    registerBackInStockNotification,
    getProductStock,
  });
}
