<script setup lang="ts">
import { isClient } from '@mop/shared/utils/util';
import type { ObserveVisibilityConfig } from '@mop/types';
import type { CmsMedia } from '@mop/cms/types';
import type { GtmProductListType, Gtm2EcommerceEventType } from '@/types/gtm';
import type { ProductModel } from '@/types/product';

defineOptions({
  name: 'MopProductTile',
});

const props = defineProps({
  product: {
    type: Object as PropType<ProductModel>,
    required: true,
  },
  position: {
    type: Number,
    required: false,
    default: 0,
  },
  gtmListType: {
    type: String as PropType<GtmProductListType>,
    required: false,
    default: '',
  },
  gtmSearchTerm: {
    type: String,
    required: false,
    default: '',
  },
  media: {
    type: Object,
    default: null,
  },
  imageType: {
    type: String,
    default: 'product-tile',
  },
  imageIndex: {
    type: Number,
    default: PRODUCT_IMAGE_INDEX.STANDARD,
  },
  hideText: {
    type: Boolean,
    default: false,
  },
  disableImageHoverChange: {
    type: Boolean,
    default: false,
  },
  imageHoverIndex: {
    type: Number,
    default: PRODUCT_IMAGE_INDEX.DETAIL,
  },
  categoryPath: {
    type: String,
    default: '',
  },
  disableClick: {
    type: Boolean,
    default: false,
  },
  wishlistIcon: {
    type: String,
    default: 'heart',
  },
  enablePromotion: {
    type: Boolean,
    default: false,
  },
  enablePopularityFlag: {
    type: Boolean,
    default: false,
  },
  disableHover: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

const {
  $mopI18n,
  $breakpoint: { isMobileRef },
  $gtm2,
  $mopConfig,
} = useNuxtApp();
const router = useRouter();
const product: ProductModel = props.product as ProductModel;
const imageIndex: number = props.imageIndex;
let imageHoverIndex: number = props.imageHoverIndex;
if (imageIndex === imageHoverIndex) {
  imageHoverIndex =
    imageIndex === PRODUCT_IMAGE_INDEX.DETAIL ? PRODUCT_IMAGE_INDEX.STANDARD : PRODUCT_IMAGE_INDEX.DETAIL;
}
const { getActiveCartDiscount } = useMopCartDiscounts();
const { getActiveProductDiscount } = useMopProductDiscounts();

const { wishlistModelRef, addToWishlist, removeFromWishlist, loadingRef } = useMopWishlistClient();

const url = product.getUrl();
const imageSrcRef = ref('');
const imageHoverSrcRef = ref('');
const hasSustainableFlag = product.hasSustainabilityFlag();
const customFlag = product.getCustomFlag();
const isHover = ref(false);
const isVisibleRef = ref(true);
const popularityFlag = product.getPopularityFlag($mopConfig);
const popularityFlagText =
  props.enablePopularityFlag && popularityFlag
    ? $mopI18n.t(`product.popularity_flag.${popularityFlag?.type}.title`)
    : '';
const swatchesRef = ref<ProductModel[]>([]);
const price = product.getPrice();

const callOutMessageRef = computed(() => {
  if (!isClient) {
    return;
  }
  return (
    getActiveProductDiscount(product.getPrice())?.getCalloutFlag() || getActiveCartDiscount(product)?.getCalloutFlag()
  );
});

const visibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    if (!props.gtmListType || !isVisible) {
      return;
    }
    trackEcommerce('view_item_list', true);
  },
  rootMargin: '0px 0px 0px 0px',
};

const imageVisibilityConfig: ObserveVisibilityConfig = {
  callback: (isVisible) => {
    if (!isVisible) {
      return;
    }
    imageSrcRef.value = product.getImageByIndex(imageIndex);
  },
  rootMargin: '0px 0px 100% 0px',
};

if (isClient && !props.disableHover) {
  const stopIsHoverWatch = watch(isHover, async (isHover) => {
    if (!isHover) {
      return;
    }
    imageHoverSrcRef.value = product.getImageByIndex(imageHoverIndex);
    const { swatchesModelListRef, searchProductWithSwatches } = useMopProduct();
    await searchProductWithSwatches(product.getMopMasterId(), true);
    swatchesRef.value = swatchesModelListRef.value?.getSwatches() || [];
    stopIsHoverWatch();
  });
}

const isInWishlistRef = computed(() => {
  if (loadingRef.value.add || loadingRef.value.remove) {
    return null;
  }
  return wishlistModelRef.value.isProductInWishlist(product.getId());
});

async function handleWishlistClick() {
  if (isInWishlistRef.value) {
    // special case to hide tile from the list inside the wishlist
    if (props.wishlistIcon === 'x') {
      isVisibleRef.value = false;
    }
    await removeFromWishlist(product, props.gtmListType);
  } else {
    await addToWishlist(product, props.gtmListType);
  }
}

function handleMouseEnter() {
  if (!isMobileRef.value) {
    isHover.value = true;
  }
}

function handleMouseLeave() {
  if (!isMobileRef.value) {
    isHover.value = false;
  }
}

function trackEcommerce(event: Gtm2EcommerceEventType, enableBundling = false) {
  $gtm2.trackEcommerce({
    event,
    enableBundling,
    data: {
      products: [
        {
          product,
          position: props.position + 1,
          list: props.gtmListType,
          category: props.categoryPath,
        },
      ],
    },
  });
}

function handleProductClick(event: MouseEvent, url: string) {
  if (props.gtmListType) {
    trackEcommerce('select_item');
  }
  if (props.gtmSearchTerm) {
    $gtm2.reportLegacyEngagement({
      event: props.gtmSearchTerm,
      // Possibly incorrect parameters, ticket about tracking implementation: https://marc-o-polo.atlassian.net/browse/DI-9706
      // @ts-ignore
      target: 'site_search_click',
      label: document.location.origin + $mopI18n.localePath(url),
    });
  }
  if (props.disableClick) {
    event.preventDefault();
    emit('click');
    return;
  }
  if (guardEvent(event)) {
    event.preventDefault();
    handleNavigateTo(url);
  }
}

function handleNavigateTo(url: string) {
  router.push(url);
  emit('click');
}
</script>

<template>
  <div
    v-if="isVisibleRef"
    v-observe-visibility:once="gtmListType ? visibilityConfig : undefined"
    :data-image-list="product.getNamesOfImageList()"
  >
    <div v-observe-visibility:once="imageVisibilityConfig">
      <Ui2EcomProductCard
        :price="{
          basePrice: price.basePrice,
          salePrice:
            (callOutMessageRef?.showPriceOnlyInCart ? price.reductionPrice : price.salePrice) || price.basePrice,
          currency: price.currency,
          salePercentage: callOutMessageRef?.showPriceOnlyInCart ? price.reductionPercentage : price.salePercentage,
        }"
        :title="product.getCompositeName()"
        :url="$mopI18n.localePath(url)"
        :image-type="imageType"
        :image-src="imageSrcRef"
        :image-hover-src="imageHoverSrcRef"
        :disable-image-hover-change="disableImageHoverChange || isMobileRef"
        :is-hover="isHover"
        :has-sustainable-flag="hasSustainableFlag"
        :is-in-wishlist="isInWishlistRef"
        :is-denim="product.isDenim()"
        :new-flag="product.isNew() ? $mopI18n.t('product.badge.new').toLowerCase() : ''"
        :popularity-flag="popularityFlagText"
        :call-out-message="callOutMessageRef?.message"
        :call-out-message-appearance="callOutMessageRef?.appearance"
        :custom-flag="customFlag && $mopI18n.te(customFlag) ? $mopI18n.t(customFlag) : customFlag"
        :hide-text="hideText"
        :wishlist-icon="wishlistIcon === 'x' ? 'x' : 'heart'"
        :swatches="
          swatchesRef.map((swatch) => {
            return {
              url: $mopI18n.localePath(swatch.getUrl()),
              swatchImageSrc: swatch.getSwatchImage(),
              imageSrc: swatch.getImageByIndex(imageIndex),
              sizes: swatch.getSizes(),
            };
          })
        "
        :media="media as CmsMedia"
        @click="handleProductClick"
        @click-wishlist="handleWishlistClick"
        @mouse-enter="handleMouseEnter"
        @mouse-leave="handleMouseLeave"
      />
    </div>
  </div>
</template>
