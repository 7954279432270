import type { NewsletterResponseData } from '@/types/newsletter';
import type { BffNewsletterThemeKey } from '@/types/bff';

// TODO: commercetools - redo with proper type ApiResponse<BffApiNewsletterSubscriptionPostResponse> after all newsletter endpoints migrated
export function newsletterModel(responseData: NewsletterResponseData | null) {
  const response: NewsletterResponseData = responseData ?? {};

  return {
    hasError(): boolean {
      return response.error !== undefined;
    },

    hasSubscription(): boolean {
      return response.error?.code === constants.ERROR_CODE.NEWSLETTER.ALREADY_EXISTS;
    },

    isInitialized(): boolean {
      return responseData !== null;
    },

    hasInterestInMen(): boolean {
      return response.data?.isInterestedInMen === true;
    },

    hasInterestInWomen(): boolean {
      return response.data?.isInterestedInWomen === true;
    },

    isOptInMail(): boolean {
      return response.data?.isOptInMail === true;
    },

    isOptInPost(): boolean {
      return response.data?.isOptInPost === true;
    },

    getOptInThemes(): BffNewsletterThemeKey[] {
      return response.data
        ? (Object.keys(response.data).filter((theme) => response.data![theme]) as BffNewsletterThemeKey[])
        : [];
    },

    // Email is already opt-in and now is joined (i.e. to raffle or adventscalender)
    isAdded(): boolean {
      const addedResponses: string[] = [
        constants.ERROR_MESSAGE.NEWSLETTER.RAFFLE_ADDED,
        constants.ERROR_MESSAGE.NEWSLETTER.ADVENTCALENDERTHEME_ADDED,
      ];
      return addedResponses.includes(response?.error?.message || '');
    },

    // Email is already opt-in and also already joined (i.e. to raffle or adventscalender)
    isAlreadySet(): boolean {
      const alreadySetResponses: string[] = [
        constants.ERROR_MESSAGE.NEWSLETTER.RAFFLE_ALREADY_SET,
        constants.ERROR_MESSAGE.NEWSLETTER.ADVENTCALENDERTHEME_ALREADY_SET,
      ];
      return alreadySetResponses.includes(response?.error?.message || '');
    },

    isDOIMailSent(): boolean {
      return response.data?.isDOIMailSent === true;
    },

    getDebugData() {
      return response.data;
    },
  };
}
