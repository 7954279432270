import type { LocationQueryRaw } from 'vue-router';
import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient } from '@mop/shared/utils/util';
import type { Alternate } from '@/types/cms';
import type { CategoryModel } from '@/types/category';

type ActiveCategoryId = Ref<string | null>;
type ActiveCategoryPath = Ref<CategoryModel[]>;

type RouterComposableStorage = {
  activeCategoryPathRef: ActiveCategoryPath;
  activeCategoryIdRef: ActiveCategoryId;
};

export default function useMopRouter() {
  const { $cookie, $mopI18n, $config } = useNuxtApp();
  const { getCategoriesInPath, getCategoryById, getCategoryByPropertyName } = useMopCategoryTree();
  const route = useRoute();
  const router = useRouter();
  const storage = initStorage<RouterComposableStorage>('useMopRouter');
  const activeCategoryIdRef: ActiveCategoryId =
    storage.get('activeCategoryIdRef') ??
    storage.saveAndGet('activeCategoryIdRef', useMopSSR('active-category-id', null));

  const activeCategoryPathRef: ActiveCategoryPath =
    storage.get('activeCategoryPathRef') ??
    storage.saveAndGet('activeCategoryPathRef', ref(initAndGetActiveCategory()));

  function getQueryParamValue(name: string): string | undefined {
    let queryParamValue: any = route.query[name];
    // If duplicate parameters in url, use last
    if (Array.isArray(queryParamValue)) {
      queryParamValue = queryParamValue[queryParamValue.length - 1];
    }

    return queryParamValue;
  }

  // commercetools id
  function setActiveCategory(categoryId: string) {
    const categoryList = findCategoryPath(categoryId);
    if (!categoryList || !categoryList.length || !categoryId) {
      return;
    }
    $cookie.store(constants.COOKIE.TOP_LEVEL_CATEGORY, String(categoryList[0].getId()));

    if (activeCategoryIdRef.value !== categoryId) {
      activeCategoryPathRef.value = categoryList;
      activeCategoryIdRef.value = categoryId;
    }
    // bit of a hack
    if (isClient) {
      $config.public.HOME_URL = $mopI18n.localePath(activeCategoryPathRef.value[0]?.getUrl() || '');
    }
  }

  function isAuthRequired() {
    return !isClient || (Boolean(route.meta?.requiresAuth) && useMopCustomer().customerModelRef.value.isGuest());
  }

  function initAndGetActiveCategory(): CategoryModel[] {
    if (activeCategoryIdRef.value) {
      return findCategoryPath(activeCategoryIdRef.value);
    }

    let topCategory: CategoryModel | undefined;
    const isCachedPage = Boolean(route.meta?.cacheMaxAgeInSeconds);
    /**
     * For cached pages (static pages like the Storefinder) there is some trouble getting the saved top category. We
     * would need to save a cached version for each top category. We decided to go for the more simple solution and
     * use the same fallback category for them.
     */
    // exclude product page to keep previously set top category, it also contains fallback
    if (isCachedPage && route.name !== 'product-page') {
      topCategory = getFallbackTopCategory();
    } else {
      topCategory = getSavedTopCategory();
    }

    return topCategory ? [topCategory] : [];
  }

  function getSavedTopCategory(): CategoryModel {
    let category: CategoryModel | undefined;

    const topCategoryId: string = $cookie.get(constants.COOKIE.TOP_LEVEL_CATEGORY);

    if (topCategoryId) {
      const categoryFromCookie = getCategoryById(topCategoryId);
      if (categoryFromCookie) {
        category = categoryFromCookie;
      }
    }

    return category || getFallbackTopCategory();
  }

  function getFallbackTopCategory(): CategoryModel {
    return getCategoryByPropertyName('mopId', 'women') as CategoryModel;
  }

  function findCategoryPath(categoryId: string) {
    if (!categoryId) {
      return [];
    }
    const foundCategory = getCategoryById(categoryId);
    if (!foundCategory) {
      return [];
    }
    return getCategoriesInPath(foundCategory.getPath());
  }

  function performLocaleRedirect(oldLocale: string, newLocale: string, newLocaleFallback: string) {
    const alternates: Alternate[] = useMopSeo().getAlternates();
    let targetAlternate: Alternate | undefined = alternates.find((alternate) => alternate.lang === newLocale);
    if (!targetAlternate && newLocaleFallback) {
      targetAlternate = alternates.find((alternate) => alternate.lang === newLocaleFallback);
    }
    let target: string = window.location.href.replace(oldLocale, newLocale).split('#overlay-')[0];
    if (targetAlternate) {
      target = `/${newLocale}${targetAlternate.href}`;
    }

    window.location.href = target;
  }

  function isActiveRootCategory(mopCategoryId: string): boolean {
    const [activeTopCategory] = activeCategoryPathRef.value;
    const activeTopCategoryId: string = activeTopCategory ? activeTopCategory.getMopId() : '';
    return mopCategoryId === activeTopCategoryId;
  }

  function isCurrentCategory(id: string): boolean {
    return id === activeCategoryIdRef?.value;
  }

  function getLocalePathFromQuery(query: LocationQueryRaw) {
    const fullPath = router.resolve({ query }).fullPath;
    return $mopI18n.localePath(fullPath);
  }

  function getLocalePathFromHash(hash: string) {
    const fullPath = router.resolve({ hash }).fullPath;
    return $mopI18n.localePath(fullPath);
  }

  function getLocalePathByPage(pageNumber: number) {
    const query = { ...route.query };
    if (pageNumber === 1) {
      delete query.page;
    } else {
      query.page = pageNumber.toString();
    }

    return getLocalePathFromQuery(query);
  }

  return securedWrap({
    activeCategoryPathRef,
    getSavedTopCategory,
    getQueryParamValue,
    setActiveCategory,
    isAuthRequired,
    performLocaleRedirect,
    isActiveRootCategory,
    isCurrentCategory,
    getLocalePathByPage,
    getLocalePathFromQuery,
    getLocalePathFromHash,
  });
}
