import { securedWrap } from '@mop/shared/utils/securedWrap';
import type { ProductModel } from '@/types/product';

export default function useMopCartOverlayClient() {
  type MopCartOverlayComposableStorage = {
    showAddToCartOverlayRef: Ref<boolean>;
  };

  const overlay = useMopOverlay();
  const storage = initStorage<MopCartOverlayComposableStorage>('useMopCartOverlayClient');
  const showAddToCartOverlayRef =
    storage.get('showAddToCartOverlayRef') ?? storage.saveAndGet('showAddToCartOverlayRef', ref(false));

  function openCartOverlay() {
    showAddToCartOverlayRef.value = true;
  }

  function closeCartOverlay() {
    showAddToCartOverlayRef.value = false;
  }

  function openGiftCardPromptOverlay(
    caseId: 'pdp_giftcard' | 'click_collect' | 'cart' | 'pdp',
    product?: ProductModel,
  ) {
    return overlay.open({
      type: 'centered',
      componentName: 'MopGiftCardPromptOverlay',
      props: {
        caseId,
        product,
      },
      overrideParams: {
        lockScroll: true,
        forceRender: true,
      },
    });
  }

  return securedWrap({
    openCartOverlay,
    closeCartOverlay,
    showAddToCartOverlayRef,
    openGiftCardPromptOverlay,
  });
}
