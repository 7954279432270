import { securedWrap } from '@mop/shared/utils/securedWrap';
import { logError } from '@mop/shared/utils/logger';
import type { CheckoutError, CheckoutLogError } from '@/types/checkout';

type CheckoutComposableStorage = {
  error: Ref<CheckoutError>;
};

export default function useMopCheckoutErrorClient() {
  const emptyError: CheckoutError = { checkoutErrorKey: undefined, ctaAction: 'reload' };
  const storage = initStorage<CheckoutComposableStorage>('useMopCheckoutErrorClient');

  const checkoutErrorRef = storage.get('error') ?? storage.saveAndGet('error', ref(emptyError));

  function showCheckoutError(error: CheckoutError) {
    if (error.checkoutErrorKey && checkoutErrorRef.value.checkoutErrorKey === error.checkoutErrorKey) {
      // ignores same errors
      return;
    }
    checkoutErrorRef.value = error;
  }

  function hideCheckoutError() {
    checkoutErrorRef.value = emptyError;
  }

  function logCheckoutError(error: CheckoutLogError) {
    const errorName = `${error.errorGroup}|${error.errorDetail || 'none'}`;
    // Throw error for datadog
    try {
      throw new Error(`Checkout error: ${errorName}`);
    } catch (error) {
      logError(error);
    }
  }

  return securedWrap({
    showCheckoutError,
    hideCheckoutError,
    logCheckoutError,
    checkoutErrorRef,
  });
}
