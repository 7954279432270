import type { BffReservation } from '@/types/bff';

export function reservationProductModel(reservationProduct: BffReservation['product']) {
  return {
    getSku(): string {
      return reservationProduct.variantId;
    },

    getUrl(): string {
      return reservationProduct.productSrc;
    },

    getImage(): string {
      return reservationProduct.imageSrc;
    },

    getPrice() {
      return reservationProduct.price;
    },

    getSize(): string {
      return reservationProduct.size;
    },

    getColor(): string {
      return reservationProduct.color;
    },

    getName(): string {
      return reservationProduct.name;
    },

    getShortDescription(): string {
      return reservationProduct.shortDescription;
    },
  };
}
