import type { AxiosError } from 'axios';
import { handleAxiosError, bffErrorHandler, getBffUrlWithParams } from '@/api/utils';
import type { ApiBffConfig, ApiResponse } from '@/types/apiInit';
import type {
  BffSearchCustomerPayload,
  BffApiAuthCustomerSearchPost,
  BffLoginOnBehalfPayload,
  BffApiAuthCustomerLoginOnBehalfPost,
} from '@/types/bff';
const API_NAME = 'AWS - BFF Order On Behalf';

export function searchCustomers(
  apiConfig: ApiBffConfig,
  params: BffSearchCustomerPayload,
): Promise<ApiResponse<BffApiAuthCustomerSearchPost>> {
  const url = getBffUrlWithParams('/auth/customer/search', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthCustomerSearchPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'searchCustomers',
      url,
    }),
  }));
}

export function loginOnBehalf(
  apiConfig: ApiBffConfig,
  params: BffLoginOnBehalfPayload,
): Promise<ApiResponse<BffApiAuthCustomerLoginOnBehalfPost>> {
  const url = getBffUrlWithParams('/auth/customer/login-on-behalf', apiConfig);
  return bffErrorHandler<ApiResponse<BffApiAuthCustomerLoginOnBehalfPost>>(async () => ({
    data: (
      await apiConfig.apiClient.post(url, params, {
        withCredentials: true,
      })
    ).data,
  })).catch((error: AxiosError) => ({
    error: handleAxiosError(error, {
      api: API_NAME,
      method: 'loginOnBehalf',
      url,
    }),
  }));
}
