<script setup lang="ts">
import type { CmsHeadlineWithUppercaseOption, CmsUiCta, CmsLink } from '@mop/cms/types';
import type { ProductModel } from '@/types/product';

defineOptions({
  name: 'MopTilePromo',
});

const props = defineProps({
  product: {
    type: Object as PropType<ProductModel | null>,
    default: null,
  },
  media: {
    type: Object,
    default: null,
  },
  headline: {
    type: Object as PropType<CmsHeadlineWithUppercaseOption>,
    default: () => {},
  },
  subline: {
    type: String,
    default: '',
  },
  isSublineUppercase: {
    type: Boolean,
    default: false,
  },
  cta: {
    type: Object as PropType<CmsUiCta>,
    default: () => {},
  },
  link: {
    type: Object as PropType<CmsLink>,
    default: null,
  },
  imageType: {
    type: String,
    default: '',
  },
  imageIndex: {
    type: Number,
    default: PRODUCT_IMAGE_INDEX.STANDARD,
  },
});

const { $storyblokLivePreview, $mopI18n } = useNuxtApp();
const imageSrcRef = computed(() => {
  return props.product?.getImageByIndex(props.imageIndex);
});

const linkRef = computed(() => {
  if (!$storyblokLivePreview.isEnabledInIframe && props.product && !props.link?.isLink) {
    return {
      componentName: 'NuxtLink',
      to: $mopI18n.localePath(props.product.getUrl()),
      isLink: true,
    };
  }
  return props.link;
});
</script>

<template>
  <UiNuxtLink v-bind="linkRef" :key="linkRef.isLink?.toString()" class="tile-promo">
    <div class="tile-promo__body">
      <UiCmsMedia v-if="media" :key="`${media._uid}-${imageType}`" :image-type="imageType" :data="media as any" lazy />
      <UiImage v-else :key="`${imageSrcRef}-${imageType}`" :type="imageType" :src="imageSrcRef" lazy />
    </div>
    <div v-if="headline.text || subline || cta" class="tile-promo__info">
      <div
        v-if="headline.text"
        :class="[
          'tile-promo__headline',
          {
            'tile-promo__uppercase': headline.isUppercase,
          },
        ]"
      >
        {{ headline.text }}
      </div>
      <div
        v-if="subline"
        :class="[
          'tile-promo__subline',
          {
            'tile-promo__uppercase': isSublineUppercase,
          },
        ]"
      >
        {{ subline }}
      </div>
      <div v-if="cta" class="tile-promo__cta">
        <UiCmsCta :data="cta" no-padding />
      </div>
    </div>
  </UiNuxtLink>
</template>

<style scoped lang="scss">
.tile-promo {
  grid-column-start: var(--large-grid-column-start);
  grid-row-start: var(--large-grid-row-start);

  @include apply-upto(medium) {
    grid-column-start: var(--small-grid-column-start);
    grid-row-start: var(--small-grid-row-start);
  }
}

.tile-promo__info {
  margin-top: $space5;

  @include apply-upto(small) {
    padding-right: $space-8;
    padding-left: $space-8;
  }
}

.tile-promo__headline {
  @include text-style(strong-small);

  @include apply-from(tiny) {
    margin: 0;
  }
}

.tile-promo__uppercase {
  text-transform: uppercase;
}

.tile-promo__subline {
  @include text-style(running);
}

.tile-promo__cta {
  margin: -$space15 0;
}
</style>
